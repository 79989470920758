
import { apiSlice } from "./apiSlice";
import { 
    AdvertRequest,
    AdvertResponse,
    AllCountriesResponse, CampaignAdvertOverviewResponse, CampaignDetailResponse, 
    CampaignResponse, CreateAdvertRequest, 
    CreateAdvertResponse, CreateCampaignRequest, 
    CreateCampaignResponse, NetworkResponse } from "./campaignSliceTypes";

const campaignSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get Campaigns
        getCampaigns: build.query<CampaignResponse, string>({
            query: (search: string) => `/campaigns?search=${search}`,
            providesTags:['Campaigns']
        }),
        //Create campaign 
        createCampaign:  build.mutation<CreateCampaignResponse, Partial<CreateCampaignRequest>>({
            query: (payload) => ({
                url: '/campaigns',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Campaigns"],
        }),

        //Create advert
        createAdvert:  build.mutation<CreateAdvertResponse, Partial<CreateAdvertRequest>>({
            query: (payload) => ({
                url: '/adverts',
                method: 'POST',
                body: payload,
            }),
            invalidatesTags: ["Adverts"],
        }),

        //Upload advert images 
        uploadAdvertImages: build.mutation<any, Partial<FormData>>({
            query: (payload) => ({
                url: `/advert-pictures/image-upload`,
                method: 'POST',
                body: payload
            }),
        }),

        //Get a campaign by id
        getCampaign:  build.query<CampaignDetailResponse, number>({
            query: (id) => `/campaigns/${id}`,
            providesTags:['Campaigns']
        }),

        //Get a campaign overview by id
        getCampaignAdvertOverview:  build.query<CampaignAdvertOverviewResponse, number>({
            query: (id) => `/campaigns/advert?id=${id}`,
            providesTags:['Campaigns']
        }),

        //Delete campaign
        deleteCampaign: build.mutation<any, Partial<number>>({
            query: (id) => ({
                url: `/campaigns/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ["Campaigns"]
        }),

        //Get Campaign Adverts
        getCampaignAdvert:  build.query<AdvertResponse, AdvertRequest>({
            query: ({campaignId, status, networkId}) => `/adverts/campaign/?campaignId=${campaignId}&status=${status}&networkId=${networkId}&pageIndex=${0}&pageSize=${30}`,
            providesTags:['Adverts']
        }),

        //Delete advert
        deleteAdvert: build.mutation<any, Partial<string>>({
            query: (id) => ({
                url: `/adverts/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags:['Adverts']
        }),

        //Get all networks 
        getNetworks:  build.query<NetworkResponse, void>({
            query: () => `/networks`,
        }),

         //Get countries
        getCountries: build.query<AllCountriesResponse, void>({
            query: () => `/countries`,
        }),

        //Pause advert
        pauseAdvert: build.mutation<any, number>({
            query: (id) => ({
            url: `/adverts/enable/${id}`,
            method: "PUT",
            }),
            invalidatesTags:['Campaigns', 'Adverts']
        }),
        }),
    overrideExisting: true,
})


export const { useGetCampaignsQuery, 
                useCreateCampaignMutation, 
                useGetCampaignQuery, 
                useGetNetworksQuery, 
                useGetCountriesQuery, 
                useCreateAdvertMutation, 
                useGetCampaignAdvertQuery,
                useUploadAdvertImagesMutation,
                useDeleteAdvertMutation,
                useGetCampaignAdvertOverviewQuery,
                usePauseAdvertMutation,
                useDeleteCampaignMutation
            } = campaignSlice