import React, { useState, useRef } from "react";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { AccountTitle, MainBox } from "../Account/Account.styled";
import {
  WalletBox,
  WalletOuterBox,
  InnerWalletBox,
} from "./Wallet.styled";
import Expenses from "./Expenses";
import WithdrawModal from "./WithdrawModal";
import Funding from "./Funding";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useGetWalletBalanceQuery, useGetExpenseQuery} from "../../features/api/walletSlice";
import { ScaleLoader } from "react-spinners";
import { numberWithCommasWithoutDecimal } from "../Campaign/helper";
import { ReactComponent as AddIcon } from "../../assets/icons/campaigns-add.svg";


const Wallet: React.FC = () => {
  const [showRole, setShowRole] = useState(false);
  const [showTabs, setShowTabs] = useState({ earnings: true, withdraw: false });

  const {data: walletDetail, isLoading: gettingWalletDetail} = useGetWalletBalanceQuery();
  const {data: expenses, isLoading: gettingExpenses} = useGetExpenseQuery();

  const modalRef = useRef<HTMLElement>(null);

  const handleRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  useOutsideClick(modalRef, handleCloseRole);

  const handleEarnings = () => {
    setShowTabs({ earnings: true, withdraw: false });
  };
  const handleWithdraw = () => {
    setShowTabs({ earnings: false, withdraw: true });
  };

  return (
    <Section>
      <WalletOuterBox>
        <WalletBox>
          <InnerWalletBox>
            <h4>Available Balance</h4>
            <p> ₦{gettingWalletDetail? 
            <ScaleLoader
              loading={gettingWalletDetail}
              height={12}
              width={2}
              color={"#3d5170"}
            /> 
            :  numberWithCommasWithoutDecimal(walletDetail?.data?.wallet_balance || 0)}</p>
          </InnerWalletBox>
          <div>
            <Button
              text="Fund Wallet"
              paddingProps="7px 15px"
              fontSizeProps="0.875rem"
              onClick={handleRole}
              icon={<AddIcon />}
            />
          </div>
        </WalletBox>
        <WalletBox>
          <InnerWalletBox>
            <h4>Total Expenses</h4>
            <p> ₦{gettingExpenses? 
            <ScaleLoader
              loading={gettingExpenses}
              height={12}
              width={2}
              color={"#3d5170"}
            /> 
            : numberWithCommasWithoutDecimal(expenses?.data || 0)}</p>
          </InnerWalletBox>

          {/* <WalletSelect>
            <ButtonReset>Weekly</ButtonReset>
            <img src={ArrowYellow} alt="" />
          </WalletSelect> */}
        </WalletBox>
      </WalletOuterBox>
      <AccountTitle>Transaction History</AccountTitle>
      <MainBox>
        <GraphTab widthGraphBox="fit-content">
          <button
            onClick={handleEarnings}
            className={`${showTabs.earnings ? "active" : ""}`}
          >
            Expenses
          </button>
          <button
            onClick={handleWithdraw}
            className={`${showTabs.withdraw ? "active" : ""}`}
          >
            Funding
          </button>
        </GraphTab>
        {showTabs.earnings&& <Expenses />}
        {showTabs.withdraw && <Funding />}
        <WithdrawModal
          show={showRole}
          handleCloseModal={handleCloseRole}
          modalRef={modalRef}
        />
      </MainBox>
    </Section>
  );
};

export default Wallet;