import React, { useEffect, useState } from "react";
import {
  BackArrow,
  ErrorMsg,
  Form,
} from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
  Avatar,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { Link } from "react-router-dom";
import BeeLogo from "../../assets/icons/Account/company-logo.svg";
import { BoxIcon, FileInput, Relative } from "./Account.styled";
import { Input16, Label14 } from "../Campaign/Campaign.styled";
import { ModalMainEditProfileProps, ModalMainProps } from "../../types";
import { useUpdateProfileMutation } from "../../features/api/accountSlice";
import toast from "react-hot-toast";
import { UserDetail } from "../../features/api/authTypes";
import { CircleSpan } from "../Reusable/Reuseable.styled";

const INITIAL_STATE = {
  firstName: "",
  lastName: "",
  businessName: "",
  // email: "",
  phoneNumber: "",
};
const EditProfile: React.FC<ModalMainEditProfileProps> = ({
  show,
  handleCloseModal,
  modalRef,
  updateUserFromLocalStorage,
}) => {
  const [form, setForm] = useState(INITIAL_STATE);
  const [profileUrl, setprofileUrl] = useState<string>("");
  const [photoFile, setphotoFile] = useState<File>();
  const [errMsg, setErr] = useState<string>("");

  // Get user
  const [userDetail, setUser] = useState<UserDetail>();

  // const { name, email, verificationStatus, refCode, profileImageUrl } =
  //   useAppSelector((state) => state.auth);
  const [updateProfile, { isLoading }] = useUpdateProfileMutation();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const selectedFile = e.target.files?.[0];
    setphotoFile(selectedFile)
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setprofileUrl(blobUrl);
    }
    setForm({ ...form, [e.target.name]: value });
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);

  useEffect(() => {
    if (userDetail) {
      setForm({
        ...form,
        firstName: userDetail.first_name,
        lastName: userDetail.last_name,
        businessName: userDetail.businesses[0]?.name,
        phoneNumber: userDetail.phone_number || "",
      });
      setprofileUrl(userDetail.photo || "");
    }
  }, [userDetail]);

  // fallback image
  function imgError(e: any) {
    e.target.src = BeeLogo;
  }

  const handleProfileUpdate = () => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", form.firstName ? form.firstName : "");
    bodyFormData.append("LastName", form.lastName? form.lastName : "");
    bodyFormData.append("PhoneNumber", form.phoneNumber ? form.phoneNumber : "");
    bodyFormData.append("UpdateNetwork.NetworkName", "");
    bodyFormData.append("UpdateBusiness.BusinessName", form.businessName ? form.businessName : "");
    bodyFormData.append("Photo", photoFile ? photoFile : profileUrl);
    updateProfile(bodyFormData)
      .unwrap()
      .then((res) => {
        if (res.success) {
          const newUserObj = { ...userDetail };
          if (newUserObj && newUserObj.businesses && newUserObj.businesses[0]) {
            newUserObj.businesses[0].name = form.businessName;
          }
          if (newUserObj) {
            newUserObj.phone_number = form.phoneNumber;
            newUserObj.first_name = form.firstName;
            newUserObj.last_name = form.lastName;
            newUserObj.photo = profileUrl;
          }
          localStorage.setItem("user", JSON.stringify(newUserObj));
          updateUserFromLocalStorage();
          handleCloseModal();
          toast.success("Profile Updated Successfully");
        } else {
          setErr(res.Message);
        }
      })
      .catch((error) => {
        setErr("An error occured");
      });
  };

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Edit Profile</ModalTitle>
          </div>
        </BackArrow>
        <BoxIcon>
          {/* <CircleSpan width="120px" height="120px" background="#f3eeee">
            {profileUrl ? 
              <img
                src={profileUrl || ""}
                alt="Company's logo"
                width={80}
                height={80}
                className="companyLogo"
                //onError={imgError}
              /> :
              <div>
                 <Avatar className="companyLogo">
                  <p>
                    {form.firstName?.slice(0, 2).toUpperCase() || ""}
                  </p>
                </Avatar>
              </div>
              }
          </CircleSpan> */}
          <CircleSpan width="120px" height="120px" background="#f3eeee">
            {profileUrl ? (
              <img
                src={profileUrl || ""}
                alt="Company's logo"
                width={80}
                height={80}
                onError={imgError}
                className="image-round"
              />
            ) : (
              <Avatar className="image-round">
                <p> {form.firstName?.slice(0, 2).toUpperCase() || ""}</p>
              </Avatar>
            )}
          </CircleSpan>
          <Relative>
            <Link to={""}>Change</Link>
            <FileInput
              type="file"
              name="file"
              id="idCard"
              onChange={handleChange}
              accept=".png, .jpeg, .jpg"
            />
          </Relative>
        </BoxIcon>
        <Form height="100%">
          <div>
            <Label14 htmlFor="firstName">First Name</Label14>
            <Input16
              type="text"
              placeholder="Adayemi"
              value={form.firstName}
              onChange={handleChange}
              name="firstName"
              id="firstName"
            />
          </div>
          <div>
            <Label14 htmlFor="lastName">Last Name</Label14>
            <Input16
              type="text"
              placeholder="Adam"
              value={form.lastName}
              onChange={handleChange}
              name="lastName"
              id="lastName"
            />
          </div>
          <div>
            <Label14 htmlFor="businessName">Business Name</Label14>
            <Input16
              type="text"
              placeholder="Adam"
              value={form.businessName}
              onChange={handleChange}
              name="businessName"
              id="businessName"
            />
          </div>

          {/* <div>
            <Label14 htmlFor="email">Email</Label14>
            <Input16
              type="email"
              value={form.email}
              onChange={handleChange}
              name="email"
              id="email"
              placeholder="Adayemiadam@gmail.com"
            />
          </div> */}
          <div>
            <Label14 htmlFor="phoneNumber">Phone number</Label14>
            <Input16
              type="tel"
              value={form.phoneNumber}
              onChange={handleChange}
              name="phoneNumber"
              id="phoneNumber"
              placeholder="+000 000 000 0000"
            />
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <Button
          text="Save"
          widthProps="30%"
          height="40px"
          marginTop="auto"
          onClick={handleProfileUpdate}
          isLoading={isLoading}
        />
      </ModalContainer>
    </MainContainer>
  );
};

export default EditProfile;
