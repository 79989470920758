import React from "react";
import { Link, Outlet, useLocation, useParams, useNavigate } from "react-router-dom";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import { TextTitle1, TextTitle1Span } from "../../styles/all.styled";
import { useGetCampaignQuery } from "../../features/api/campaignSlice";

const MainCampaign: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate()
  const {campaignId} = useParams();
  const {data: campaignDetail} = useGetCampaignQuery(Number(campaignId) as number);
  return (
    <Section>
      <div>
        <TextTitle1>
        <span className="pointer" onClick={()=> navigate("/home/campaign")}> Campaigns  </span> - 
          <TextTitle1Span>{campaignDetail?.data?.name}</TextTitle1Span>
        </TextTitle1>
      </div>

      <GraphTab>
        <Link
          to={`/home/campaign/main-campaign/${campaignId}/overview`}
          className={
            location.pathname === `/home/campaign/main-campaign/${campaignId}/overview`
              ? "active"
              : ""
          }
        >
          Overview
        </Link>
        <Link
          to={`/home/campaign/main-campaign/${campaignId}/advert-campaign`}
          className={
            location.pathname === `/home/campaign/main-campaign/${campaignId}/advert-campaign`
              ? "active"
              : ""
          }
        >
          My Adverts
        </Link>
      </GraphTab>
      <Outlet />
    </Section>
  );
};

export default MainCampaign;
