import React, { useState } from "react";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { LogoBox, SideBox, SideContainer, InnerNav } from "./Dashboard.styled";
import { SIDEBAR_DATA } from "../../data/SideBarData";
import NavTab from "./NavTab";
import { SideNavbarProps } from "../../types";
import { useLocation } from "react-router-dom";

const SideNavbar: React.FC<SideNavbarProps> = ({
  showNavbar,
  handleCloseNav,
  modalRef,
}) => {
  const [selected, setSelected] = useState(0);
  const location = useLocation();

  const handleSelect = (index: number) => {
    setSelected(index);
    if (handleCloseNav) {
      handleCloseNav();
    }
  };

  return (
    <SideContainer showMenu={showNavbar} ref={modalRef}>
      <InnerNav>
        <LogoBox>
          <Logo className="sidelogo" />
          <button onClick={handleCloseNav}>
            <p>X</p>
          </button>
        </LogoBox>
        <SideBox gap="15px">
          {SIDEBAR_DATA.map((item, index) => {
            return (
              <NavTab
                key={index}
                className={
                  location.pathname.split("/")[2] === item.path
                    ? "Tab active"
                    : "Tab"
                }
                {...item}
                handleSelect={() => handleSelect(index)}
              />
            );
          })}
        </SideBox>
      </InnerNav>
    </SideContainer>
  );
};

export default SideNavbar;
