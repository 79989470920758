import React from "react";
import { useNavigate } from "react-router-dom";
import { LogoutBox, Text14 } from "./Dashboard.styled";
import LogoutIcon from "../../assets/icons/logout.svg";

const Logout = () => {
  const navigate = useNavigate();
  const handleLogOut = () =>{
    localStorage.clear();
    navigate("/")
  }

  return (
    <LogoutBox onClick ={handleLogOut}>
      <img src={LogoutIcon} alt="Log out" />
      <Text14 color="#ff1e1e">Log Out</Text14>
    </LogoutBox>
  );
};

export default Logout;
