import React, { useState, useRef } from "react";
import BgAdvert from "../../assets/images/Frame.png";
import { Button } from "../Reusable/Buttons/button";
import { PendBox, TextCenter } from "./Campaign.styled";
import { CreateCampaignModal } from "./CampaignModals";
import useOutsideClick from "../../hooks/useOutsideClick";
import { Text14 } from "../../styles/all.styled";

const CampaignEmpty: React.FC = () => {
  const [isCreateCampaign, setIsCreateCampaign] = useState<boolean>(false);
  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsCreateCampaign(false);
  };

  useOutsideClick(modalRef, handleCloseModal);

  return (
    <PendBox>
      <img
        src={BgAdvert}
        alt=""
        className="bg-ad-img"
        width={253}
        height={128}
      />
      <TextCenter>
        <Text14 fontSize="1.25rem">Start a campaign </Text14>
        <Text14 color="#6b6b6b">
          There's no campaign currently, you can create a new campaign by
          clicking on the button below to start
        </Text14>
        <div className="btn-width">
          <Button
            text="Create Campaign"
            onClick={() => setIsCreateCampaign(!isCreateCampaign)}
          />
        </div>
      </TextCenter>
      {isCreateCampaign && (
        <CreateCampaignModal
          show={isCreateCampaign}
          modalRef={modalRef}
          handleCloseModal={handleCloseModal}
        />
      )}
    </PendBox>
  );
};

export default CampaignEmpty;
