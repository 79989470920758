import {
  CircularProgressbar,
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  CircularText1,
  CircularTextBox,
  CircularTextP,
  CircularTextSpan,
} from "./Campaign.styled";

export const CircularProgress = () => (
  <div style={{ paddingTop: "20px" }} className="circular">
    <CircularProgressbarWithChildren
      value={72}
      circleRatio={0.6}
      styles={buildStyles({
        rotation: 1 / 2 + 1 / 5,
        strokeLinecap: "rounded",
        trailColor: "#E5EAFC",
        pathColor: "#25346A",
        textSize: "14px",
      })}
    >
      <CircularTextBox className="bar-main">
        <CircularText1>72%</CircularText1>
        <CircularTextP>
          Expense: <CircularTextSpan>$24,400</CircularTextSpan>
        </CircularTextP>
      </CircularTextBox>
    </CircularProgressbarWithChildren>
  </div>
);
