import React, { useRef, useState } from "react";
import SideNavbar from "../components/Dashboard/SideNavbar";
import Breadcrumbs from "../components/Partials/breadcrumbs";
import {
  ContainerDB,
  BoxDB,
  HeaderBox,
} from "../components/Dashboard/Dashboard.styled";
import DashboardModal from "../components/Dashboard/DashboardModal";
import { Outlet } from "react-router-dom";
import useOutsideClick from "../hooks/useOutsideClick";
import Logout from "../components/Dashboard/Logout";
import { Relative } from "../components/Account/Account.styled";

const Dashboard: React.FC = () => {
  const [showModal, setShowModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);

  // Log out
  const [showLogout, setShowLogout] = useState(false);

  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef<HTMLElement>(null);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMenu = () => {
    setShowNavbar(true);
  };

  const handleCloseNav = () => {
    setShowNavbar(false);
  };

  const handleLogOut = () => {
    setShowLogout((prev) => !prev);
  };

  useOutsideClick(modalRef2, handleCloseNav);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ContainerDB>
      <SideNavbar
        showNavbar={showNavbar}
        handleCloseNav={handleCloseNav}
        modalRef={modalRef2}
      />
      <BoxDB>
        <HeaderBox>
          <Breadcrumbs
            handleNotifyClick={handleOpenModal}
            handleMenu={handleMenu}
            handleLogOut={handleLogOut}
          />
          {showLogout && <Logout />}
        </HeaderBox>
        <Outlet />
        <DashboardModal
          show={showModal}
          handleCloseModal={handleCloseModal}
          modalRef={modalRef}
        />
      </BoxDB>
    </ContainerDB>
  );
};

export default Dashboard;
