import {
  CreateBox,
  EachBox,
  FlexBox,
  LineBottom,
  PieBox,
  RoundBox,
} from "../Campaign.styled";
import {
  Text14,
  Text400H5,
  TextTitle2,
  TextTitle3,
} from "../../../styles/all.styled";
import { Button } from "../../Reusable/Buttons/button";
import CampaignPieChart from "../CampaignPieChart";
import { CircularProgress } from "../CircularProgrsess";
import { ReactComponent as Pause } from "../../../assets/icons/campaigns-ads-overview-pause.svg";
import { usePauseAdvertMutation } from "../../../features/api/campaignSlice";
import { useParams } from "react-router-dom";

const OverviewFlashsales = () => {

  const { advertId } = useParams();
  const [pauseAdvert, {isLoading: pausingAdvert}] = usePauseAdvertMutation()

  const handlePauseAdvert = () =>{
    pauseAdvert(Number(advertId) as number)
    .then((res)=>{
      console.log(res, 'i am the pause value')
    })
    .catch((err)=>{
      console.log(err, 'i am the err')
    })
  }
  return (
    <section>
      <EachBox justifyContent="space-between">
        <TextTitle3>Analytics</TextTitle3>
        <Button
          text="Pause Advert"
          fontSizeProps="0.875rem"
          widthProps="10rem"
          isLoading={pausingAdvert}
          onClick={handlePauseAdvert}
          icon={<Pause />}
        />
      </EachBox>

      <FlexBox
        MarginTop="1rem"
        gap="2.5rem"
        alignItems="flex-start"
        flexWrap="wrap"
      >
        <PieBox>
          <Text400H5>Statistics</Text400H5>
          <TextTitle2>Views by country</TextTitle2>
          <LineBottom></LineBottom>
          <FlexBox>
            <CampaignPieChart />
            <CreateBox gap="1rem">
              <FlexBox>
                <FlexBox justifyContent="flex-start">
                  <RoundBox background="#25346A"></RoundBox>
                  <Text14 color="#1E1B39">USA</Text14>
                </FlexBox>
                <FlexBox justifyContent="flex-start" gap="0.8rem">
                  <Text14 color="#615E83" fontWeight="400">
                    39.11%
                  </Text14>
                  <Text14 color="#00C087" fontWeight="400">
                    (+2.98%)
                  </Text14>
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox justifyContent="flex-start">
                  <RoundBox background="#1D3FB5"></RoundBox>
                  <Text14 color="#1E1B39">Canada</Text14>
                </FlexBox>
                <FlexBox justifyContent="flex-start" gap="0.8rem">
                  <Text14 color="#615E83" fontWeight="400">
                    28.02%
                  </Text14>
                  <Text14 color="#FF1E1E" fontWeight="400">
                    (-3.25%)
                  </Text14>
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox justifyContent="flex-start">
                  <RoundBox background="#93AAFD"></RoundBox>
                  <Text14 color="#1E1B39">U.K.</Text14>
                </FlexBox>
                <FlexBox justifyContent="flex-start" gap="0.8rem">
                  <Text14 color="#615E83" fontWeight="400">
                    23.13%
                  </Text14>
                  <Text14 color="#00C087" fontWeight="400">
                    (+0.14%)
                  </Text14>
                </FlexBox>
              </FlexBox>
              <FlexBox>
                <FlexBox justifyContent="flex-start">
                  <RoundBox background="#C6D2FD"></RoundBox>
                  <Text14 color="#1E1B39">Australia</Text14>
                </FlexBox>
                <FlexBox justifyContent="flex-start" gap="0.8rem">
                  <Text14 color="#615E83" fontWeight="400">
                    5.03%
                  </Text14>
                  <Text14 color="#FF1E1E" fontWeight="400">
                    (-1.11%)
                  </Text14>
                </FlexBox>
              </FlexBox>
            </CreateBox>
          </FlexBox>
        </PieBox>
        <PieBox>
          <FlexBox>
            <div>
              <Text400H5>Estimated Cost</Text400H5>
              <TextTitle2>$32,000</TextTitle2>
            </div>
            <div>
              <Text400H5 color="#FF718B">Remaining</Text400H5>
              <TextTitle2>$7,600</TextTitle2>
            </div>
          </FlexBox>
          <LineBottom></LineBottom>

          <div style={{ height: "18rem" }}>
            <CircularProgress />
          </div>
        </PieBox>
      </FlexBox>
    </section>
  );
};

export default OverviewFlashsales;
