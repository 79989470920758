import React, { useState } from "react";
import {
  Input,
  Label,
  Title,
  InnerContainer,
  Description,
  BackArrow,
  ContainerVerify,
  FlexBox,
  Form,
  ErrorMsg,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Link } from "react-router-dom";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import { Button } from "../Reusable/Buttons/button";
import { useRequestPasswordResetMutation } from "../../features/api/authSlice";

interface Reset1Props {
  setEmail: (val: string) => void;
  email: string;
  handleNext: (val: number) => void;
}

const Reset1: React.FC<Reset1Props> = ({ setEmail, email, handleNext }) => {
  const [error, setError] = useState("");
  const [reset, { isLoading: sendingOtp }] = useRequestPasswordResetMutation();

  const handleSubmit = () => {
    //TODO: check if it takes the normal email format
    if (email) {
      setError("");
      let data = { email: email };
      reset(data)
        .unwrap()
        .then((res) => {
          if (res) {
            handleNext(2);
          }
        });
    } else {
      setError("Please enter a valid email");
    }
  };
  return (
    <ContainerVerify>
      <InnerContainer gap="3.75rem">
        <FlexBox>
          <Logo className="logo" width={115} height={25.578} />

          <Title>Reset Password</Title>
          <Description className="text-center" marginBottom="0">
            Enter your Wicrypt email, and we'll send you instructions on how to
            reset your password.
          </Description>
        </FlexBox>
        <Form>
          <Label htmlFor="email" textAlign="left">
            Email Address
          </Label>
          <Input
            type="email"
            name="email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(e.target.value)
            }
            id="email"
            placeholder="youremail@gmail.com"
          />
          <ErrorMsg> {error}</ErrorMsg>
        </Form>

        <Button
          text="Send me instructions"
          isLoading={sendingOtp}
          onClick={() => handleSubmit()}
          height="40px"
        />

        <BackArrow>
          <Link to="/" className="back">
            <ArrowLeft />
            <span>Back</span>
          </Link>
        </BackArrow>
      </InnerContainer>
    </ContainerVerify>
  );
};

export default Reset1;
