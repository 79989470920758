import styled from "styled-components";
import { TextProps } from "../Campaign/types";

export const CloseBtn = styled.button<TextProps>`
  background: transparent;
  cursor: pointer;
  border: none;
  svg path {
    fill: ${(props) => props.fill || "white"};
  }
`;

export const CloseBtn2 = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #f5f5f5;
  width: 30px;
  aspect-ratio: 1;
  border: none;
`;

export const LoaderContainer = styled.div`
  margin: 0 auto;
`;

export const LoaderBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PendBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 75vh; */
  /* height: calc(100% - 298.28px); */
  gap: 2.875rem;
  padding-top: 4rem;
  .empty-box {
    width: 530px;
  }
  p {
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.4;
    text-align: center;
  }
  .details {
    font-size: 0.875rem;
    font-weight: 400;
    color: #6b6b6b;
  }
  .btn-width {
    width: 10rem;
    margin: 3rem auto 0;
  }
  @media (max-width: 600px) {
    .bg-ad-img {
      width: 199.5px;
      height: 124.61px;
    }
    .empty-box {
      width: 80%;
    }
  }
`;

export const CircleSpan = styled.span<TextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background || "#e5b910"};
  font-size: 0.75rem;
  color: #fff;
  width: ${(props) => props.width || "18px"};
  height: ${(props) => props.height || "18px"};
  border-radius: 50%;
  margin-right: ${(props) => props.marginRight || "0"};

  .image-round {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;