import React, { useRef } from "react";
import { EachBox, FlexBox, ImgUploadBox, RoundBox } from "./Campaign.styled";
import { AccountTitle, HeadingBox } from "../Account/Account.styled";
import { ButtonReset } from "../Reusable/Buttons/button";
import { ReactComponent as FolderFile } from "../../assets/icons/campaigns-folder-file.svg";
import {
  Text12,
  Text14,
  Text400,
  TextTitle3,
  PicBox,
  Text10,
} from "../../styles/all.styled";
import { ErrorMessage } from "../Reusable/Error";

interface MediaFile {
  file: File;
  url: string;
}
interface ContentProps {
  handleImage: (index: number) => void;
  handleAdvertUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  advertImages: MediaFile[];
  handleDrop: (e: React.DragEvent<HTMLDivElement>)=> void;
  errMsg: string
}

const CreateContent = ({ handleImage, advertImages, handleAdvertUpload, handleDrop, errMsg }: ContentProps) => {

   // Reference to the hidden file input element
   const fileInputRef = useRef<HTMLInputElement | null>(null);

  // Function to handle click on ImgUploadBox
  const handleImgUploadBoxClick = (): void => {
    // Trigger the click event on the hidden file input
    fileInputRef?.current?.click();
  };
  
  const renderMedia = (media: MediaFile, index: number) => {
    if (media.file.type.startsWith('video/')) {
      return (
        <ButtonReset >
          <video key={index} src={media.url} controls width="300" />
        </ButtonReset >
      );
    } else if (media.file.type.startsWith('image/')) {
      return (
        <ButtonReset >
        <img key={index} src={media.url} alt={`advert-${index}`} width="300" />
        </ButtonReset >
      );
    }
    return null;
  };

  return (
    <section>
      <EachBox>
        <TextTitle3>Content Uploads</TextTitle3>
      </EachBox>
      <div style={{ marginTop: "2rem" }}>
        <Text400>Image Uploader</Text400>
        <ImgUploadBox 
          onClick={handleImgUploadBoxClick}
          onDragOver={(e)=> e.preventDefault()}
          onDrop={(e)=> handleDrop(e)}
        >
          <FolderFile width={30} height={30} />
          <div>
            <Text14 color="#25346A" marginBottom="0">
              Drag and drop or browse for media
            </Text14>
            <Text400 fontSize="0.75rem">Max size 10MB each</Text400>
          </div>
          <input
            type="file"
            name="file"
            id="idCard"
            ref={fileInputRef}
            onChange={handleAdvertUpload}
            accept=".png, .jpeg, .jpg, .mp4, .avi, .mkv, .mov, .wmv, .flv, .webm, .ogg"
            style={{ display: "none" }}
          />
          {/* <div>
            <div>
              <FolderFile width={20} height={20} />
              <img src={CheckIcon} alt="" width={20} height={20} />
              <Text10 color="#C4C4C4" fontWeight="400">
                64% Ready
              </Text10>
            </div>
          </div> */}
          <FlexBox gap="2rem" style={{ marginTop: "2rem" }}>
            <FlexBox>
              <RoundBox></RoundBox>
              <Text12 color="#6B6B6B">Aspect Ratio 16:9</Text12>
            </FlexBox>
            <FlexBox>
              <RoundBox></RoundBox>
              <Text12 color="#6B6B6B">Recommended Size 467 * 548</Text12>
            </FlexBox>
          </FlexBox>
        </ImgUploadBox>
      </div>
      <div>
        <HeadingBox>
          <AccountTitle>Advert image</AccountTitle>
        </HeadingBox>
        
        <PicBox>
          {/* {advertImages.map((adImage)=>{
            return(
              <ButtonReset >
                <img src={adImage} alt="Advert Pictures" />
              </ButtonReset>
            )
          })} */}

          {advertImages.map((url, index) => renderMedia(url, index))}
        </PicBox>
      </div>
      <ErrorMessage errMsg={errMsg} />
    </section>
  );
};

export default CreateContent;
