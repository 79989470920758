import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from './config';

// initialize an empty api service that we'll inject endpoints into later as needed
export const apiSlice = createApi({
    reducerPath: "apiUrl",
    baseQuery: fetchBaseQuery({
        baseUrl: config.apiUrl,
        prepareHeaders: (headers) => {
            const token = JSON.parse(localStorage.getItem("token") || "{}");
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
                return headers;
            }
            return;
        },
    }),
    tagTypes: ['Campaigns', 'Adverts'],
    endpoints: () => ({}),
})