import styled from "styled-components";

interface TextProps {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  marginBottom?: string;
  marginTop?: string;
  width?: string;
  fontWeightMd?: string;
  opacity?: string;
}

// Text style

export const TextTitle1 = styled.h2<TextProps>`
  color: #c4c4c4;
  font-size: 0.75rem; //12px
  font-weight: 400;
  line-height: 1.2;
  margin-bottom: 2.125rem;
  // cursor: pointer;
`;

export const TextTitle1Span = styled(TextTitle1).attrs({ as: "span" })`
  font-weight: 600;
  color: #25346a;
`;

export const TextTitle2 = styled.h2<TextProps>`
  color: ${(props) => props.color || "#1a1a1a"};
  font-size: ${(props) => props.fontSize || "1.375rem"}; //22px
  font-weight: 600;
  line-height: 1.5;
  margin-bottom: ${(props) => props.marginBottom || "1.5rem"};
`;

export const TextTitle3 = styled.h3<TextProps>`
  font-size: 1.125rem; //18px
  font-weight: 500;
  line-height: normal;
  color: #000;
  letter-spacing: 0.1px;
`;

export const TextTitle4 = styled.h4<TextProps>`
  font-size: 1.5rem; //24px
  font-weight: 500;
  line-height: 1.4;
  color: #000;
`;

export const Text14 = styled.p<TextProps>`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "0.75rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  line-height: 1.4;
  margin-bottom: ${(props) => props.marginBottom || "0"};
`;

export const Text400 = styled.p<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: 400;
  line-height: 1.4;
`;

export const Text400H5 = styled(Text400).attrs({ as: "h5" })`
  color: ${(props) => props.color || "#9291a5"};
  font-size: 1.125rem;
`;

export const Text12 = styled.p<TextProps>`
  color: ${(props) => props.color || "#000"};
  font-size: ${(props) => props.fontSize || "0.75rem"};
  font-weight: ${(props) => props.fontWeight || "400"};
  line-height: ${(props) => props.lineHeight || "1.4"};
  margin-bottom: ${(props) => props.marginBottom || "0"};
  margin-top: ${(props) => props.marginTop || "0"};
  white-space: nowrap;
`;

export const Text10 = styled.p<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: ${(props) => props.fontSize || "0.625rem"};
  font-weight: ${(props) => props.fontWeight || "500"};
  line-height: ${(props) => props.lineHeight || "1"};
  opacity: ${(props) => props.opacity || "1"};

  /* white-space: nowrap; */
  @media (min-width: 765px) {
    font-weight: ${(props) => props.fontWeightMd || "500"};
  }
`;

export const Text14Span = styled(Text14).attrs({ as: "span" })`
  margin-bottom: 0;
  white-space: nowrap;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 3.5rem;
  margin-top: 1.875rem;

  th,
  td {
    border: 1px solid #ccc;
    padding: 1.34rem;
    border-right: none;
    border-left: none;
  }

  th {
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 1.4;
    text-transform: uppercase;
    color: #1a1a1a;
  }

  td {
    font-size: 0.875rem;
    line-height: 1.4;
  }

  tr:nth-child(2n) {
    background: #fbfbfb;
    border-bottom: 1px solid #efefef;
  }
`;

export const ADBox2 = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.875rem;
`;

export const ImageAD = styled.img``;

export const PicBox = styled.div`
  display: flex;
  gap: 1.5rem;
`;

export const ViewMoreBox = styled.button`
  border: none;
  border-radius: 26px;
  color: white;
  background: #25346a;
  padding: 0.4rem 1rem;
  cursor: pointer;
  text-align: center;
`;
