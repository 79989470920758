import React, { useRef, useState } from "react";
import {
  Title,
  InnerContainer,
  Description,
  ContainerVerify,
  FlexBox,
  InputBox,
  InputNum,
  ErrorMsg,
  BackButtonContainer,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Button } from "../Reusable/Buttons/button";
import ProgressBar from "./progressBar";
import { useVerifytwoFAMutation } from "../../features/api/authSlice";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";

interface ICreatePassword {
  email: string;
  setStep: (val: number)=> void
}

const TwoFAVerifcation: React.FC<ICreatePassword> = ({
  email, setStep
}) => {
    const [errMsg, setError] = useState<string>("");
    const [pin, setPin] = useState<Array<string | null>>([
        null,
        null,
        null,
        null,
        null,
        null,
      ]);
    const [focusIndex, setFocusIndex] = useState(0);
    const [verifyTwoFA, {isLoading: loading}] = useVerifytwoFAMutation()

    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

    const handleDigit = (val: string, idx: number) => {
    if (val.length > 1) {
        val = val.slice(0, 1);
    }
    setPin((pre) => pre.map((e, id) => (idx === id ? val : e)));

    if (val.length === 1) {
        const nextIndex = idx + 1;
        inputRefs.current[nextIndex]?.focus();
    }
    };

    const handleKeyDown = (key: string, idx: number) => {
        if (key === "Backspace" && pin[idx] === "") {
          const preIndex = idx - 1;
          inputRefs.current[preIndex]?.focus();
        }
      };
    
      const handleFocus = (idx: number) => {
        setFocusIndex(idx);
      };

    const handleVerification = () =>{
      let stringPin = pin.join("");
      if (stringPin.length !== 6) {
        setError("Please enter a valid pin");
      } else {
        setError("")
        let data = {
          email: email,
          code: stringPin,
        };
        verifyTwoFA(data)
        .unwrap()
        .then((res)=>{
          setStep(6)
        })
        .catch((err)=>{
          setError(err?.data?.Message)
        })

      }
      
    }
    

  return (
    <ContainerVerify as={"section"}>
      <InnerContainer gap="3.75rem">
        <FlexBox>
          <Logo className="logo" width={115} height={25.578} />
          <BackButtonContainer onClick={()=> setStep(4)}> 
              <ArrowLeft className="arrow-left" />
              <span> Back</span>
          </BackButtonContainer>
          <ProgressBar percentage={100} text={"4/4"} />
          <div>
            <Title marginBottom="1rem">Enter Google Auth Code</Title>
            <Description className="welcome" marginBottom="1rem">
              Welcome : <span className="email">{email}</span>
            </Description>
          </div>
          <Description className="desc">
            Please enter your 6-digit authentication code from the Google Authenticator app.
          </Description>
          <InputBox>
            {pin.map((val, idx) => {
              return (
                <InputNum
                  type="number"
                  name="digit1"
                  value={val || ""}
                  max={1}
                  onChange={(e) => handleDigit(e.target.value, idx)}
                  ref={(ref) => (inputRefs.current[idx] = ref)}
                  className={`${
                    val !== null && val !== "" ? "inputClr" : "inputGrey"
                  } ${idx === focusIndex ? "borderBlue" : ""}`}
                  onKeyDown={(e) => handleKeyDown(e.key, idx)}
                  onFocus={() => handleFocus(idx)}
                />
              );
            })}
          </InputBox>
        </FlexBox>
        <ErrorMsg> {errMsg}</ErrorMsg>
        <Button
          text="Confirm"
          onClick={() => handleVerification()}
          isLoading={loading}
          height="40px"
        />
        <p className="desc mb-0 t-center">
         Can’t access your Google Authenticator app?
        </p>
        <p className="authenticator pointer t-center" onClick={()=> setStep(4)}> 
          Reset 2FA here
        </p>
      </InnerContainer>
    </ContainerVerify>
  );
};

export default TwoFAVerifcation;
