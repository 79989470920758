import React, { useState } from "react";
import {
  Container,
  Input,
  Label,
  Terms,
  CreateLink,
  WrapperLeft,
  InnerContainer,
  Description,
  Form,
  Title,
  ErrorMsg,
  MarginTop,
} from "./styles/Container.styled";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { Link } from "react-router-dom";
import { Button } from "../Reusable/Buttons/button";
import WrapperRightSide from "./WrapperRightSide";

interface ICreateAccount {
  createAccount: () => void;
  err: string;
  loading: boolean;
  email: string;
  setEmail: (val: string) => void;
  setFirstName: (val: string) => void;
  firstName: string;
  setLastName: (val: string) => void;
  lastName: string;
  setBusinessName: (val: string) => void;
  businessName: string;
}

const CreateAccount = ({
  createAccount,
  err,
  loading,
  email,
  setEmail,
  firstName,
  setFirstName,
  setLastName,
  lastName,
  setBusinessName,
  businessName
  
}: ICreateAccount) => {
  const [isAgree, setIsAgree] = useState<boolean>(false);
  const [errMsg, setError] = useState<string>("");

  const handleSubmit = () => {
    if (!email) {
      setError("Please enter a valid email");
    } else if (!firstName) {
      setError("Please enter your first name");
    } else if (!lastName) {
      setError("Please enter your last name");
    } else if (!isAgree) {
      setError("Please accept the terms and conditions to proceed");
    } else {
      setError("");
      createAccount();
    }
  };

  return (
    <Container>
      <WrapperLeft>
        <InnerContainer gap="1.5rem">
          <div>
            <Logo className="logo" width={115} height={25.578} />
            <div className="">
              <Title>Create Account</Title>
              <Description>
                Sign up now to take advantage of the fantastic services we
                provide.
              </Description>
              {/* <ButtonGoogle>
                <img
                  src="/Assets/icon/google.svg"
                  alt=""
                  className="googleImg"
                />
                <span className="button-text">Sign in to Google</span>
              </ButtonGoogle> */}
            </div>
          </div>
          {/* <Alternative> Or</Alternative> */}
          <Form>
            <div>
              <Label htmlFor="email" textAlign="left">
                Email Address
              </Label>
              <Input
                type="email"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Beemobile@gmail.com"
              />
            </div>
            <div>
              <Label htmlFor="firstName" textAlign="left">
                First Name
              </Label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                value={firstName}
                placeholder="Bee_mobile"
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <Label htmlFor="lastName" textAlign="left">
                Last Name
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Bee_mobile"
              />
            </div>
            <div>
              <Label htmlFor="lastName" textAlign="left">
                Business Name
              </Label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
                placeholder="Bee_mobile"
              />
            </div>
          </Form>
          <Terms>
            <input
              type="radio"
              name="terms"
              id="terms"
              checked={isAgree}
              onChange={() => setIsAgree(!isAgree)}
            />
            <Label htmlFor="terms" textAlign="left">
              I agree to the
              <a
                href="https://wicrypt.com/terms-condition.html"
                target="_blank"
                className="reset"
              >
                {" "}
                Terms and Conditions
              </a>
            </Label>
          </Terms>
          <ErrorMsg> {errMsg || err}</ErrorMsg>
          <MarginTop marginTop="-1rem">
            <Button
              text="Sign up"
              onClick={() => handleSubmit()}
              isLoading={loading}
              height="40px"
            />
          </MarginTop>

          <CreateLink className="">
            Already have an account?
            <Link to={"/"} className="create">
              <span> Sign in</span>
            </Link>
          </CreateLink>
        </InnerContainer>
      </WrapperLeft>
      <WrapperRightSide />
    </Container>
  );
};

export default CreateAccount;
