import React from "react";
import { AccountTitle } from "../../Account/Account.styled";
import TableMain from "../../Tables/TableMain";
import { columnsBids, defaultDataBids } from "../../Tables/TableData";
import {
  CampButton,
  EachBox,
  FlexBox,
  Input11,
  Label14,
  MarginTop,
  SettingBox,
} from "../Campaign.styled";
import NetworkIcon from "../../../assets/icons/network-icon.svg";
import CloseIcon from "../../../assets/icons/close-yellow.svg";
import { Button } from "../../Reusable/Buttons/button";
import { TextTitle3 } from "../../../styles/all.styled";

const Details: React.FC = () => {
  const greenColor = "#1A1A1A";
  return (
    <div>
      <div>
        <MarginTop>
          <EachBox>
            <TextTitle3>Network</TextTitle3>
          </EachBox>

          <FlexBox justifyContent="start" MarginTop="1.5rem">
            <CampButton>
              <img
                src={NetworkIcon}
                alt="Network Icon"
                width={10}
                height={10}
              />
              <p>Bee Mobile</p>
              <img src={CloseIcon} alt="Close Icon" width={10} height={10} />
            </CampButton>
          </FlexBox>
        </MarginTop>
        <MarginTop>
          <EachBox>
            <TextTitle3>Country</TextTitle3>
          </EachBox>

          <FlexBox justifyContent="start" MarginTop="1.5rem">
            <CampButton>
              <p>Nigeria</p>
              <img src={CloseIcon} alt="Close Icon" width={10} height={10} />
            </CampButton>
            <CampButton>
              <p>United Kingdom</p>
              <img src={CloseIcon} alt="Close Icon" width={10} height={10} />
            </CampButton>
            <CampButton>
              <p>United States</p>
              <img src={CloseIcon} alt="Close Icon" width={10} height={10} />
            </CampButton>
          </FlexBox>
        </MarginTop>
      </div>
      <MarginTop>
        <AccountTitle>Bids</AccountTitle>
        <div style={{ textAlign: "left" }}>
          <TableMain
            defaultData={defaultDataBids}
            columns={columnsBids}
            greenColor={greenColor}
          />
        </div>
      </MarginTop>
      <EachBox>
        <TextTitle3>Budget</TextTitle3>
      </EachBox>

      <SettingBox marginTop="1.5rem">
        <FlexBox justifyContent="flex-start" gap="1rem">
          <input type="radio" name="" id="" />
          <Label14 fontSize="1rem" htmlFor="">
            No daily Limits
          </Label14>
        </FlexBox>
        <FlexBox justifyContent="flex-start" gap="1rem">
          <input type="radio" name="" id="" />
          <Label14 fontSize="1rem" htmlFor="">
            Set Daily Limit Budget{" "}
          </Label14>
          <Label14 as={"p"}>₦</Label14>
          <Input11 type="number" placeholder="10" />
          {/* <Label14 as={"p"}>($ 5 Minimum)</Label14> */}
        </FlexBox>
      </SettingBox>
      <MarginTop marginTop="2rem">
        <Button
          text="Save Changes"
          fontSizeProps="0.875rem"
          widthProps="10rem"
          onClick={() => {}}
        />
      </MarginTop>
    </div>
  );
};

export default Details;
