import React, { useRef, useState } from "react";
import {
  BtnGroup,
  CampButton,
  CircleSpan,
  EachBox,
  FlexBox,
  Input16,
  Label14,
  MarginTop,
} from "./Campaign.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import BgAdvert from "../../assets/images/Frame.png";
import AddCampaign from "../../assets/icons/add-campaign.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-right.svg";
import NetworkIcon from "../../assets/icons/network-icon.svg";
import CloseIcon from "../../assets/icons/close-yellow.svg";
import {
  AdvertSummaryModal,
  CountryModal,
  NetworkModal,
} from "./CampaignModals";
import useOutsideClick from "../../hooks/useOutsideClick";
import CreateContent from "./CreateContent";
import AdBig from "../../assets/images/ad-big.png";
import PicAd from "../../assets/images/Rectangle 83.png";
import ImageGallery from "./ImageGallery";
import CreateAdvertBudget from "./CreateAdvertBudget";
import { Section } from "../Dashboard/Dashboard.styled";
import {
  Text12,
  Text14,
  TextTitle1,
  TextTitle1Span,
  TextTitle3,
} from "../../styles/all.styled";
import { Country } from "../Wallet/types";
import { Network } from "../../features/api/campaignSliceTypes";
import {
  useGetCampaignQuery,
  useCreateAdvertMutation,
  useUploadAdvertImagesMutation,
} from "../../features/api/campaignSlice";
import { useNavigate, useParams } from "react-router-dom";
import { ErrorMessage } from "../Reusable/Error";

interface MediaFile {
  file: File;
  url: string;
}

const CreateCampaign13 = () => {
  const navigate = useNavigate();

  const [isSelectNetwork, setIsSelectNetwork] = useState<boolean>(false);
  const [isSelectCountry, setIsSelectCountry] = useState<boolean>(false);
  const [errMsg, setError] = useState<string>("");
  const [file, setSelectedFile] = useState<File[]>([]);
  const [showRole2, setShowRole2] = useState<boolean>(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  //list of selected countries
  const [selectedCountries, setSelectedCountries] = useState<Country[]>([]);
  //list of selected networks
  const [selectedNetworks, setSelectedNetworks] = useState<Network[]>([]);
  //advert name
  const [advertName, setAdvertName] = useState<string>("");

  //advert images
  const [advertImages, setAdvertImages] = useState<MediaFile[]>([]);

  //my bid
  const [myBid, setBid] = useState<string>("");

  const [autoBudget, setisAutoBudget] = useState<boolean>(true);
  const [minimumBudget, setMinimumBudget] = useState<string>("");

  const { campaignId } = useParams();
  const { data: campaignDetail } = useGetCampaignQuery(
    Number(campaignId) as number
  );

  //Creating advert
  const [createAdvert, { isLoading: creatingAdvert }] =
    useCreateAdvertMutation();

  //Uploading image
  const [uploadAdverts, { isLoading: uploadingAdImages }] =
    useUploadAdvertImagesMutation();

  //step for the views
  const [step, setStep] = useState<number>(1);

  // Modal to open Advert Summary
  const [isAdvertSummary, setIsAdvertSummary] = useState<boolean>(false);

  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef(null);

  const handleCloseModal = () => {
    setIsSelectNetwork(false);
    setIsSelectCountry(false);
    setIsAdvertSummary(false)
  };

  const handleClick = () => {
    handleCloseModal();
  };

  const handleImage = (index: number) => {
    setSelectedImageIndex(index);
    setShowRole2(true);
  };

  const handleAdvertUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    setSelectedFile([...file, selectedFile as File]);
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setAdvertImages((prevFiles) => [...prevFiles, { file: selectedFile, url: blobUrl }]);
    }
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const selectedFile = e.dataTransfer?.files[0];
    setSelectedFile([...file, selectedFile as File]);
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setAdvertImages((prevFiles) => [...prevFiles, { file: selectedFile, url: blobUrl }]);
    }
  };

  const handlePreviousImage = () => {
    setSelectedImageIndex(
      (prevIndex) => (prevIndex - 1 + imagesAdvert.length) % imagesAdvert.length
    );
  };

  const imagesAdvert = [AdBig, PicAd];

  const handleCloseRole = () => {
    setShowRole2(false);
  };

  const handleNextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % imagesAdvert.length);
  };

  useOutsideClick(modalRef, handleClick);
  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef2, handleCloseRole);

  const handleCountrySelect = (country: Country) => {
    const countryIndex = selectedCountries.findIndex(
      (eachCountry: Country) => eachCountry.id === country.id
    );

    if (countryIndex !== -1) {
      // If the country exists in the selectedCountries array, remove it
      const updatedSelectedCountries = [...selectedCountries];
      updatedSelectedCountries.splice(countryIndex, 1);
      setSelectedCountries(updatedSelectedCountries);
    } else {
      // If the country doesn't exist, add it to the selectedCountries array
      setSelectedCountries([...selectedCountries, country]);
    }
  };

  const handleNetworkSelect = (network: Network) => {
    const networkindex = selectedNetworks.findIndex(
      (eachNetwork: Network) => eachNetwork.id === network.id
    );
    if (networkindex !== -1) {
      // If the country exists in the selectedNetworks array, remove it
      const updatedselectedNetworks = [...selectedNetworks];
      updatedselectedNetworks.splice(networkindex, 1);
      setSelectedNetworks(updatedselectedNetworks);
    } else {
      // If the country doesn't exist, add it to the selectedNetworks array
      setSelectedNetworks([...selectedNetworks, network]);
    }
  };

  const handleCreateAdvert = () => {
      let networkIds: number[] = [];
      selectedNetworks.map((each) => {
        networkIds.push(each.id);
      });
  
      let countryIds: number[] = [];
      selectedCountries.map((each) => {
        countryIds.push(each.id);
      });
  
      let data = { 
        campaign_id: Number(campaignId) as number,
        name: advertName,
        disapprove_reason: "",
        is_auto_bid: autoBudget,
        network_id: networkIds,
        country_id: countryIds,
        budget_daily_cap: minimumBudget,
        advert_bid: myBid,
      };
      createAdvert(data)
        .unwrap()
        .then((res) => {
          if (res.success) {
            file.map((eachFile) => {
              let bodyFormData = new FormData();
              bodyFormData.append("AdvertId", res?.data?.id.toString() as string);
              bodyFormData.append("PictureUrl", "");
              bodyFormData.append("PictureType", "");
              bodyFormData.append("DestinationUrl", "");
              bodyFormData.append("file", eachFile as File);
              bodyFormData.append("ContentType", eachFile.type.startsWith('video/') ? "2": "1");
              uploadAdverts(bodyFormData)
                .unwrap()
                .then((adImageRes) => {
                  console.log(adImageRes);
                })
                .catch((err) => {
                  console.log(err, "i am err creating ad images");
                });
            });
          }
          navigate(`/home/campaign/main-campaign/${campaignId}/advert-campaign`);
        })
        .catch((err) => {
          console.log(err, "i am the err");
        });
    
  };

  const renderCreateStep = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <MarginTop marginTop="2rem">
              <EachBox>
                <TextTitle3>Advert Details</TextTitle3>
              </EachBox>
              <MarginTop marginTop="2rem">
                <Label14 htmlFor="name">Advert Title</Label14>
                <Input16
                  width="60%"
                  type="text"
                  placeholder="Advert Name"
                  id="name"
                  value={advertName}
                  onChange={(e) => setAdvertName(e.target.value)}
                />
              </MarginTop>
            </MarginTop>
            <div>
              <MarginTop>
                <EachBox>
                  <TextTitle3>Add your preferred network</TextTitle3>
                  <ButtonReset onClick={() => setIsSelectNetwork(true)}>
                    <img
                      src={AddCampaign}
                      alt="Add a Network"
                      width={15}
                      height={15}
                    />
                    <Text14 marginBottom="0" color="#6B6B6B">
                      Add a Network
                    </Text14>
                  </ButtonReset>
                </EachBox>
                {selectedNetworks.length > 0 ? (
                  <FlexBox justifyContent="start">
                    {selectedNetworks.map((network) => {
                      return (
                        <CampButton>
                          <img
                            src={NetworkIcon}
                            alt="Network Icon"
                            width={10}
                            height={10}
                          />
                          <p>{network.name}</p>
                          <img
                            onClick={() => handleNetworkSelect(network)}
                            src={CloseIcon}
                            alt="Close Icon"
                            width={10}
                            height={10}
                          />
                        </CampButton>
                      );
                    })}
                  </FlexBox>
                ) : (
                  <FlexBox justifyContent="center">
                    <img
                      src={BgAdvert}
                      alt=""
                      className="bg-ad-img"
                      width={120.072}
                      height={75}
                      style={{ marginTop: "0.8rem" }}
                    />
                  </FlexBox>
                )}
              </MarginTop>
              <MarginTop>
                <EachBox>
                  <TextTitle3>Country</TextTitle3>
                  <ButtonReset onClick={() => setIsSelectCountry(true)}>
                    <img
                      src={AddCampaign}
                      alt="Add a Country"
                      width={15}
                      height={15}
                    />
                    <Text14 marginBottom="0" color="#6B6B6B">
                      Add a Country
                    </Text14>
                  </ButtonReset>
                </EachBox>
                {selectedCountries.length > 0 ? (
                  <FlexBox justifyContent="start">
                    {selectedCountries.map((country) => {
                      return (
                        <CampButton>
                          <p>{country?.name}</p>
                          <img
                            src={CloseIcon}
                            onClick={() => handleCountrySelect(country)}
                            alt="Close Icon"
                            width={10}
                            height={10}
                          />
                        </CampButton>
                      );
                    })}
                  </FlexBox>
                ) : (
                  <FlexBox justifyContent="center">
                    <img
                      src={BgAdvert}
                      alt=""
                      className="bg-ad-img"
                      width={120.072}
                      height={75}
                      style={{ marginTop: "0.8rem" }}
                    />
                  </FlexBox>
                )}
              </MarginTop>
            </div>
            <ErrorMessage errMsg={errMsg} />
          </div>
        );
      case 2:
        return (
          <div style={{ marginTop: "4rem" }}>
            <CreateContent
              handleImage={handleImage}
              handleAdvertUpload={handleAdvertUpload}
              advertImages={advertImages}
              handleDrop={handleDrop}
              errMsg={errMsg}
            />
            <div>
              <ImageGallery
                showRole={showRole2}
                handleCloseRole={handleCloseRole}
                modalRef={modalRef}
                imagesAdvert={imagesAdvert}
                selectedImage={imagesAdvert[selectedImageIndex]}
                handlePreviousImage={handlePreviousImage}
                handleNextImage={handleNextImage}
              />
            </div>
          </div>
        );
      case 3:
        return (
          <div style={{ marginTop: "4rem" }}>
            <CreateAdvertBudget
              autoBudget={autoBudget}
              setisAutoBudget={setisAutoBudget}
              setMinimumBudget={setMinimumBudget}
              minimumBid={minimumBudget}
              setBid={setBid}
              myBid={myBid}
            />
            
            <ErrorMessage errMsg={errMsg} />
          </div>
        );
    }
  };

  const handlePreview = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  const handleNext = () => {
    if (step === 1) {
      if (!advertName) {
        setError("Please enter an advert title");
      } else if (selectedNetworks.length === 0) {
        setError("Please select networks to run your advert");
      } else if (selectedCountries.length === 0) {
        setError("Please select countries to run your advert");
      } else {
        setStep(step + 1);
        setError("");
      }
    } else if (step === 2) {
      if (advertImages.length === 0) {
        setError("Please upload one or more advert images");
      } else {
        setStep(step + 1);
        setError("");
      }
    } else {
      if (step < 3) {
        setStep(step + 1);
      }
    }
  };

  const handleShowAdvertSummary = () =>{
    
    if( Number(myBid || 0) < Number(campaignDetail?.data?.default_advert_bid?.minimal_bid || 0)){
      setError("Your Bid cannot be less than the minimum bid for this advert")
    }else{
      setIsAdvertSummary(true)
    }
  }

  return (
    <Section>
      <div className="top">
        <div>
          <TextTitle1>
            <span className="pointer" onClick={()=> navigate("/home/campaign")}> Campaigns  </span>- 
            <span className="pointer" onClick={()=> navigate(`/home/campaign/main-campaign/${campaignDetail?.data?.id}/overview`)} >{campaignDetail?.data?.name || ""}</span> -{" "}
            <TextTitle1Span >Create Advert</TextTitle1Span>{" "}
          </TextTitle1>
        </div>
        <FlexBox>
          <FlexBox gap="1.5rem">
            <FlexBox justifyContent="center" gap="0.8rem">
              <CircleSpan background={step >= 1 ? "#e5b910" : "#c4c4c4"}>
                1
              </CircleSpan>
              <Text12 fontWeight="600">Properties</Text12>
            </FlexBox>
            <ArrowRight />
            <FlexBox justifyContent="center" gap="0.8rem">
              <CircleSpan background={step >= 2 ? "#e5b910" : "#c4c4c4"}>
                2
              </CircleSpan>
              <Text12 fontWeight="600">Content</Text12>
            </FlexBox>
            <ArrowRight />
            <FlexBox justifyContent="center" gap="0.8rem">
              <CircleSpan background={step >= 3 ? "#e5b910" : "#c4c4c4"}>
                3
              </CircleSpan>
              <Text12 fontWeight="600">Budget</Text12>
            </FlexBox>
          </FlexBox>
          <BtnGroup>
            {step > 1 && <Button text="Previous" variant="outline" onClick={() => handlePreview()} />}
            {step !== 3 && <Button text="Next" widthProps="7rem" onClick={() => handleNext()} />}
            {step >= 3 && <Button
              text="Start Advert"
              widthProps="15rem"
              onClick={()=> handleShowAdvertSummary()}
            />}
          </BtnGroup>
        </FlexBox>
      </div>
      {renderCreateStep()}
      {isSelectNetwork && (
        <NetworkModal
          show={isSelectNetwork}
          modalRef={modalRef}
          handleCloseModal={handleCloseModal}
          selectedValues={selectedNetworks}
          handleSelect={handleNetworkSelect}
        />
      )}
      {isSelectCountry && (
        <CountryModal
          show={isSelectCountry}
          modalRef={modalRef}
          selectedValues={selectedCountries}
          handleSelect={handleCountrySelect}
          handleCloseModal={handleCloseModal}
        />
      )}
      {isAdvertSummary && (
        <AdvertSummaryModal
          showRole={isAdvertSummary}
          modalRef={modalRef}
          name={advertName}
          networks={selectedNetworks.length}
          countries={selectedCountries.length}
          images={advertImages.length}
          handleCreateAdvert={handleCreateAdvert}
          isLoading={creatingAdvert || uploadingAdImages}
          minimumBudget={minimumBudget}
          myBid={myBid}
        />
      )}
    </Section>
  );
};

export default CreateCampaign13;
