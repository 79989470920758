import React, { useEffect, useState } from "react";
import { BackArrow, ErrorMsg } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import { FundDescription, Instructions, MarginBottom, WalletDescription, WalletInnerBox } from "./Wallet.styled";
import ArrowRight from "../../assets/icons/arrow-right.svg";
import { ModalMainProps } from "../../types";
// import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useInitiateCardPaymentMutation, useGetFundingDetailsQuery } from "../../features/api/walletSlice";
// import { InitiateCardPaymentResponse } from "../../features/api/walletSliceTypes";
import { copyToClipboard, FundOptions, NetworkChainId } from "../Campaign/helper";
// import { UserDetail } from "../../features/api/authTypes";
import { ReactComponent as CopyIcon } from "../../assets/icons/copyIcon.svg";
import toast from "react-hot-toast";

const WithdrawModal: React.FC<ModalMainProps> = ({
  show,
  handleCloseModal,
  modalRef,
}) => {

  // const [amount, setAmount] = useState<string>('');
  // const [errMsg, setErr] = useState<string>('');
  const [step, setStep] = useState<number>(0);
  // const [userDetail, setUser] = useState<UserDetail>();

  //const [initiatePayment, {isLoading: initiatingPayment}] = useInitiateCardPaymentMutation()
  const { data: fundingDetail } = useGetFundingDetailsQuery()
  //const [transactionDetail, setDetails] = useState<InitiateCardPaymentResponse | null>();


  // const updateUserFromLocalStorage = () => {
  //   const user = JSON.parse(localStorage.getItem("user") || "{}");
  //   setUser(user);
  // };

  // useEffect(() => {
  //   updateUserFromLocalStorage();
  // }, [])

  // const handleCardFunding = () => {
  //   const data = {
  //     user_id: "0",
  //     amount: amount,
  //     currency_id: "1"
  //   };
  //   if(!amount){
  //     setErr("Please enter amount you wish to fund")
  //   }else{
  //     setErr("")
  //     initiatePayment(data)
  //     .unwrap()
  //     .then((res) => {
  //       if (res.success) {
  //         setDetails(res);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err, 'I am the error');
  //     });
  //   }

  // };

  // const config = {
  //   public_key: transactionDetail?.data?.public_key as string,
  //   tx_ref: transactionDetail?.data?.transaction_ref as string,
  //   amount: transactionDetail?.data?.amount as number,
  //   currency: "NGN",
  //   payment_options: transactionDetail?.data?.payment_options as string,
  //   customer: {
  //     email: userDetail?.email as string,
  //     phone_number: userDetail?.phone_number as string,
  //     name: userDetail?.first_name as string,
  //   },
  //   customizations: {
  //     title: "Wicrypt Advert Payment",
  //     description: "Payment for items in cart",
  //     logo: "https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg",
  //   },
  // };

  // const handleFlutterPayment = useFlutterwave(config);

  // useEffect(() => {
  //   if (transactionDetail && transactionDetail.success) {
  //     handleFlutterPayment({
  //       callback: (response) => {
  //         closePaymentModal()
  //         handleCloseModal()
  //         setDetails(null);
  //       },
  //       onClose: () => {
  //         console.log("Payment modal closed");
  //       },
  //     });
  //   }
  // }, [handleFlutterPayment, transactionDetail]);

  useEffect(() => {
    setStep(0)
  }, [])

  const copyText = (text: string) => {
    toast.success("Copied!");
    copyToClipboard(text)
  }

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div>
            {/* <WalletInnerBox>
                <button onClick={handleCardFunding}>
                  <p>Pay Via Card</p>
                  <img src={CheckIcon} alt="Check mark" />
                </button>
              </WalletInnerBox> */}
            <WalletInnerBox>
              <button onClick={() => setStep(1)}>
                <p>Fund Via Bank Transfer</p>
                <img src={ArrowRight} alt="Check mark" />
              </button>
            </WalletInnerBox>
            <WalletInnerBox>
              <button onClick={() => setStep(2)}>
                <p>Fund Via Crypto </p>
                <img src={ArrowRight} alt="Check mark" />
              </button>
            </WalletInnerBox>
          </div>
        )
      case 1:
        return (
          <div>
            <WalletDescription>
              <div>
                <p className="title">Bank Name</p>
                <p className="descr">{fundingDetail?.data?.bank_name}</p>
              </div>
              <div>
                <CopyIcon className="pointer" onClick={() => copyText(fundingDetail?.data?.bank_name || "")} />
              </div>

            </WalletDescription>
            <WalletDescription>
              <div>
                <p className="title">Account Number</p>
                <p className="descr">{fundingDetail?.data?.account_number}</p>
              </div>
              <div>
                <CopyIcon className="pointer" onClick={() => copyText(fundingDetail?.data?.account_number || "")} />
              </div>

            </WalletDescription>
            <WalletDescription>
              <div>
                <p className="title">Account Name</p>
                <p className="descr">{fundingDetail?.data?.account_name}</p>
              </div>
              <div>
                <CopyIcon className="pointer" onClick={() => copyText(fundingDetail?.data?.account_name || "")} />
              </div>

            </WalletDescription>
            <Instructions>
              After payment, please send proof to the email below.
              <p> Hello@wicrypt.com <CopyIcon onClick={() => copyText("hello@wicrypt.com")} /></p>
            </Instructions>
          </div>
        )
      case 2:
        return (
          <div>
            <WalletDescription>
              <div>
                <p className="title">Network</p>
                <p className="descr">{NetworkChainId[fundingDetail?.data?.chain_id || 1]}</p>
              </div>

            </WalletDescription>
            <WalletDescription>
              <div>
                <p className="title">Chain ID</p>
                <p className="descr">{fundingDetail?.data?.chain_id}</p>
              </div>

            </WalletDescription>
            <WalletDescription>
              <div>
                <p className="title">Wallet Address</p>
                <p className="descr">{fundingDetail?.data?.wallet_address.slice(0, 8) + "..." + fundingDetail?.data?.wallet_address.slice(20)}</p>
              </div>
              <div>
                <CopyIcon className="pointer" onClick={() => copyText(fundingDetail?.data?.wallet_address || "")} />
              </div>

            </WalletDescription>
           <WalletDescription>
              <div>
                <p className="title">Currency</p>
                <p className="descr">USDT</p>
              </div>


            </WalletDescription>
            <Instructions>
              After payment, please send proof to the email below.
              <p> Hello@wicrypt.com <CopyIcon onClick={() => copyText("hello@wicrypt.com")} /></p>
            </Instructions>
          </div>
        )
    }
  }


  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={() => step === 0 ? handleCloseModal() : setStep(0)} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>{FundOptions[step].title}</ModalTitle>
          </div>
        </BackArrow>
        {/* <FormBox>
            <Label textAlign="left">Amount</Label>
            <p className="absolute">  ₦</p>
            <Input type="text"  className="pl-20" onChange={(e)=> setAmount(e.target.value)} value={amount}  />
          </FormBox> */}
        <FundDescription>{FundOptions[step].description}</FundDescription>
        <div>

          {renderStep()}
          {/* <WalletInnerBox>
            <Link to={""}>
              <button>
                <p>Pay via Xendbridge </p>
              </button>
            </Link>
          </WalletInnerBox> */}
          {/* <WalletInnerBox>
            <Link to={""}>
              <button>
                <p>Pay via Paypal</p>
              </button>
            </Link>
          </WalletInnerBox> */}
        </div>
        {/* <ErrorMsg> {errMsg}</ErrorMsg>
        {initiatingPayment &&
        <MarginBottom>
          <Button text="Proceed" isLoading={initiatingPayment} />
        </MarginBottom>} */}
      </ModalContainer>
    </MainContainer>
  );
};

export default WithdrawModal;
