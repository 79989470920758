import React, { useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { FlexBox, ModalBox } from "./Campaign.styled";
import { Button } from "../Reusable/Buttons/button";
import { ConfirmModal } from "../Reusable/Modal";

interface CustomDatePickerProps {
  onDateSelect: (startDate: Date | null, endDate: Date | null) => void;
  handleCloseModal: () => void;
}

const CustomDatePicker = ({
  onDateSelect,
  handleCloseModal,
}: CustomDatePickerProps) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleProceed = () => {
    // Check if both start and end dates are selected
    if (startDate && endDate) {
      // Call the onDateSelect prop with the selected dates
      onDateSelect(startDate, endDate);
    }
  };

  return (
    <ConfirmModal handleCloseModal={handleCloseModal}>
      <FlexBox flexDirection="column">
        <DatePicker
          selected={startDate}
          onChange={onChange}
          minDate={new Date()}
          // maxDate={addMonths(new Date(), 5)}
          startDate={startDate}
          endDate={endDate}
          selectsRange
          inline
          showDisabledMonthNavigation
        >
          {startDate && endDate && (
            <Button
              onClick={handleProceed}
              text="Proceed"
              widthProps="7rem"
              height="25px"
            />
          )}
        </DatePicker>
      </FlexBox>
    </ConfirmModal>
  );
};

export default CustomDatePicker;
