import { TextProps } from "recharts";
import styled from "styled-components";
import { COLOR_BLUE } from "../../styles/themes/colors";

interface ListProps {
  gap?: string;
  width?: string;
  widthMd?: string;
  fontWeight?: string;
  margin?: string;
  backgroundColor?: string;
}

export const VerifySection = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding-bottom: 2rem;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const UnorderedList = styled.ul`
  display: inline-flex;
  padding: 0px 12px;
  gap: 80px;
  flex-wrap: wrap;
  align-items: center;
  width: 70%;
  margin: 0.75rem auto;
  @media (min-width: 765px) {
    justify-content: center;
    width: 100%;
    margin: 1.25rem auto;
    flex-wrap: nowrap;
    column-gap: 1rem;
  }
`;

export const List = styled.li<ListProps>`
  display: flex;
  gap: ${(props) => props.gap || "0.625rem"};
  width: 100%;
  @media (min-width: 765px) {
    width: ${(props) => props.width || "100%"};
  }
`;

export const ImgBox = styled.img`
  align-self: flex-start;
  margin-top: -0.125rem /* -2px */;
`;

export const BoxWidth = styled.div`
  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const BlueInfoBox2 = styled.div`
  border-radius: 0.25rem;
  background: rgba(37, 52, 106, 0.1);
  padding: 1rem 1.25rem;
`;

export const FontWeight = styled.span<ListProps>`
  font-weight: ${(props) => props.fontWeight || "500"};
`;

export const BoxWidthMargin = styled.div<ListProps>`
  width: ${(props) => props.width || "80%"};
  margin: 0 auto;
  text-align: center;
  @media (min-width: 768px) {
    width: ${(props) => props.widthMd || "80%"};
  }
`;

export const BoxBorder = styled.button`
  background-color: transparent;
  border: none;
  border-bottom: 0.5px solid #eae9ed;
  padding: 12px 0px 12px 20px;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: color 300ms ease-in;

  &:hover {
    color: #${COLOR_BLUE};
  }
`;
// export const ModalBox = styled.section<ListProps>`
//   display: flex;
//   justify-content: center;
//   position: fixed;
//   inset: 0;
//   z-index: 20;
//   height: 100vh;
//   overflow: hidden;
//   background-color: #f4f4f4;
//   @media (min-width: 768px) {
//     background-color: ${(props) => props.backgroundColor || "#00000033"};
//     align-items: center;
//   }
// `;

export const ModalBox = styled.section<ListProps>`
  display: flex;
  justify-content: center;
  position: fixed;
  inset: 0;
  z-index: 20;
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  backdrop-filter: blur(8px);
  @media (min-width: 768px) {
    background-color: ${(props) => props.backgroundColor || "#00000033"};
    align-items: center;
  }
`;
// export const ModalInnerBox = styled.div`
//   background-color: rgb(244 244 244);
//   width: 92%;
//   z-index: 30;
//   padding: 1.5rem 0;
//   border-radius: 0.75rem /* 12px */;
//   @media (min-width: 768px) {
//     background-color: #00000033;
//     align-items: center;
//     padding: 1.5rem 1rem;
//     width: 450px;
//   }
// `;

export const ModalInnerBox = styled.div`
  background-color: transparent;
  width: 92%;
  z-index: 30;
  padding: 1.5rem 0;
  border-radius: 1.25rem /* 20px */;
  @media (min-width: 768px) {
    align-items: center;
    padding: 1.5rem 1.5rem;
    width: 550px;
  }
`;

export const TopBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 80%;
  height: 80%;
  padding: 3rem 0.75rem 3rem;
  margin: 2rem auto;
  background-color: #ffffff;
  border-radius: 0.75rem;
`;

export const TextBox = styled.div`
  width: 73%;
  margin: 0 auto;
  text-align: center;
`;

export const Wrapper1 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 1.5rem;
  background-color: #000;
  border-radius: 0.375rem;
  /* margin-bottom: 1.75rem; */
  /* height: 18rem; */
  padding: 2.5rem 0 1.3rem;
`;

export const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15rem;
  height: 12rem;
  border-radius: 1.25rem;
  padding: 0.5rem;

  position: relative;
  border: 3px solid #e5b910;
  background: #000;
  transition: all 0.05s linear;

  &:after,
  &:before {
    content: "";
    position: absolute;
    background: #000;
    transition: all 0.2s linear;
  }

  &:before {
    width: calc(100% + 9px);
    height: calc(100% - 64px);
    top: 32px;
    left: -4px;
  }

  &:after {
    height: calc(100% + 9px);
    width: calc(100% - 64px);
    top: -3px;
    left: 32px;
  }
`;

export const WrapperInner2 = styled.div`
  position: relative;
  width: 12.893rem;
  height: 10rem;
  margin: 0 auto;
  z-index: 4;
`;

export const CaptureImageBtn = styled.div`
  position: relative;
  z-index: 50;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  width: 2.75rem;
  height: 2.75rem;
`;

export const SmallCircle = styled.div`
  width: 2rem;
  height: 2rem;
  background-color: #fff;
  border-radius: 50%;
`;

export const CaptureImageBox = styled.div`
  position: relative;
  margin: 0 auto;
  @media (min-width: 768px) {
    width: 80%;
  }
`;

export const CaptureImageInput = styled.input`
  position: absolute;
  overflow: hidden;
  opacity: 0;
  z-index: -1;
`;

export const CaptureLabel = styled.label`
  width: 70%;
  padding: 0.5rem 1rem; /* Use 2.5rem for md breakpoint */
  display: flex;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  opacity: 1;
  border: none;
  background-color: #25346a;
  color: #ffffff;
  border-radius: 40px;
  margin: 0 auto;
  transition: all 0.3s;
  outline: none;
  white-space: nowrap;
  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background-color: #25346a30;
    transform: scale(1.05);
  }

  &:focus {
    outline: none;
  }
`;

export const CaptureImageBox2 = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  margin: 0.5rem auto 0;
  padding: 0.375rem;
  background-color: #ffffff;
  border-radius: 12px;
  @media (min-width: 768px) {
    width: 60%;
  }
`;

export const BtnGroup2 = styled.div`
  margin: 0 auto;
  & > * {
    margin-top: 1rem;
  }

  @media (min-width: 768px) {
    width: 70%;
  }
`;

export const MarginBox = styled.div<ListProps>`
  /* margin: 0.5rem 0; */
  margin: ${(props) => props.margin || "0.5rem 0"};
`;
