import moment from "moment"


export function getDateRange(interval: string) {
    const currentDate = new Date();
  
    if (interval === '1week') {
      const endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() + 7); // Add 7 days for 1 week
      return {
        startDate: currentDate,
        endDate: endDate,
      };
    }
  
    if (interval === '1month') {
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() + 1); // Add 1 month
      return {
        startDate: currentDate,
        endDate: endDate,
      };
    }
  
    if (interval === '3months') {
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() + 3); // Add 3 months
      return {
        startDate: currentDate,
        endDate: endDate,
      };
    }
  
    if (interval === '6months') {
      const endDate = new Date(currentDate);
      endDate.setMonth(currentDate.getMonth() + 6); // Add 6 months
      return {
        startDate: currentDate,
        endDate: endDate,
      };
    }
  
    if (interval === '1year') {
      const endDate = new Date(currentDate);
      endDate.setFullYear(currentDate.getFullYear() + 1); // Add 1 year
      return {
        startDate: currentDate,
        endDate: endDate,
      };
    }
  
    // Default to returning the current date if the interval is not recognized
    return {
      startDate: currentDate,
      endDate: currentDate,
    };
}
  
export const durationOptions = [
    {key: 1, value: "1 week", interval: "1week"},
    {key: 2, value: "1 Month", interval: "1month"},
    {key: 3, value: "3 Months", interval: "3months"},
    {key: 4, value: "6 Months", interval: "6months"},
    {key: 5, value: "1 Year", interval: "1year"},
    {key: 6, value: "Custom", interval: "custom"}
]

export const getTimeLeft = (targetDate: Date) => {
  // Parse the target date using moment
  const targetDateTime = moment(targetDate, 'YYYY-MM-DD HH:mm:ss');

  // Get the current date and time using moment
  const currentDateTime = moment();

  // Calculate the duration between the target date and current date
  const duration = moment.duration(targetDateTime.diff(currentDateTime));

  // Get the days, hours, minutes, and seconds
  const months = duration.months();
  const days = duration.days();
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();

  let timeLeftValue = 0;
  let timeLeftUnit = '';

  if(months > 0){
    timeLeftValue = months;
    timeLeftUnit = ' months left';
  }else if (days > 0) {
    timeLeftValue = days;
    timeLeftUnit = ' days left';
  } else if (hours > 0) {
    timeLeftValue = hours;
    timeLeftUnit = ' hours left';
  } else if (minutes > 0) {
    timeLeftValue = minutes;
    timeLeftUnit = ' minutes left';
  } else if (seconds > 0) {
    timeLeftValue = seconds;
    timeLeftUnit = ' secs left';
  } else {
    timeLeftValue = 0;
    timeLeftUnit = ' secs left';
  }

  return { value: timeLeftValue, unit: timeLeftUnit };
}


export function progressDuration(startDate: Date, endDate: Date) {
  // Parse the startDate and endDate strings into Date objects
  const startDateTime = new Date(startDate).getTime(); // Convert to timestamp
  const endDateTime = new Date(endDate).getTime(); // Convert to timestamp

  // Get the current date and time
  const currentDateTime = new Date().getTime(); // Convert to timestamp

  // Calculate the difference in seconds between endDate and startDate
  const differenceInSeconds = Math.floor((endDateTime - startDateTime) / 1000);

  // Calculate progress as a percentage
  let timeLeftPercentage;

  if (differenceInSeconds <= 0) {
    // If difference is non-positive, return 100% (time has passed)
    timeLeftPercentage = 100;
  } else {
    // Calculate the elapsed seconds from the startDate to the current date
    const elapsedSeconds = Math.floor((currentDateTime - startDateTime) / 1000);

    // Calculate the remaining seconds
    const remainingSeconds = differenceInSeconds - elapsedSeconds;

    // Calculate progress as a percentage
    timeLeftPercentage = (remainingSeconds / differenceInSeconds) * 100;
  }

  // Ensure the percentage is within the 0-100 range
  timeLeftPercentage = Math.min(Math.max(timeLeftPercentage, 0), 100);

  return 100 - timeLeftPercentage;
}


export const appStatus = [
  {id: "", status: "All"},
  {id: "1", status: "Pending"},
  {id: "2", status: "Canceled"},
  {id: "3", status: "Failed"},
  {id: "4", status: "Successful"},
  {id: "5", status: "Approved"},
  {id: "6", status: "Disapproved"},
  {id: "7", status: "Deleted"},
  {id: "8", status: "Active"},
]

export const advertStatus = [
  {id: "", status: "All"},
  {id: "1", status: "Pending"},
  {id: "3", status: "Failed"},
  {id: "6", status: "Disapproved"},
  {id: "8", status: "Active"},
]

export const viewPeriod = [
  {id: "0", period: "All"},
  {id: "1", period: "Daily"},
  {id: "2", period: "Weekly"},
  {id: "3", period: "Monthly"},
]

export function numberWithCommasWithoutDecimal(number: number) {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

type StatusCodes = {
  [key: string]: string;
};

export const StatusCodes : StatusCodes =  {
  "1" : "Pending",
  "2": "Canceled",
  "3": "Failed",
  "4": "Successful",
  "5": "Approved",
  "6": "Disapproved",
  "7": "Deleted",
  "8": "Active",
  "9": "PendingVerification",
  "10": "InActive"
}

type FundOptions = {
  [key: number]: {title: string, description: string};
};

export const FundOptions: FundOptions  = {
  0: {title: "Fund Wallet", description: "Please choose a preferred payment method"},
  1: {title: "Fund via Bank Transfer", description: "Fund your account using the details below"},
  2: {title: "Fund via Crypto", description: "Fund your account using the details below"},
}

export const NetworkChainId: StatusCodes  = {
  1: "Ethereum",
  137: "Polygon",
  56: "Binance Smart Chain"
}

/**
 * Copies the specified text to the clipboard.
 * @param text - The text to be copied.
 */
 export const copyToClipboard = (text: string): void => {
  const textField = document.createElement("textarea");
  textField.innerText = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
};