import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box5, Form2, Label2 } from "../Wallet/Wallet.styled";
import Filter from "../../assets/icons/Wallet/filter.svg";
import { ReactComponent as AddIcon } from "../../assets/icons/campaigns-add.svg";
import { Button } from "../Reusable/Buttons/button";
import { columnsCampaignAd } from "../Tables/TableData";
import TableMain from "../Tables/TableMain";
import { FlexBox, SelectWrapperMain } from "./Campaign.styled";
import useOutsideClick from "../../hooks/useOutsideClick";
import { useGetCampaignAdvertQuery } from "../../features/api/campaignSlice";
import { useParams } from "react-router-dom";
import { CardLoader } from "../Reusable/CardLoader";
import AdEmpty from "./AdEmpty";
import { FilterAdverts } from "../Dashboard/FilterGroupCamp";
import { appStatus } from "./helper";
import EmptyBox from "../Reusable/EmptyBox";

const AdvertCampaign = () => {
  const navigate = useNavigate();
  const [isFilterGroup, setIsFilterGroup] = useState<boolean>(false);
  const [status, setStatus] = useState<string>("");
  const [networkId, setNetworkId] = useState<string>("");


  const { campaignId } = useParams();

  const { data: campaignAdverts, isLoading: gettingAdvert, isFetching } = useGetCampaignAdvertQuery({campaignId: Number(campaignId) as number, status: status, networkId: networkId})

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsFilterGroup(false);
  };

  useOutsideClick(modalRef, handleCloseModal);
  const greenColor = "#1A1A1A";
  return (
    <div style={{ marginTop: "2rem" }}>
      <FlexBox>
        <Form2>
          <Box5 width="100%">
            <Label2 htmlFor="filter">Filter</Label2>
            <SelectWrapperMain
              onClick={(e) => {
                e.preventDefault();
                setIsFilterGroup(true);
              }}
            >
            <p>{appStatus.find((filterstatus) => filterstatus.id === status)?.status}</p>
            <img src={Filter} alt="" className="select-img-filter" />
            </SelectWrapperMain>
          </Box5>
        </Form2>
        <Button
          text="Create Advert"
          fontSizeProps="0.875rem"
          widthProps="10rem"
          onClick={() => navigate(`/home/campaign/${campaignId}/create-advert`)}
          icon={<AddIcon />}
        />
      </FlexBox>
      <div style={{ textAlign: "center" }}>
        {gettingAdvert || isFetching ?
          <CardLoader />
          :
          campaignAdverts?.data.length || 0 > 0 ? 
          <TableMain
            defaultData={campaignAdverts?.data || []}
            columns={columnsCampaignAd}
            greenColor={greenColor}
          />: 
          status === "" ?
          <AdEmpty /> :
          <EmptyBox message={`No ${appStatus.find((filterstatus) => filterstatus.id === status)?.status} adverts`} />

        }
        
      </div>
      
      {/* <Walletbtn>
        <button>Load more</button>
      </Walletbtn> */}
        {isFilterGroup && (
          <FilterAdverts
            handleCloseRole={handleCloseModal}
            showRole={isFilterGroup}
            applyFilter={setStatus}
          />
        )}
      
    </div>
  );
};

export default AdvertCampaign;
