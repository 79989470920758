import React, { useState, useRef, useEffect } from "react";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import { AccountTitle, HeadingBox, ContentBox2 } from "./Account.styled";
import DeleteIcon from "../../assets/icons/delete.svg";
import EditProfile from "./EditProfile";
import { useNavigate } from "react-router-dom";
import useOutsideClick from "../../hooks/useOutsideClick";
import AuthorizedAccess from "./AuthorizedAccess";
import { TabComponent } from "../../types";
import { UserDetail } from "../../features/api/authTypes";
import moment from "moment";
import { BtnGroup } from "../Campaign/Campaign.styled";
import { useDeleteUserMutation } from "../../features/api/accountSlice";
import { Avatar } from "../Dashboard/Dashboard.styled";
import { CircleSpan } from "../Reusable/Reuseable.styled";
import { useGetUserQuery } from "../../features/api/authSlice";

const MyDetails: React.FC<TabComponent> = ({ show }) => {
  const [showRole, setShowRole] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [userDetail, setUser] = useState<UserDetail>();

  // error
  const [errMsg, setErr] = useState<string>("");

  // password
  const [password, setPassword] = useState("");

  const [deleteUser, { isLoading: deletingUser }] = useDeleteUserMutation();
  const {data: userInfo} = useGetUserQuery();

  const modalRef = useRef<HTMLElement>(null);
  const modalRef2 = useRef<HTMLElement>(null);

  const handleOpenRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    setShowRole(false);
  };

  const handleClickDelete = () => {
    setShowAccess(true);
  };

  const handleCloseAccess = () => {
    setShowAccess(false);
  };

  const navigate = useNavigate();

  const handleDeleteAccount = () => {
    setErr("");
    deleteUser(password as string)
      .unwrap()
      .then((res) => {
        if (res?.success) {
          navigate("/");
        } else {
          setErr(res?.Message);
        }
      })
      .catch((err) => {
        setErr(err?.data?.Message);
      });
  };

  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef2, handleCloseAccess);

  const updateUserFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  };

  useEffect(() => {
    if(userInfo){
      setUser(userInfo.data)
    }else{
      updateUserFromLocalStorage();
    }
   
  }, [userInfo]);

  console.log(userInfo, 'userInfo')

  if (!show) return <></>;
  return (
    <div>
      <HeadingBox>
        <AccountTitle>Personal Details</AccountTitle>
        <span className="line"></span>
        <div className="group-btn" style={{ display: "flex", gap: "1rem" }}>
          <Button
            text="Edit profile"
            onClick={handleOpenRole}
            paddingProps="0.66rem 1.5rem"
            widthProps="auto"
          />
          <ButtonReset onClick={handleClickDelete}>
            <img src={DeleteIcon} alt="Delete Icon" />
          </ButtonReset>
        </div>
      </HeadingBox>
      <ContentBox2>
        {/* <img src={userDetail?.photo || ""} alt="Company's Logo" className="companyLogo" /> */}
        <CircleSpan width="120px" height="120px" background="#f3eeee">
          {userDetail?.photo ? (
            <img
              src={userDetail?.photo || ""}
              alt="Company's Logo"
              className="image-round"
            />
          ) : (
            <Avatar className="companyLogo">
              <p>
                {userDetail?.first_name?.slice(0, 2).toUpperCase() || ""}
              </p>
            </Avatar>
          )}
        
        </CircleSpan>
        <div className="right-content">
          <h4>
            {userDetail?.first_name || ""} {userDetail?.last_name || ""}
          </h4>
          <p>{userDetail?.email || ""}</p>
          <BtnGroup>
            {userDetail?.businesses[0].status === "8" && (
              <Button
                type="button"
                text="Verified User"
                BGProps="#00C087"
                paddingProps="0.125rem"
                //widthProps="7rem"
                fontSizeProps="500"
                disabled
              />
             )}
            {userDetail?.businesses[0].status === "9" && (
              <Button
                type="button"
                text="Pending Verification"
                BGProps="#e5b909"
                paddingProps="0.125rem"
                //widthProps="7rem"
                fontSizeProps="500"
                disabled
              />
             )}
            {userDetail?.businesses[0].status === "1" && (
              <Button
                type="button"
                text="Verify User"
                BGProps="#FF1E1E"
                paddingProps="0.125rem"
                //widthProps="7rem"
                fontSizeProps="500"
                onClick={() => navigate("/verification")}
              />
            )}
          </BtnGroup>
        </div>
        <p className="startDate">
          User since:{" "}
          {moment(userDetail?.created_at || new Date()).format("MMM YYYY")}
        </p>
      </ContentBox2>
      <EditProfile
        show={showRole}
        handleCloseModal={handleCloseRole}
        updateUserFromLocalStorage={updateUserFromLocalStorage}
        modalRef={modalRef}
      />
      {showAccess && (
        <AuthorizedAccess
          handleCloseRole={handleCloseAccess}
          handleDeleteAccount={handleDeleteAccount}
          errMsg={errMsg}
          isLoading={deletingUser}
          password={password}
          setPassword={setPassword}
        />
      )}
    </div>
  );
};

export default MyDetails;
