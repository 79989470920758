import React, { useEffect, useRef, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import {
  Avatar,
  Box22,
  RoleBoxMain,
  TooltipContent,
} from "../Dashboard/Dashboard.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import {
  Box12,
  Box500,
  BtnGroup,
  BtnGroupGrid,
  BtnMore,
  CreateBox,
  DurationBox,
  EachBox,
  ErrorText,
  FlexBox,
  FlexBoxTable,
  Input16,
  Label14,
  List,
  ModalInnerBox,
  UnorderedList,
} from "./Campaign.styled";
import { BackArrow, Input } from "../Authentication/styles/Container.styled";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  ModalTitle,
  ModalContainer,
  MainContainer,
} from "../Dashboard/Dashboard.styled";
import InfoIcon from "../../assets/icons/info-circle.svg";
import CheckIcon from "../../assets/icons/campaigns-check-circle.svg";
import Search from "../../assets/icons/Wallet/Search.svg";
import { CountryBox, Form2, MarginBottom } from "../Wallet/Wallet.styled";
import { ReactComponent as MoreMenu } from "../../assets/icons/campaigns-icon-bold-info-menu.svg";
import useOutsideClick from "../../hooks/useOutsideClick";
import CustomDatePicker from "./CustomDatePicker";
import { formatDate } from "../../helpers";
import {
  Text12,
  Text14,
  TextTitle2,
  TextTitle3,
} from "../../styles/all.styled";
import { durationOptions, getDateRange } from "./helper";
import {
  useCreateCampaignMutation,
  useDeleteAdvertMutation,
  useGetCountriesQuery,
  useGetNetworksQuery,
  usePauseAdvertMutation,
} from "../../features/api/campaignSlice";
import { Country } from "../Wallet/types";
import {
  AdvertSummaryModalProps,
  CampaignModalProps,
  ModalMainProps,
  NetworkModalProps,
} from "../../types";
import { Network } from "../../features/api/campaignSliceTypes";
import { CardLoader } from "../Reusable/CardLoader";
import { DurationProps } from "./types";
import { ScaleLoader } from "react-spinners";
import toast from "react-hot-toast";
import { UserDetail } from "../../features/api/authTypes";
import EmptyBox from "../Reusable/EmptyBox";

interface DeleteCampaignModal {
  showModal: boolean;
  //modalRef?: MutableRefObject<HTMLElement>;
  handleDelete: () => void;
  handleCloseModal: () => void;
  deletingCampaign: boolean;
}

export const DeleteCampaignModal: React.FC<DeleteCampaignModal> = ({
  showModal,
  handleDelete,
  handleCloseModal,
  deletingCampaign,
}) => {
  return (
    <RoleBoxMain showRoleModal={showModal}>
      <ModalInnerBox width="90%" widthBox="28%">
        <div>
          <TextTitle2>Delete Campaign</TextTitle2>
          <Text14 color="#6b6b6b">
            Are you sure you want to delete this Campaign? This will delete
            ongoing ads too.
          </Text14>
        </div>

        <BtnGroup className="role-btn">
          <Button text="Cancel" variant="outline" onClick={handleCloseModal} />
          <Button
            isLoading={deletingCampaign}
            text="Delete"
            onClick={handleDelete}
          />
        </BtnGroup>
      </ModalInnerBox>
    </RoleBoxMain>
  );
};

export const Duration = ({
  handleOpenCampaign,
  handleDeleteCampaign,
}: DurationProps) => {
  return (
    <DurationBox>
      <UnorderedList gap="0">
        <List onClick={handleOpenCampaign}>
          <ButtonReset
            widthProps="100%"
            justifyContent="flex-start"
            paddingProps="0"
            BGProps="transparent"
          >
            View Info
          </ButtonReset>
        </List>
        <List onClick={handleDeleteCampaign} color="#FF1E1E">
          Delete Campaign
        </List>
      </UnorderedList>
    </DurationBox>
  );
};

type MoreInfoProps = {
  id: string;
  handleCloseModal: () => void;
  isEnabled?: boolean,
  canPause?: boolean
};
export const MoreInfo = ({ id, handleCloseModal, isEnabled , canPause}: MoreInfoProps) => {


  const [deleteAdvert, {isLoading: deletingAd}] = useDeleteAdvertMutation()
  const [pauseAdvert, {isLoading: pausingAdvert}] = usePauseAdvertMutation()

  const hanleDeleteAdvert = () =>{
    deleteAdvert(id)
    .unwrap()
    .then((res)=>{
      handleCloseModal()
    })
    .catch((err)=>{
      console.log(err, 'i am the advert')
    })

  }


  const handlePauseAdvert = () =>{
    pauseAdvert(Number(id) as number)
    .then((res)=>{
      handleCloseModal()
    })
    .catch((err)=>{
      toast.success(err?.data?.Message  || "Somthing went wrong!");
    })
  }

  return (
    <DurationBox>
      <UnorderedList>
        {/* <List>
          <Link to={"/home/campaign/flash-sales/7"}>Analyse</Link>
        </List> */}
        {canPause && <List onClick={()=> handlePauseAdvert()}>{pausingAdvert ? <ScaleLoader height="12px" width="2px" /> : isEnabled ?  "Pause" : "Resume"}</List>}
        <List onClick={()=> hanleDeleteAdvert()} color="#FF1E1E">{deletingAd ? <ScaleLoader height="12px" width="2px" /> : "Delete"}</List>
      </UnorderedList>
    </DurationBox>
  );
};

export const CreateCampaignModal: React.FC<ModalMainProps> = ({
  show,
  handleCloseModal,
  modalRef,
}) => {
  const [isCustomDatePickerOpen, setIsCustomDatePickerOpen] = useState(false);
  const [userDetail, setUser] = useState<UserDetail>();

  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [errMsg, setErr] = useState<string>("");
  //Campaign name
  const [campaignName, setCampaignName] = useState<string>("");
  const [budget, setBudget] = useState<string>("");
  //How long the campaign lasts
  const [duration, setDuration] = useState<string>("");
  //Request to create campaign
  const [createCampaign, { isLoading: creatingCampaign }] =
    useCreateCampaignMutation();

  // To open to the main campaign overview page
  const navigate = useNavigate();

  const handleCustomDatePickerToggle = (val: string) => {
    setDuration(val);
    if (val !== "custom") {
      let dateRange = getDateRange(val);
      setSelectedStartDate(dateRange?.startDate);
      setSelectedEndDate(dateRange?.endDate);
    } else {
      setIsCustomDatePickerOpen(!isCustomDatePickerOpen);
    }
  };

  const handleDateSelect = (startDate: Date | null, endDate: Date | null) => {
    setSelectedStartDate(startDate);
    setSelectedEndDate(endDate);
    setIsCustomDatePickerOpen(!isCustomDatePickerOpen);
  };

  const updateUserFromLocalStorage = () => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  };

  useEffect(() => {
    updateUserFromLocalStorage();
  }, []);

  const handleCreateCampaign = () => {
    if (!campaignName) {
      setErr("Please enter a name for this campaign");
    } else if (!budget || isNaN(Number(budget)) || Number(budget) <= 0) {
      setErr("Please enter a valid and positive budget amount");
    } else if (!duration) {
      setErr("Please select the duration for this campaign");
    }    
    else {
      setErr("");
      let data = {
        business_id: userDetail?.businesses[0]?.id || 0,
        name: campaignName,
        code: "",
        total_budget: Number(budget),
        start_date: selectedStartDate as Date,
        end_date: selectedEndDate as Date,
        is_enabled: true,
      };
      createCampaign(data)
        .unwrap()
        .then((res) => {
          navigate(`/home/campaign/main-campaign/${res?.data?.id}`);
        })
        .catch((err) => {
          console.log(err, "i am the err message");
        });
    }
  };

  // State to track tooltip visibility for Sync Status
  const [isCampaignTooltipVisible, setCampaignTooltipVisible] = useState(false);

  // State to track tooltip visibility for Ratings
  const [isBudgetTooltipVisible, setBudgetTooltipVisible] = useState(false);

  // State to track tooltip visibility for Ratings
  const [isDurationTooltipVisible, setDurationTooltipVisible] = useState(false);

  // Function to handle the tooltip visibility
  const handleCampaignTooltipToggle = (event: React.MouseEvent) => {
    setCampaignTooltipVisible((prevState) => !prevState);
  };

  const handleBudgetTooltipToggle = (event: React.MouseEvent) => {
    setBudgetTooltipVisible((prevState) => !prevState);
  };
  const handleDurationTooltipToggle = (event: React.MouseEvent) => {
    setDurationTooltipVisible((prevState) => !prevState);
  };

  // close calendar
  const handleCloseCalendar = () => {
    setIsCustomDatePickerOpen(false);
  };

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer>
        <BackArrow justifyContent alignItems>
          <div className="back">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Create Campaign</ModalTitle>
          </div>
        </BackArrow>
        <CreateBox>
          <EachBox>
            <TextTitle3>Campaign Name</TextTitle3>
            <Box22
              isTooltipVisible={isCampaignTooltipVisible}
              onMouseEnter={handleCampaignTooltipToggle}
              onMouseLeave={handleCampaignTooltipToggle}
              left="79.5%"
              transform="translateX(-79.5%)"
            >
              <img src={InfoIcon} alt="More information" />
              <div className="tooltip" style={{ width: "20rem" }}>
                <TooltipContent left="50%" transform="translateX(-20%)">
                  This is a concise and memorable label assigned to a specific
                  marketing or advertising initiative.
                </TooltipContent>
              </div>
            </Box22>
          </EachBox>
          <div>
            <Label14 htmlFor="name">Enter</Label14>
            <Input16
              value={campaignName}
              onChange={(e) => setCampaignName(e.target.value)}
              type="text"
              placeholder="Campaign Name"
              id="name"
            />
          </div>
          <EachBox>
            <TextTitle3>Budget</TextTitle3>
            <Box22
              isTooltipVisible={isBudgetTooltipVisible}
              onMouseEnter={handleBudgetTooltipToggle}
              onMouseLeave={handleBudgetTooltipToggle}
              left="79.5%"
              transform="translateX(-79.5%)"
            >
              <img src={InfoIcon} alt="More information" />
              <div className="tooltip" style={{ width: "26rem" }}>
                <TooltipContent left="50%">
                  This is the predetermined amount of financial resources
                  allocated to fund all the advertising activities associated
                  with this specific campaign
                </TooltipContent>
              </div>
            </Box22>
          </EachBox>
          <div>
            <Label14 htmlFor="amount">Amount</Label14>
            <Input16
              type="number"
              value={budget}
              onChange={(e) => setBudget(e.target.value)}
              id="amount"
            />
            {/* <ErrorText>Min amount: $200</ErrorText> */}
          </div>
          <EachBox>
            <TextTitle3>Duration</TextTitle3>
            <Box22
              isTooltipVisible={isDurationTooltipVisible}
              onMouseEnter={handleDurationTooltipToggle}
              onMouseLeave={handleDurationTooltipToggle}
              left="79.5%"
              transform="translateX(-79.5%)"
            >
              <img src={InfoIcon} alt="More information" />
              <div className="tooltip" style={{ width: "23rem" }}>
                <TooltipContent left="50%">
                  This is how long the adverts in this campaign will run
                </TooltipContent>
              </div>
            </Box22>
          </EachBox>
          <BtnGroupGrid>
            {durationOptions?.map((val) => {
              return (
                <Button
                  text={val.value}
                  variant="outline"
                  onClick={() => handleCustomDatePickerToggle(val.interval)}
                  className={val.interval === duration ? "active" : ""}
                />
              );
            })}
          </BtnGroupGrid>
          {/* Conditionally render the custom date picker modal */}
          {isCustomDatePickerOpen && (
            <CustomDatePicker
              onDateSelect={handleDateSelect}
              handleCloseModal={handleCloseCalendar}
            />
          )}
          {duration === "custom" && (
            <div>
              <EachBox>
                <TextTitle3> Custom Timeline</TextTitle3>
              </EachBox>
              <Input16
                type="text"
                placeholder=""
                value={
                  selectedStartDate && selectedEndDate
                    ? `${formatDate(selectedStartDate)} to ${formatDate(
                        selectedEndDate
                      )}`
                    : ""
                }
                id="timeline"
                readOnly
              />
            </div>
          )}
        </CreateBox>
        <ErrorText> {errMsg}</ErrorText>
        <BtnGroup>
          <Button
            isLoading={creatingCampaign}
            text="Create Campaign"
            onClick={() => handleCreateCampaign()}
            height="40px"
          />
          <Button text="Cancel" variant="outline" onClick={handleCloseModal} />
        </BtnGroup>
      </ModalContainer>
    </MainContainer>
  );
};

export const CountryModal = ({
  show,
  handleCloseModal,
  modalRef,
  selectedValues,
  handleSelect,
}: CampaignModalProps) => {
  const { data: allCountries, isLoading: gettingCountries } =
    useGetCountriesQuery();
  const [countries, setCountries] = useState<Country[]>([]);

  //This function handles searching the country list
  const handleSearch = (val: string) => {
    if (allCountries) {
      if (val === "") {
        setCountries(allCountries?.data);
      } else {
        const result = (allCountries?.data).filter((country) =>
          (country.name || "").toLowerCase().startsWith(val.toLowerCase())
        );
        setCountries(result);
      }
    }
  };

  useEffect(() => {
    if (allCountries) {
      setCountries(allCountries?.data);
    }
  }, [allCountries]);

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer gapSpace="1.875rem">
        <BackArrow>
          <div className="back-wallet">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Select Country</ModalTitle>
          </div>
        </BackArrow>
        <Form2>
          <img src={Search} alt="" className="img-size" />
          <Input
            type="text"
            placeholder="Search Countries"
            className="input-padding"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Form2>
        {gettingCountries && <CardLoader />}
        {selectedValues.length > 0 && (
          <Text12 color="#6b6b6b">
            {selectedValues.length} Countr
            {selectedValues.length > 1 ? "ies" : "y"} Selected
          </Text12>
        )}

        <div>
          {countries.map((country) => {
            return (
              <CountryBox
                border="1px solid rgba(0,0,0,0.12)"
                borderRaduis="0.75rem"
                onClick={() => handleSelect(country)}
              >
                <div className="flex">
                  <img src={country.flag} alt="Country's flag" />
                  <h4 className="title">{country.name}</h4>
                </div>
                {selectedValues.findIndex(
                  (eachCountry: Country) => eachCountry.id === country.id
                ) !== -1 && <img src={CheckIcon} alt="Check mark" />}
              </CountryBox>
            );
          })}
        </div>
        {(allCountries?.data?.length || 0) > 0 && (
          <MarginBottom>
            <Button text="Select" onClick={handleCloseModal} />
          </MarginBottom>
        )}
      </ModalContainer>
    </MainContainer>
  );
};

export const NetworkModal = ({
  show,
  handleCloseModal,
  modalRef,
  selectedValues,
  handleSelect,
}: NetworkModalProps) => {
  const { data: networks, isLoading: gettingNetworks } = useGetNetworksQuery();
  const [adNetworks, setNetworks] = useState<Network[]>([]);

  //This function handles searching the network list
  const handleSearch = (val: string) => {
    if (networks) {
      if (val === "") {
        setNetworks(networks?.data);
      } else {
        const result = (networks?.data).filter((network) =>
          (network.name || "").toLowerCase().startsWith(val.toLowerCase())
        );
        setNetworks(result);
      }
    }
  };

  useEffect(() => {
    if (networks) {
      setNetworks(networks?.data);
    }
  }, [networks]);

  return (
    <MainContainer show={show} ref={modalRef}>
      <ModalContainer gapSpace="1.875rem">
        <BackArrow>
          <div className="back-wallet">
            <button onClick={handleCloseModal} className="btn-reset">
              <ArrowLeft className="arrow-left" />
            </button>
            <ModalTitle>Select Network</ModalTitle>
          </div>
        </BackArrow>
        <Form2>
          <img src={Search} alt="" className="img-size" />
          <Input
            type="text"
            placeholder="Search Networks"
            className="input-padding"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Form2>
        {gettingNetworks && <CardLoader />}
        {selectedValues.length > 0 && (
          <Text12 color="#6b6b6b">
            {selectedValues.length} Networks Selected
          </Text12>
        )}
        <div>
          {adNetworks.map((network) => {
            return (
              <CountryBox
                border="1px solid rgba(0,0,0,0.12)"
                borderRaduis="0.75rem"
                onClick={() => handleSelect(network)}
              >
                <div className="flex">
                  {network?.photo ? (
                    <img src={network?.photo} alt={network?.name} />
                  ) : (
                    <Avatar>
                      <p>{network?.name?.slice(0, 2).toUpperCase() || "NA"}</p>
                    </Avatar>
                  )}
                  <h4 className="title">{network?.name}</h4>
                </div>
                {selectedValues.findIndex(
                  (eachNetwork: Network) => eachNetwork.id === network.id
                ) !== -1 && <img src={CheckIcon} alt="Check mark" />}
              </CountryBox>
            );
          })}
          {!gettingNetworks && adNetworks.length === 0 && <EmptyBox message={`Network not found!`}/>}
        </div>
        {adNetworks.length !== 0 && <MarginBottom>
          <Button text="Select" onClick={handleCloseModal} />
        </MarginBottom>}
      </ModalContainer>
    </MainContainer>
  );
};

export const AdvertSummaryModal: React.FC<AdvertSummaryModalProps> = ({
  showRole,
  modalRef,
  name,
  networks,
  countries,
  images,
  handleCreateAdvert,
  isLoading,
  minimumBudget,
  myBid,
}) => {
  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <ModalInnerBox background="#1A1A1A" width="90%" widthBox="60%">
        <FlexBox>
          <Text14 color="#fff" fontSize="1.125rem">
            Advert Summary
          </Text14>
          <img src="" alt="" />
        </FlexBox>
        <Box12>
          <Box500>
            <FlexBoxTable background="#fff">
              <Text14 color="#6b6b6b" marginBottom="0">
                Name
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                {name}
              </Text14>
            </FlexBoxTable>
            <FlexBoxTable>
              <Text14 color="#6b6b6b" marginBottom="0">
                Network
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                {networks}
              </Text14>
            </FlexBoxTable>
            <FlexBoxTable background="#fff">
              <Text14 color="#6b6b6b" marginBottom="0">
                Total Number of Countries
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                {countries}
              </Text14>
            </FlexBoxTable>
            <FlexBoxTable>
              <Text14 color="#6b6b6b" marginBottom="0">
                Total Bid cost
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                ₦{myBid}
              </Text14>
            </FlexBoxTable>
            <FlexBoxTable background="#fff">
              <Text14 color="#6b6b6b" marginBottom="0">
                Media Upload
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                {images}
              </Text14>
            </FlexBoxTable>
            <FlexBoxTable>
              <Text14 color="#6b6b6b" marginBottom="0">
                Daily Budget
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                {!minimumBudget ? "No Daily Budget" : ` ₦ ${minimumBudget}/day`}
              </Text14>
            </FlexBoxTable>
            {/* <FlexBoxTable background="#fff">
              <Text14 color="#6b6b6b" marginBottom="0">
                Expected Views
              </Text14>
              <Text14 color="#6b6b6b" marginBottom="0">
                2000
              </Text14>
            </FlexBoxTable> */}
          </Box500>
        </Box12>

        <BtnGroup>
          <Button
            text="Start Advert"
            onClick={handleCreateAdvert}
            isLoading={isLoading}
          />
        </BtnGroup>
      </ModalInnerBox>
    </RoleBoxMain>
  );
};

export const CellRenderer = ({ id, isEnabled, canPause }: { id?: string, isEnabled?:boolean, canPause: boolean }) => {
  const [isOption, setIsOption] = useState<string>("");

  const modalRefOptions = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsOption("");
  };

  const handleOption = () =>{
    if(id === isOption){
      setIsOption("")
    }else{
      setIsOption(id as string)
    }
  }

  useOutsideClick(modalRefOptions, handleCloseModal);

  return (
    <div style={{ position: "relative" }}>
      <BtnMore
        onClick={handleOption}
      >
        <MoreMenu />
      </BtnMore>
      {isOption === id && 
        <MoreInfo 
          id={id as string} 
          isEnabled={isEnabled} 
          handleCloseModal={handleCloseModal} 
          canPause={canPause}
        />
      }
    </div>
  );
};
