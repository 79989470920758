// Format dates as "dd-mm-yyyy"
export const formatDate = (date: Date | null): string => {
  if (date) {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }
  return "";
};

//Open Camers
export const openCamera = () => {
  return async () => {
    let stream = await navigator.mediaDevices.getUserMedia({
      video: true,
      audio: false,
    });
    let videotostream = window.document.getElementById(
      "videocamera"
    ) as HTMLVideoElement;
    videotostream.srcObject = stream;
    return stream;
  };
};

//Take a picture
export const takePicture = (stream: MediaStream) => {
  return (): string => {
    const canvas = document.getElementById(
      "captureImage"
    ) as HTMLCanvasElement | null;
    const video = document.getElementById(
      "videocamera"
    ) as HTMLVideoElement | null;

    if (!canvas || !video) {
      throw new Error("Canvas or video element not found.");
    }

    const context = canvas.getContext("2d");
    if (!context) {
      throw new Error("Canvas context not available.");
    }

    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);

    const image_data_url = canvas.toDataURL("image/jpeg");

    video.srcObject = null;

    const tracks = stream.getTracks();
    tracks.forEach((track) => track.stop());

    return image_data_url;
  };
};
