import React, { useState } from "react";
import { GraphTab, Section } from "../Dashboard/Dashboard.styled";
import MyDetails from "./MyDetails";
import Security from "./Security";

const Account: React.FC = () => {
  const [showTabs, setShowTabs] = useState({
    details: true,
    security: false,
  });

  const handleDetails = () => {
    setShowTabs({ details: true, security: false });
  };
  const handleSecurity = () => {
    setShowTabs({ details: false, security: true });
  };

  return (
    <Section>
      <GraphTab widthGraphBox="fit-content">
        <button
          onClick={handleDetails}
          className={`${showTabs.details ? "active" : ""}`}
        >
          My Details
        </button>
        <button
          onClick={handleSecurity}
          className={`${showTabs.security ? "active" : ""}`}
        >
          Security
        </button>
      </GraphTab>
      <MyDetails show={showTabs.details} />
      <Security show={showTabs.security} />
    </Section>
  );
};

export default Account;
