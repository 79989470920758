import React from "react";
import BgAdvert from "../../assets/images/Frame.png";
import { EmptyBoxProps } from "./types";
import { PendBox } from "./Reuseable.styled";

const EmptyBox = ({ message }: EmptyBoxProps) => {
  return (
    <PendBox>
      <img
        src={BgAdvert}
        alt=""
        className="bg-ad-img"
        width={253}
        height={128}
      />
      <p>{message}</p>
    </PendBox>
  );
};

export default EmptyBox;
