import styled from "styled-components";
import { COLOR_BORDER_2 } from "../../styles/themes/colors";
import { RoleWidthBox } from "../../types";
import { TextProps } from "./types";

export const FlexBox = styled.div<TextProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  gap: ${(props) => props.gap || "0.5rem"};
  margin-top: ${(props) => props.MarginTop || "0"};
  flex-direction: ${(props) => props.flexDirection || "row"};
  height: ${(props) => props.height || "auto"};
  /* cursor: ${(props) => props.height || "pointer"}; */
  @media (max-width: 800px) {
    flex-wrap: ${(props) => props.flexWrap || "nowrap"};
  }
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20%, 1fr));
  gap: 1rem;
  margin-top: 2.5rem;
`;

export const MarginTop = styled.div<TextProps>`
  margin-top: ${(props) => props.marginTop || "3rem"};
`;

export const MarginBottom = styled.div<TextProps>`
  margin-bottom: ${(props) => props.marginBottom || "0.75rem"};
`;

export const Margin = styled.div<TextProps>`
  margin: ${(props) => props.margin || "0 0"};
`;

export const TextCenter = styled.div`
  text-align: center;
  width: 80%;

  @media (min-width: 650px) {
    width: 500px;
  }
`;

export const PendBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* height: 75vh; */
  height: calc(100vh - 320px);
  gap: 2.875rem;
  .empty-box {
    width: 530px;
  }
  .btn-width {
    width: 10rem;
    margin: 3rem auto 0;
  }
  @media (max-width: 600px) {
    .bg-ad-img {
      width: 199.5px;
      height: 124.61px;
    }
    .empty-box {
      width: 80%;
    }
  }
`;

export const ModalInnerBox = styled.div<RoleWidthBox>`
  background: ${(props) => (!props.background ? "#fefefe" : props.background)};
  border-radius: 12px;
  text-align: center;
  width: ${(props) => props.width || "24rem"};
  padding: 1.5rem;
  margin: 0 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.6rem;

  @media (min-width: 390px) {
    width: ${(props) => (!props.widthBox ? "100%" : props.widthBox)};
    padding: ${(props) => (!props.padding ? "2.5rem 3rem" : props.padding)};
    margin: 0;
  }

  button {
    width: 8.625rem;
  }
  .cancelbtn {
    width: 24px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: #f5f5f5;
    border: none;
  }
`;

export const AddCampBox = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 12px;
  background: #e5b9101a;
  padding: 1rem 0;
  img {
    width: 20px;
    height: 20px;
    margin-bottom: 1.25rem;
  }
`;

export const ImgBox = styled.div<TextProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.75rem;
  height: 1.75rem;
  border-radius: ${(props) => props.borderRadius || "8px"};
  background: ${(props) => props.background || "#e5b9101a"};
`;

export const RoundBox = styled.div<TextProps>`
  width: 8px;
  height: 8px;
  border-radius: ${(props) => props.borderRadius || "50%"};
  background: ${(props) => props.background || "#D9D9D9"};
`;

export const CampNameBox = styled.div`
  display: grid;
  gap: 4px;
  border-radius: 12px;
  border: 1px solid #cecece40;
  padding: 1rem;
`;

export const DurationBox = styled.div`
  position: absolute;
  background-color: #fefefe;
  width: 9.5rem;
  right: 0.3rem;
  z-index: 3;
  border: 2px solid #efe1e1;
  border-radius: 5px;
`;

export const UnorderedList = styled.ul<TextProps>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || "0.5rem"};
  /* gap: 0.5rem; */
  padding: 6.65px 0;
  border-radius: 0.5rem;
`;

export const List = styled.li<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.4;
  padding: 6.65px 13.3px;
  list-style: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(37, 52, 106, 0.08);
  }
`;

// Creat campaign modal
export const CreateBox = styled.div<TextProps>`
  display: grid;
  gap: ${(props) => props.gap || "1.875rem"};
`;

export const EachBox = styled.div<TextProps>`
  display: flex;
  align-items: center;
  justify-content: ${(props) => props.justifyContent || "flex-start"};
  gap: 1rem;
  padding-bottom: 0.5rem;
`;

export const Label14 = styled.label<TextProps>`
  color: ${(props) => props.color || "#6b6b6b"};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: 400;
  line-height: 1.7;
`;

export const ErrorText = styled.p<TextProps>`
  color: #ff1e1e;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 0.5rem;
`;

export const Input16 = styled.input<TextProps>`
  color: #111; 
  display: block;
  width: ${(props) => props.width || "100%"};
  padding-bottom: 0.5rem;
  font-size: 1rem;
  line-height: 1.3;
  margin-top: 0.75rem;
  border: none;
  border-bottom: 2px solid #c4c4c4;
  &:focus {
    border-bottom: 2px solid #25346a;
  }
  &::placeholder {
    color: #c4c4c4 !important;
  }
`;

export const Input11 = styled.input<TextProps>`
  color: #1a1a1a;
  padding: 0.5rem 1rem;
  font-size: 0.6875rem;
  font-weight: 400;
  line-height: 1.4;
  border: none;
  border-radius: 26px;
  background: #f5f5f5;
  &:focus {
    border-bottom: 1px solid #25346a;
  }
`;

// Overview

export const OverviewBox = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 20px;
  background: #2f80ed1a;
  padding: 1.875rem;
  margin-bottom: 2rem;
  @media (max-width: 600px) {
    .bg-ad-img {
      width: 199.5px;
      height: 124.61px;
    }
  }
`;

export const CampInnerBox = styled.div<TextProps>`
  display: grid;
  gap: ${(props) => props.gap || "2rem"};
`;

// Progress bar style
export const ProgressBarContainer = styled.div`
  display: grid;
  gap: 1px;
  width: 100%;
  margin-top: 0.7rem;
`;
export const GridBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 30%;
  align-items: flex-end;
  gap: 0.5rem;
`;

export const ProgressBarWrapper = styled.div`
  width: 100%;
  height: 0.2rem;
  background-color: #c4c4c4;
  border-radius: 12px;
  overflow: hidden;
`;

export const ProgressBarFill = styled.div<{ percentage: number }>`
  width: ${(props) => props.percentage}%;
  height: 100%;
  background-color: #e5b910;
  transition: width 0.5s ease-in-out;
`;

export const CircleSpan = styled.span<TextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) => props.background || "#e5b910"};
  font-size: 0.75rem;
  color: #fff;
  width: ${(props) => props.width || "18px"};
  height: ${(props) => props.height || "18px"};
  border-radius: 50%;
  margin-right: ${(props) => props.marginRight || "0"};

  .image-round {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`;

export const CampButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.3rem 0.5rem;
  font-size: 0.6875rem; //11px
  font-weight: 500;
  line-height: 1.4;
  border-radius: 26px;
  border: 1px solid #e5b910;
  background: transparent;
  color: #e5b910;
`;

// Pie and circular progress bar

export const PieBox = styled.div`
  flex: 1;
  padding: 2rem;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 2px 6px 0px rgba(13, 10, 44, 0.08);
  border-bottom: 1px solid #e5e5ef;
`;

export const LineBottom = styled.div`
  border-bottom: 1px solid #e5e5ef;
`;

export const BtnGroupGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(6rem, 1fr));
  gap: 1rem;
`;

export const BtnGroup = styled.div<TextProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: ${(props) => props.flexDirection || "row"};
  gap: 0.75rem;
  margin-top: auto;
`;

// Create content
export const ImgUploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 1rem;
  width: 45%;
  border-radius: 12px;
  border: 1px dashed #c4c4c4;
  padding: 3rem 1rem 0.8rem;
  margin: 0.8rem 0 3rem;
  cursor: pointer;
`;

// Create budget
export const BlueInfoBox = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem 1.5rem;
  flex-direction: column;
  gap: 0.5rem;
  border-radius: 12px;
  border: 0.5px solid #2f80ed;
  background: #eaf1ff;
  margin: 1.875rem 0;
  @media (min-width: 600px) {
    width: 80%;
  }
`;

export const SettingBox = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  margin-top: ${(props) => props.marginTop || "0"};
`;

export const Box12 = styled.div`
  /* width: 829px; */
  border-radius: 12px;
  background: #fff;
`;

export const FlexBoxTable = styled.div<TextProps>`
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: ${(props) => props.alignItems || "center"};
  gap: ${(props) => props.gap || "0.5rem"};
  padding: ${(props) => props.padding || "1.25rem 0.75rem 1.25rem"};
  background: ${(props) => props.background || "#fbfbfb"};
`;

export const Box500 = styled.div`
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  @media (min-width: 800px) {
    width: 500px;
  }
`;

export const SelectWrapperMain = styled.button`
  display: flex;
  gap: 9rem;
  position: relative;
  background-color: transparent;
  border: 1px solid ${COLOR_BORDER_2};
  width: 100%;
  border-radius: 26px;
  padding: 0.625rem 1.06rem;
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: 0.875rem;
  width: 50%;
  cursor: pointer;

  @media (min-width: 390px) {
    width: auto;
  }
`;

export const BtnMore = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: rgba(37, 52, 106, 0.08);
  border-radius: 50%;
  cursor: pointer;
  &:hover {
    background-color: #e0dcdc;
    transition: color 300 ease-in-out;
  }
`;

// circular progress bar
export const CircularTextBox = styled.div`
  color: #1e1b39;
  text-align: center;
  margin-top: -5px;
`;

export const CircularText1 = styled.h3`
  font-size: 78px;
  font-weight: 600;
  line-height: 84px; /* 107.692% */
`;

export const CircularTextP = styled.p`
  font-size: 18px;
  font-weight: 400;
  line-height: 32px; /* 177.778% */
`;

export const CircularTextSpan = styled.span`
  font-size: 22px;
  font-weight: 700;
  line-height: 28px;
`;

export const ModalBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.12);
  backdrop-filter: blur(8px);
  overflow: hidden !important;
  z-index: 101;
  height: 100vh;
`;
