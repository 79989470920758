import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { ReactComponent as Success } from "../../assets/icons/success.svg";
import {
  Description,
  Title,
  ContainerVerify,
  SuccessBox,
  InnerContainer,
} from "./styles/Container.styled";
import { Button } from "../Reusable/Buttons/button";
import ProgressBar from "./progressBar";

interface ISuccsess {
  handleProceed: (val: number) => void;
  email: string;
}

const SuccessPage: React.FC<ISuccsess> = ({ handleProceed, email }) => {
  return (
    <ContainerVerify className="justify">
      <InnerContainer alignItems gap="1.75rem">
        <Logo className="logo" width={115} height={25.578} />
        <ProgressBar percentage={25} text={"1/4"} />
        <Success width={90} height={90} />

        <SuccessBox>
          <Title className="success__title">Email Verified</Title>
          <Description className="success__para">{email}</Description>
          <Description className="success__para2">
            You're almost done just a few steps away
          </Description>
        </SuccessBox>

        <Button text="Proceed" onClick={() => handleProceed(3)} height="40px" />
      </InnerContainer>
    </ContainerVerify>
  );
};

export default SuccessPage;
