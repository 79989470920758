import React, { useState } from "react";
import { RoleInnerBox, RoleBoxMain, BtnGroup, Box7 } from "./Dashboard.styled";
import { Button, ButtonReset } from "../Reusable/Buttons/button";
import {
  Box6,
  Box8,
  Label2,
  WalletForm,
} from "../Wallet/Wallet.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import { FilterAdsProps } from "../../types";
import { advertStatus, viewPeriod } from "../Campaign/helper";

export const FilterGroupCampaign: React.FC<FilterAdsProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
  applyFilter
}) => {
  const [isDurationOpen, setIsDurationOpen] = useState(false);
  const [selectedPeriod, setPeriod] = useState({id: "", period: "All"});

  // const handleCampaign = (e: MouseEvent<HTMLButtonElement>): void => {
  //   e.preventDefault();
  //   setIsCampaignOpen(!isCampaignOpen);
  // };

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox padding="1rem" widthBox="260px" textAlign="left">
        <WalletForm>
          <div>
            <Box6>
              <Label2 htmlFor="filter">Duration</Label2>
              <Box8 onClick={()=> setIsDurationOpen(!isDurationOpen)}>
                <p>{selectedPeriod.period}</p>
                <ButtonReset >
                  <img src={ArrowYellow} alt="" className="select-img" />
                </ButtonReset>
              </Box8>
            </Box6>
            {isDurationOpen && (
              <Box7>
                <ul>
                  {viewPeriod.map((each)=>{
                    return(
                      <li onClick={()=> {setIsDurationOpen(!isDurationOpen); setPeriod(each) }}> {each.period}</li>
                    )
                  })}
                </ul>
              </Box7>
            )}
          </div>
          {/* <div>
            <Box6>
              <Label2 htmlFor="filter">Campaign</Label2>
              <Box8>
                <p>Months</p>
                <ButtonReset onClick={handleCampaign}>
                  <img src={ArrowYellow} alt="" className="select-img" />
                </ButtonReset>
              </Box8>
            </Box6>
            {isCampaignOpen && (
              <Box7>
                <ul>
                  <li>All</li>
                  <li>Campaign 1</li>
                  <li>Campaign 2</li>
                  <li>Campaign 3</li>
                  <li>Campaign 4</li>
                </ul>
              </Box7>
            )}
          </div> */}
          <BtnGroup>
            <Button
              text="Apply filter"
              fontSizeProps="0.875rem"
              paddingProps="0.47rem"
              onClick={(e) => {
                e.preventDefault();
                applyFilter(selectedPeriod.id);
                handleCloseRole();
              }}
            />
            <Button
              text="Cancel"
              variant="outline"
              fontSizeProps="0.875rem"
              onClick={handleCloseRole}
              paddingProps="0.47rem"
            />
          </BtnGroup>
        </WalletForm>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

export const FilterAdverts: React.FC<FilterAdsProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
  applyFilter
}) => {
  const [isDurationOpen, setIsDurationOpen] = useState(false);
  const [selectedStatus, setStatus] = useState({id: "", status: "All"});

  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox padding="1rem" widthBox="260px" textAlign="left">
        <WalletForm>
          <div>
            <Box6>
              <Label2 htmlFor="filter">Status</Label2>
              <Box8 onClick={()=>  setIsDurationOpen(!isDurationOpen)}>
                <p>{selectedStatus.status}</p>
                <ButtonReset >
                  <img src={ArrowYellow} alt="" className="select-img" />
                </ButtonReset>
              </Box8>
            </Box6>
            {isDurationOpen && (
              <Box7>
                <ul>
                  {advertStatus.map((each)=>{
                    return(
                      <li onClick={()=> {setStatus(each); setIsDurationOpen(!isDurationOpen)}}>{each.status}</li>
                    )
                  })}
                </ul>
              </Box7>
            )}
          </div>
          {/* <div>
            <Box6>
              <Label2 htmlFor="filter">Network</Label2>
              <Box8>
                <p>Months</p>
                <ButtonReset onClick={handleCampaign}>
                  <img src={ArrowYellow} alt="" className="select-img" />
                </ButtonReset>
              </Box8>
            </Box6>
            {isCampaignOpen && (
              <Box7>
                <ul>
                  <li>All</li>
                  <li>Campaign 1</li>
                  <li>Campaign 2</li>
                  <li>Campaign 3</li>
                  <li>Campaign 4</li>
                </ul>
              </Box7>
            )}
          </div> */}
          <BtnGroup>
            <Button
              text="Apply filter"
              fontSizeProps="0.875rem"
              paddingProps="0.47rem"
              onClick={(e) => {
                e.preventDefault();
                applyFilter(selectedStatus.id);
                handleCloseRole();
              }}
            />
            <Button
              text="Cancel"
              variant="outline"
              fontSizeProps="0.875rem"
              onClick={handleCloseRole}
              paddingProps="0.47rem"
            />
          </BtnGroup>
        </WalletForm>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

