import { ReactComponent as Verify } from "../../assets/icons/verifyID.svg";
import Camera from "../../assets/icons/camera.svg";
import ProfileIcon from "../../assets/icons/profile-3.svg";
import ProficPhotoId from "../../assets/images/profile-photo-id.svg";
import IDCardIcon from "../../assets/images/id-card.svg";
import { Button } from "../Reusable/Buttons/button";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import TickIcon from "../../assets/icons/tick-square.svg";
import { CircleSpan, FlexBox, MarginBottom } from "../Campaign/Campaign.styled";
import { Text10, Text14, Text400, TextTitle2 } from "../../styles/all.styled";
import {
  BlueInfoBox2,
  BoxWidth,
  BoxWidthMargin,
  FontWeight,
  ImgBox,
  List,
  MarginBox,
  TextCenter,
  UnorderedList,
  VerifySection,
} from "./Verification.styled";
import { useEffect, useRef, useState } from "react";
import { ChooseID } from "./VerificationModals";
import { UserDetail } from "../../features/api/authTypes";

interface CaptureProps {
  handlePage: () => void;
}

export const VerifyPage = ({ handlePage }: CaptureProps) => {
  return (
    <VerifySection>
      <FlexBox flexDirection="column" justifyContent="center" gap="1rem">
        <MarginBottom marginBottom="1rem">
          <Logo className="logo" width={112} height={22.578} />
        </MarginBottom>
        <MarginBottom marginBottom="1rem">
          <Verify width={70} height={70} />
        </MarginBottom>
        <TextCenter>
          <TextTitle2 fontSize="1rem" color="#25346a" marginBottom="0.5rem">
            Verify your identity
          </TextTitle2>
          <BoxWidthMargin>
            <Text10 lineHeight="normal" fontWeight="400">
              We must verify that you are who you claim to be. This is how it
              works:
            </Text10>
          </BoxWidthMargin>
        </TextCenter>
        <UnorderedList>
          <List>
            <CircleSpan background="#E5B910" marginRight="0.5rem">
              1
            </CircleSpan>
            <ImgBox src={ProfileIcon} alt="Take a pic" width={20} height={20} />
            <BoxWidth>
              <Text14 marginBottom="0.375rem" color="#6B6B6B">
                Take a snap of a valid photo ID card.
              </Text14>
              <Text400 fontSize="0.75rem">
                To check if your personal information is correct
              </Text400>
            </BoxWidth>
          </List>
          <List>
            <CircleSpan background="#E5B910" marginRight="0.5rem">
              2
            </CircleSpan>
            <ImgBox src={Camera} alt="Take a selfie" width={20} height={20} />
            <BoxWidth>
              <Text14 marginBottom="0.375rem" color="#6B6B6B">
                Take a selfie with a written phrase
              </Text14>
              <Text400 fontSize="0.75rem">
                To match your face to your ID photo
              </Text400>
            </BoxWidth>
          </List>
        </UnorderedList>
        <BlueInfoBox2>
          <Text400 className="font-normal text-x8 text-clrGray">
            <FontWeight className="font-semibold">Note:</FontWeight> Your info
            would be encrypted , stored securely, and only ever used to verify
            your identity
          </Text400>
        </BlueInfoBox2>

        <Button
          onClick={handlePage}
          type="button"
          text="Get Started"
          widthProps="50%"
          marginTop="1rem"
        />
      </FlexBox>
    </VerifySection>
  );
};

export const ValidPhotoID = ({ handlePage }: CaptureProps) => {
  return (
    <VerifySection>
      <FlexBox flexDirection="column" justifyContent="center" gap="1rem">
        <MarginBottom marginBottom="1rem">
          <Logo className="logo" width={112} height={22.578} />
        </MarginBottom>
        <MarginBottom marginBottom="1rem">
          <Verify width={70} height={70} />
        </MarginBottom>
        <TextCenter>
          <TextTitle2 fontSize="1rem" color="#25346a" marginBottom="0.5rem">
            Take a snap of a valid photo ID
          </TextTitle2>
          <BoxWidthMargin>
            <Text10 lineHeight="normal" fontWeight="400">
              Checking that your National ID, International Passport, and full
              or temporary driver's license:{" "}
            </Text10>
          </BoxWidthMargin>
        </TextCenter>
        <UnorderedList>
          <List width="fit-content">
            <img src={TickIcon} alt="Check" width={15} height={15} />
            <Text10 fontWeightMd="600">It hasn't expired</Text10>
          </List>
          <List width="fit-content">
            <img src={TickIcon} alt="Check" width={15} height={15} />
            <Text10 fontWeightMd="600">It's clear and easy to read</Text10>
          </List>
          <List width="fit-content">
            <img src={TickIcon} alt="Check" width={15} height={15} />
            <Text10 fontWeightMd="600">
              The entire ID is shown in the photo
            </Text10>
          </List>
        </UnorderedList>
        <BlueInfoBox2 className="bg-clrTextBlue dark:bg-white dark:bg-opacity-10 bg-opacity-10 px-5 py-3 rounded-[0.25rem]">
          <Text400 className="font-normal text-x8 text-clrGray dark:text-clrPlaceholder">
            <FontWeight className="font-semibold">Note:</FontWeight> Your info
            would be encrypted , stored securely, and only ever used to verify
            your identity
          </Text400>
        </BlueInfoBox2>
        <Button
          onClick={handlePage}
          type="button"
          text="Let's Begin"
          widthProps="50%"
          marginTop="1rem"
        />
      </FlexBox>
    </VerifySection>
  );
};

export const VerifyPhotoID = ({ handlePage }: CaptureProps) => {

  // Get user
  const [userDetail, setUser] = useState<UserDetail>();

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);


  return (
    <VerifySection>
      <FlexBox flexDirection="column" justifyContent="center" gap="1rem">
        <Logo className="logo" width={112} height={22.578} />
        <MarginBox margin="1rem 0">
          <img src={ProficPhotoId} alt="Profile" width={324} height={186} />
        </MarginBox>
        <BoxWidthMargin widthMd="45%">
          <TextTitle2 fontSize="1.15rem" color="#25346a" marginBottom="0.5rem">
            Take a snap of a valid photo ID{" "}
          </TextTitle2>
          <Text10 lineHeight="normal" fontWeight="400">
            Ensure that we can clearly see you, and the plain sheet with a
            phrase that appear on camera so that we can match you to your photo
            ID.
          </Text10>
        </BoxWidthMargin>
        <MarginBox>
          <Text10 fontWeight="400">
            Phrase:
            <FontWeight fontWeight="600">
              {" "}
              “Hi my name is {userDetail ? userDetail?.first_name : ""} {" "} {userDetail ? userDetail?.last_name : ""} and i want a wicrypt account”
            </FontWeight>
          </Text10>
        </MarginBox>
        <BlueInfoBox2>
          <Text400 className="font-normal text-x8 text-clrGray">
            <FontWeight className="font-semibold">Note:</FontWeight> Your info
            would be encrypted , stored securely, and only ever used to verify
            your identity
          </Text400>
        </BlueInfoBox2>
        <Button
          onClick={handlePage}
          type="button"
          text="Let's Begin"
          widthProps="50%"
          marginTop="1rem"
        />
      </FlexBox>
    </VerifySection>
  );
};

export const VerifyOnGoing = ({ handlePage }: CaptureProps) => {
  return (
    <VerifySection>
      <FlexBox flexDirection="column" justifyContent="center" gap="1rem">
        <MarginBottom marginBottom="1rem">
          <Logo className="logo" width={112} height={22.578} />
        </MarginBottom>
        <MarginBox margin="1rem 0">
          <img
            src={IDCardIcon}
            alt="ID card"
            width={180}
            height={110.455}
            className="block dark:hidden w-24 md:w-32"
          />
        </MarginBox>
        <BoxWidthMargin widthMd="80%">
          <TextTitle2 fontSize="1rem" color="#25346a" marginBottom="0.5rem">
            We're verifying your account information
          </TextTitle2>
          <Text10 lineHeight="normal" fontWeight="400">
            This usually takes between 5 minutes and an hour, but can sometimes
            take up to 48 hours, Please check back later
          </Text10>
        </BoxWidthMargin>
        <Button
          onClick={handlePage}
          type="button"
          text="Go back to dashboard"
          widthProps="50%"
          marginTop="1rem"
        />
      </FlexBox>
    </VerifySection>
  );
};
