import Login from "../components/Authentication/login";
import Signup from "../components/Authentication/signup";
import GlobalStyle from "../styles/base/global";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { lightTheme } from "../styles/themes/lightTheme";
import Dashboard from "../components/Dashboard/Dashboard";
import Account from "../components/Account/Account";
import Wallet from "../components/Wallet/Wallet";
import Home from "../pages/Home";
import NotFound from "../error/404";
import Campaign from "../components/Campaign";
import MainCampaign from "../components/Campaign/MainCampaign";
import CreateCampaign13 from "../components/Campaign/CreateCampaign13";
import Overview from "../components/Campaign/CampaignDetail";
import AdvertCampaign from "../components/Campaign/AdvertCampaign";
import Details from "../components/Campaign/CampaignFlashsales/Details";
import OverviewFlashsales from "../components/Campaign/CampaignFlashsales/Overview";
import CampaignFlashsales from "../components/Campaign/CampaignFlashsales";
import { Verification } from "../components/Verification";
import Reset from "../components/Authentication/reset";
import { Toaster } from "react-hot-toast";
import ProtectedRoute from "./ProtectedRoute";

function App() {
  return (
    <>
      <ThemeProvider theme={lightTheme}>
        <BrowserRouter>
          <Toaster />
          <GlobalStyle />
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/reset" element={<Reset />} />
            <Route path="/verification" element={<Verification />} />
            {/* Private Routes */}
            <Route
              path="/home"
              element={
                <ProtectedRoute redirectPath="/">
                  <Home />
                </ProtectedRoute>
              }
            >
              <Route
                path="/home"
                element={<Navigate replace to="dashboard" />}
              />
              <Route path="dashboard" element={<Dashboard />} />

              <Route path="account" element={<Account />} />
              <Route path="wallet" element={<Wallet />} />
              <Route path="campaign" element={<Campaign />} />

              <Route
                path="campaign/main-campaign/:campaignId"
                element={<MainCampaign />}
              />
              <Route
                path="campaign/main-campaign/:campaignId"
                element={<MainCampaign />}
              >
                <Route
                  path="/home/campaign/main-campaign/:campaignId"
                  element={<Navigate replace to="overview" />}
                />
                <Route path="overview" element={<Overview />} />
                <Route path="advert-campaign" element={<AdvertCampaign />} />
              </Route>
              <Route
                path="campaign/:campaignId/create-advert"
                element={<CreateCampaign13 />}
              />
              <Route
                path="campaign/flash-sales/:advertId"
                element={<CampaignFlashsales />}
              >
                <Route
                  path="/home/campaign/flash-sales/:advertId"
                  element={<Navigate replace to="overview" />}
                />
                <Route path="overview" element={<OverviewFlashsales />} />
                <Route path="details" element={<Details />} />
              </Route>
            </Route>
            {/* Redirect to login page for unknown routes */}
            <Route path="*" element={<Navigate to="/" replace />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </>
  );
}

export default App;
