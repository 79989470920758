
import { apiSlice } from "./apiSlice";
import { DashboardOverviewResponse } from "./dashboardSliceTypes";

const dashboardSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get Network Dashboard Overview 
        getDashboardOverview: build.query<DashboardOverviewResponse, string>({
            query: (period) => `/businesses/overview?period=${period}`,
        }),

        //Get Network Dashboard Overview 
        firstLogin: build.query<DashboardOverviewResponse, void>({
            query: () => ({
                url: `/users/first_login`,
                method: 'PATCH',
            }),
        }),
    }),
    overrideExisting: true,
})


export const { useGetDashboardOverviewQuery, useLazyFirstLoginQuery } = dashboardSlice