import React, { useEffect, useState } from "react";
import { BackArrow, Input } from "../Authentication/styles/Container.styled";
import ArrowYellow from "../../assets/icons/Wallet/arrow-yellow.svg";
import Search from "../../assets/icons/Wallet/Search.svg";
import { CountryBox, Form2 } from "../Wallet/Wallet.styled";
import {
  BoxBorder,
  BtnGroup2,
  CaptureImageBox,
  CaptureImageBox2,
  CaptureImageBtn,
  CaptureImageInput,
  CaptureLabel,
  SmallCircle,
  TextBox,
  TopBox,
  Wrapper1,
  WrapperInner,
  WrapperInner2,
} from "./Verification.styled";
import { BtnGroup, CircleSpan } from "../Campaign/Campaign.styled";
import {
  Text10,
  Text12,
  Text14,
  Text400,
  TextTitle2,
} from "../../styles/all.styled";
import { Button } from "../Reusable/Buttons/button";
import {
  CaptureDocumentProps,
  CaptureSelfieProps,
  ChooseIDProps,
  ConfirmProps,
  RegionProps,
} from "./type";
import { ErrorMessage } from "../Reusable/Error";
import TickSquare from "../../assets/icons/Wallet/tick-square.svg";
import { ReactComponent as PaperClip } from "../../assets/icons/paper-clip.svg";
import { ReactComponent as PhotographIcon } from "../../assets/icons/photograph.svg";
import { idCollection, countries } from "./data";
import { openCamera, takePicture } from "../../helpers";
import { CaptureModal, Modal, VerifyModal } from "../Reusable/Modal";

//Function to allow user to choose the ID to verify

export const ChooseID: React.FC<ChooseIDProps> = ({
  handleSubmit,
  handleGoBack,
}) => {
  return (
    <VerifyModal
      title={"Choose ID"}
      description={"Please select your preferred ID"}
      goBack={true}
      handleGoBack={handleGoBack}
    >
      <div>
        {idCollection.map((id) => {
          return (
            <CountryBox
              as={"button"}
              onClick={() => handleSubmit(id.id)}
              key={id.id}
            >
              <Text12 fontSize="0.875rem" color="#000000DE" className="title">
                {id.name}
              </Text12>
              <img src={ArrowYellow} alt="Arrow" width={20} height={20} />
            </CountryBox>
          );
        })}
      </div>
    </VerifyModal>
  );
};

//Function to allow user to select a region for verifying the user

export const SelectRegion: React.FC<RegionProps> = ({
  handleSubmit,
  handleGoBack,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  const filteredCountries = countries.filter((country: any) =>
    country.name.toLowerCase().includes(searchQuery.toLowerCase())
  );
  return (
    <VerifyModal
      title={"Select Country"}
      goBack={true}
      handleGoBack={handleGoBack}
    >
      <>
        <Form2>
          <img src={Search} alt="" className="img-size" />
          <Input
            type="text"
            placeholder="search countries"
            className="input-padding"
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </Form2>
        <div>
          {searchQuery
            ? filteredCountries.map((country: any) => {
                return (
                  <BoxBorder onClick={() => handleSubmit(country.code)}>
                    <Text14 color="#25346A">{country.name}</Text14>
                  </BoxBorder>
                );
              })
            : countries.map((country: any) => {
                return (
                  <BoxBorder onClick={() => handleSubmit(country.code)}>
                    <Text14 color="#25346A">{country.name}</Text14>
                  </BoxBorder>
                );
              })}
        </div>
      </>
    </VerifyModal>
  );
};

export const CaptureDocument: React.FC<CaptureDocumentProps> = ({
  handleCloseModal,
  handleSubmit,
  idType,
}) => {
  const [image, setImage] = useState<string>("");
  const [fileName, setfileName] = useState<string>("");
  const [uploadedFile, uploadFile] = useState<File | null>();
  const [mediaStream, saveStream] = useState<MediaStream>();

  const startCamera = async () => {
    uploadFile(null)
    try {
      const startCameraFunction = openCamera();
      const stream = await startCameraFunction();
      // Do something with the stream, e.g., display it on the page
      const videoElement = document.getElementById(
        "videocamera"
      ) as HTMLVideoElement;
      saveStream(stream);
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const handleCaptureImage = () => {
    try {
      const takePictureFunction = takePicture(mediaStream as MediaStream);
      const imageDataUrl = takePictureFunction();
      setImage(imageDataUrl);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    uploadFile(selectedFile)
    if (selectedFile) {
      let blobUrl = URL.createObjectURL(selectedFile);
      setImage(blobUrl);
      setfileName(selectedFile?.name);
    }
  };

  useEffect(() => {
    if (!image) {
      startCamera();
    }
  }, [image]);

  return (
    <CaptureModal
      close={true}
      title={"Capture ID"}
      description={`Make sure your ${idCollection[idType - 1].name} is infront of the frame`}
      handleCloseModal={handleCloseModal}
    >
      <div>
        <Wrapper1>
          <WrapperInner>
            <WrapperInner2>
              <canvas
                id="captureImage"
                width="100%"
                height="100%"
                style={{ display: "none" }}
              />
              {image && (
                <img
                  src={image}
                  width="100%"
                  height="100%"
                  style={{ borderRadius: "20px" }}
                />
              )}
              {!image && (
                <video
                  style={{ borderRadius: "20px" }}
                  id="videocamera"
                  width="100%"
                  height="100%"
                  autoPlay
                ></video>
              )}
            </WrapperInner2>
          </WrapperInner>

          {!image && (
            <CaptureImageBtn onClick={handleCaptureImage}>
              <SmallCircle></SmallCircle>
            </CaptureImageBtn>
          )}
        </Wrapper1>

        {!image && (
          <>
            {" "}
            <CaptureImageBox>
              <CaptureImageInput
                type="file"
                name="file"
                id="idCard"
                onChange={handleChange}
                accept=".png, .jpeg, .jpg"
              />
              <CaptureLabel htmlFor="idCard">
                <div style={{ marginRight: "0.5rem" }}>
                  <PaperClip width={15} height={15} />
                </div>
                <Text12 color="#fff" fontWeight="600">
                  Upload scanned image here
                </Text12>
              </CaptureLabel>
            </CaptureImageBox>
            <Text12
              color="#c4c4c4"
              fontWeight="600"
              marginTop="0.75rem"
              style={{ textAlign: "center" }}
            >
              JPG or JPEG only. Max 2MB File size
            </Text12>
          </>
        )}
        {fileName && (
          <CaptureImageBox2>
            <PhotographIcon />
            <Text10 color="#25346a">{fileName.slice(0, 20) + "..."}</Text10>
          </CaptureImageBox2>
        )}
        {image && (
          <BtnGroup2>
            <Button
              type="button"
              text="Use this picture"
              paddingProps="0.5rem"
              onClick={() => handleSubmit(uploadedFile ? uploadedFile : image, uploadedFile? "UPLOAD" : "SNAPSHOT")}
            />
            <Button
              variant="outline"
              type="button"
              text="Retake Picture"
              paddingProps="0.5rem"
              onClick={() => setImage("")}
            />
          </BtnGroup2>
        )}
      </div>
    </CaptureModal>
  );
};

export const CaptureSelfie: React.FC<CaptureSelfieProps> = ({
  handleSubmit,
  handleCloseModal,
}) => {
  const [image, setImage] = useState<string>("");
  const [mediaStream, saveStream] = useState<MediaStream>();

  const startCamera = async () => {
    try {
      const startCameraFunction = openCamera();
      const stream = await startCameraFunction();
      // Do something with the stream, e.g., display it on the page
      const videoElement = document.getElementById(
        "videocamera"
      ) as HTMLVideoElement;
      saveStream(stream);
      videoElement.srcObject = stream;
    } catch (error) {
      console.error("Error opening camera:", error);
    }
  };

  const handleCaptureImage = () => {
    try {
      const takePictureFunction = takePicture(mediaStream as MediaStream);
      const imageDataUrl = takePictureFunction();

      setImage(imageDataUrl);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  };

  useEffect(() => {
    if (!image) {
      startCamera();
    }
  }, [image]);

  return (
    <CaptureModal
      close={true}
      title={"Capture Selfie"}
      description={`Make sure your driving licence is infront of the frame`}
      handleCloseModal={handleCloseModal}
    >
      <div>
        <Wrapper1>
          <WrapperInner>
            <WrapperInner2>
              <canvas
                id="captureImage"
                width="100%"
                height="100%"
                style={{ display: "none" }}
              />
              {image && <img src={image} width="100%" height="100%" />}
              {!image && (
                <video
                  style={{ borderRadius: "20px" }}
                  id="videocamera"
                  width="100%"
                  height="100%"
                  autoPlay
                ></video>
              )}
            </WrapperInner2>
          </WrapperInner>

          {!image && (
            <CaptureImageBtn onClick={handleCaptureImage}>
              <SmallCircle></SmallCircle>
            </CaptureImageBtn>
          )}
        </Wrapper1>

        {image && (
          <BtnGroup2>
            <Button
              type="button"
              text="Submit Selfie"
              onClick={() => handleSubmit(image)}
            />
            <Button
              variant="outline"
              type="button"
              text="Retake Selfie"
              onClick={() => setImage("")}
            />
          </BtnGroup2>
        )}
      </div>
    </CaptureModal>
  );
};

//Success Card
export const ConfirmApproval: React.FC<ConfirmProps> = ({
  errMsg,
  handleSubmit,
  handleCloseModal,
  isLoading,
}) => {
  return (
    <Modal handleCloseModal={handleCloseModal}>
      <TopBox>
        <CircleSpan background="#EEE1A9" width="4rem" height="4rem">
          <img src={TickSquare} alt="" width={35} height={35} />
        </CircleSpan>
        <TextBox>
          <TextTitle2 marginBottom="0.75rem" color="#25346A">
            Confirm Submission
          </TextTitle2>
          <Text400>
            Please keep in mind that presenting falsified documents is illegal
            and will be reported to the authorities.
          </Text400>
        </TextBox>
        <ErrorMessage errMsg={errMsg} />
        <BtnGroup flexDirection="column" style={{ width: "73%" }}>
          <Button
            type="button"
            text="Confirm"
            onClick={handleSubmit}
            isLoading={isLoading}
            disabled={isLoading}
            paddingProps="0.5rem"
          />
          <Button
            variant="outline"
            type="button"
            disabled={isLoading}
            text="Cancel"
            paddingProps="0.5rem"
            onClick={handleCloseModal}
          />
        </BtnGroup>
      </TopBox>
    </Modal>
  );
};
