import React, { useState } from "react";
import {
  useSendOTPMutation,
  useVerifyOTPMutation,
  useCreateUserMutation,
  useCreateBusinessMutation,
} from "../../features/api/authSlice";
import AuthSuccess from "./authSuccess";
import CreateAccount from "./createAccount";
import CreatePassword from "./createPassword";
import Success from "./success";
import TwoFA from "./twofa";
import TwoFAVerifcation from "./twofaverification";
import Verify from "./verify";

const Signup: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [businessName, setBusinessName] = useState<string>("");
  const [step, setStep] = useState<number>(0);
  const [errMsg, setError] = useState<string>("");

  const [sendOTP, { isLoading: sendingOTP }] = useSendOTPMutation();
  const [verifyOTP, { isLoading: verifyingOTP }] = useVerifyOTPMutation();
  const [createUser, { isLoading: creatingUser }] = useCreateUserMutation();
  const [createBusiness, {isLoading: creatingBusiness}] = useCreateBusinessMutation()

  const handleSubmit = () => {
    sendOTP({ email: email })
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(1);
        } else {
          setError(res?.Message);
        }
      })
      .catch((err) => {
        setError(err?.res?.Message);
      });
  };

  const handleVerify = (data: { token: string; email: string }) => {
    verifyOTP(data)
      .unwrap()
      .then((res) => {
        setError("")
        if (res) {
          setStep(2);
        }
      })
      .catch((err) => {
        setError(err?.data?.Message)
      });
  };

  const handleCreate = (password: string) => {
    let bodyFormData = new FormData();
    bodyFormData.append("FirstName", firstName);
    bodyFormData.append("LastName", lastName);
    bodyFormData.append("Email", email);
    bodyFormData.append("Password", password);
    bodyFormData.append("RoleId", "2");
    bodyFormData.append("PhoneNumber", "");
    bodyFormData.append("Photo", "");
    bodyFormData.append("CreateNetwork.NetworkName", "");
    bodyFormData.append("BusinessViewModel.BusinessName", businessName)
    createUser(bodyFormData)
      .unwrap()
      .then((res) => {
        if (res.success) {
          setStep(4);
        } else {
          setError(res?.Message)
        }
      })
      .catch((err) => {
        setError(err?.data?.Message)
      });
  };
  const renderComponent = () => {
    switch (step) {
      case 0:
        return (
          <CreateAccount
            createAccount={handleSubmit}
            err={errMsg}
            loading={sendingOTP}
            email={email}
            setEmail={setEmail}
            setFirstName={setFirstName}
            setLastName={setLastName}
            firstName={firstName}
            lastName={lastName}
               setBusinessName={setBusinessName}
               businessName={businessName}
          />
        );
      case 1:
        return (
          <Verify
            verifyOTP={handleVerify}
            loading={verifyingOTP}
            err={errMsg}
            email={email}
            goBack={setStep}
          />
        );
      case 2:
        return <Success handleProceed={setStep} email={email} />;
      case 3:
        return (
          <CreatePassword
            loading={creatingUser || creatingBusiness}
            createPassword={handleCreate}
            email={email}
            err={errMsg}
          />
        );
       case 4:
        return <TwoFA email={email} setStep={setStep} />;
    case 5:
          return <TwoFAVerifcation email={email} setStep={setStep} />
      case 6:
        return <AuthSuccess email={email} title="Account Created!" />;

      default:
        return;
    }
  };
  return <>{renderComponent()}</>;
};

export default Signup;
