import React, { useState } from "react";
import { BtnGroup } from "../Dashboard/Dashboard.styled";
import { Button } from "../Reusable/Buttons/button";
import {
  ErrorMsg,
  Form,
  Input,
  Label,
} from "../Authentication/styles/Container.styled";
import { BsEyeSlashFill, BsFillEyeFill } from "react-icons/bs";
import { AuthorizedAccessProps } from "./type";
import { AccessModal } from "../Reusable/Modal";

const AuthorizedAccess = ({
  handleCloseRole,
  handleDeleteAccount,
  errMsg,
  isLoading,
  password,
  setPassword,
}: AuthorizedAccessProps) => {
  const [visibilityStatus, toggleVisibility] = useState<boolean>(false);

  return (
    <AccessModal
      title="Deactivate Account"
      description="To completely wipe your data, press Delete. Your account will need to be created once again."
      close={true}
      handleCloseModal={handleCloseRole}
    >
      <div>
        <Form className="form-margin">
          <div className="formControlPassword">
            <Label htmlFor="password" textAlign="left">
              Enter Password
            </Label>
            <Input
              type={visibilityStatus ? "text" : "password"}
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              //placeholder="......"
            />
            <span
              onClick={() => toggleVisibility(!visibilityStatus)}
              className="eye"
            >
              {visibilityStatus ? (
                <BsFillEyeFill className="" />
              ) : (
                <BsEyeSlashFill className="" />
              )}
            </span>
          </div>
        </Form>
        {errMsg && <ErrorMsg> {errMsg}</ErrorMsg>}
        <BtnGroup>
          <Button text="Cancel" variant="outline" onClick={handleCloseRole} />
          <Button
            text="Delete"
            BGProps="#FF1E1E"
            isLoading={isLoading}
            onClick={handleDeleteAccount}
          />
        </BtnGroup>
      </div>
    </AccessModal>
  );
};

export default AuthorizedAccess;
