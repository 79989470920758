import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { AccountTitle } from "../Account/Account.styled";
import {
  BlueInfoBox,
  EachBox,
  FlexBox,
  Input11,
  Label14,
  SettingBox,
} from "./Campaign.styled";
import { Text12, Text400, TextTitle3 } from "../../styles/all.styled";
import { useGetCampaignQuery } from "../../features/api/campaignSlice";
import InfoCircle from "../../assets/icons/info-circle.svg";
import { Box22, TooltipContent } from "../Dashboard/Dashboard.styled";
import { TableBox } from "../Tables/Table.styled";


interface AdvertBudgetProps {
  autoBudget: boolean;
  setisAutoBudget: (val: boolean) => void;
  setMinimumBudget: (val: string) => void;
  minimumBid: string,
  setBid: (val: string) => void;
  myBid: string
}
const CreateAdvertBudget: React.FC<AdvertBudgetProps> = ({autoBudget, setisAutoBudget, setMinimumBudget, minimumBid, setBid, myBid}) => {

  const { campaignId } = useParams();
  const { data: campaignDetail } = useGetCampaignQuery(Number(campaignId) as number);

  const [isEffectiveBidVisible, setEffectiveBid] = useState(false);
  const [isMinimalBid, setMinimalBid] = useState(false);
  const [smyBid, setMyBid] = useState(false);

  return (
    <div>
      <AccountTitle>Bids</AccountTitle>
      <div style={{ textAlign: "left" }}>

      <TableBox>
        <table className="dx-table">
          <thead>
              <tr>
                  <th>
                    <div>
                      <span>Effective Bid</span>
                        <Box22
                          isTooltipVisible={isEffectiveBidVisible}
                          onMouseEnter={()=> setEffectiveBid(true)}
                          onMouseLeave={()=> setEffectiveBid(false)}
                          left="79.5%"
                          transform="translateX(-79.5%)"
                        >
                          <img src={InfoCircle} alt="More information" />
                          <div className="tooltip" style={{ width: "20rem" }}>
                            <TooltipContent left="50%" transform="translateX(-20%)">
                              This is the most effective bid for your campaign
                            </TooltipContent>
                          </div>
                        </Box22>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span>Minimal Bid</span>
                        <Box22
                          isTooltipVisible={isMinimalBid}
                          onMouseEnter={()=> setMinimalBid(true)}
                          onMouseLeave={()=> setMinimalBid(false)}
                          left="79.5%"
                          transform="translateX(-79.5%)"
                        >
                          <img src={InfoCircle} alt="More information" />
                          <div className="tooltip" style={{ width: "20rem" }}>
                            <TooltipContent left="50%" transform="translateX(-20%)">
                              This is the minimum amount of bids that you can set per 100 impressions.
                            </TooltipContent>
                          </div>
                        </Box22>
                    </div>
                  </th>
                  <th>
                    <div>
                      <span> My Bid </span>
                      <Box22
                          isTooltipVisible={smyBid}
                          onMouseEnter={()=> setMyBid(true)}
                          onMouseLeave={()=> setMyBid(false)}
                          left="79.5%"
                          transform="translateX(-79.5%)"
                        >
                          <img src={InfoCircle} alt="More information" />
                          <div className="tooltip" style={{ width: "20rem" }}>
                            <TooltipContent left="50%" transform="translateX(-20%)">
                              This is the value that you can set per 100 impressions.
                            </TooltipContent>
                          </div>
                        </Box22>
                    </div>
                  </th>
                
              </tr>
          </thead>
          <tbody> 
           <tr> 
             <td>₦{campaignDetail?.data?.default_advert_bid?.effective_bid} </td>
             <td>₦{campaignDetail?.data?.default_advert_bid?.minimal_bid} </td>
             <td className="relative" >
             <span className="absolute budgetCurrency"> ₦</span>
             <input
                style={{
                  borderRadius: "26px",
                  background: "#F5F5F5",
                  display: "flex",
                  border: "none",
                  padding: "0.3rem 1rem",
                  textAlign: "center",
                  paddingLeft:"20px"
                }}
                value={myBid}
                onChange={(e)=> setBid(e.target.value)}
              />
             </td>
           </tr>
          </tbody>
        </table>
        </TableBox>
      </div>
      <EachBox>
        <TextTitle3>Set your Daily Budget</TextTitle3>
      </EachBox>
      <BlueInfoBox>
        <Text12 color="#25346A">
          The Wicrypt advertising system will run your advertisement as
          frequently as it can while adhering to your Daily Ad Budget.
        </Text12>
        <Text12 color="#25346A">
          Your advertisement will stop running for the day if the limit is
          reached.
        </Text12>
      </BlueInfoBox>
      <SettingBox>
        <Text400>Settings</Text400>
        <FlexBox justifyContent="flex-start" gap="1rem">
          <input type="radio" name="daily limit" id=""  onClick={()=> setisAutoBudget(true)} checked={autoBudget}/>
          <Label14 fontSize="1rem" htmlFor="">
            No daily Limits
          </Label14>
        </FlexBox>
        <FlexBox justifyContent="flex-start" gap="1rem">
          <input type="radio" name="" id=""  onClick={()=> setisAutoBudget(false)} checked={!autoBudget && true} />
          <Label14 fontSize="1rem" htmlFor="">
            Set Daily Limit Budget{" "}
          </Label14>
          <Label14 as={"p"}>₦</Label14>
          <Input11 type="number" value={minimumBid} onChange={(e)=> setMinimumBudget(e.target.value)}/>
          {/* <Label14 as={"p"}>($ 5 Minimum)</Label14> */}
        </FlexBox>
      </SettingBox>
    </div>
  );
};

export default CreateAdvertBudget;
