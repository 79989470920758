import { ReactComponent as GraphIcon } from "../assets/icons/HomeIcons/graph.svg";
import { ReactComponent as ProfileIcon } from "../assets/icons/HomeIcons/profile.svg";
import { ReactComponent as SpeakerIcon } from "../assets/icons/HomeIcons/speaker.svg";
import {ReactComponent as WalletIcon} from "../assets/icons/HomeIcons/wallet.svg";

export const SIDEBAR_DATA = [
  {
    icon: <GraphIcon />,
    title: "Dashboard",
    to: "/home/dashboard",
    path: "dashboard",
  },
  {
    icon: <SpeakerIcon />,
    title: "Campaign",
    to: "/home/campaign",
    path: "campaign",
  },
  {
    icon: <WalletIcon/>,
    title: "Wallet",
    to: "/home/wallet",
     path: "wallet"
  },
  {
    icon: <ProfileIcon />,
    title: "My Account",
    to: "/home/account",
    path: "account",
  },
];
