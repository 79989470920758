import React from "react";
import BgAdvert from "../../assets/images/Frame.png";
import { Button } from "../Reusable/Buttons/button";
import { PendBox, TextCenter } from "./Campaign.styled";
import { Text14 } from "../../styles/all.styled";
import { useNavigate, useParams } from "react-router-dom";

const AdEmpty: React.FC = () => {
  const { campaignId } = useParams();
  const navigate = useNavigate();

  return (
    <PendBox>
      <img
        src={BgAdvert}
        alt=""
        className="bg-ad-img"
        width={253}
        height={128}
      />
      <TextCenter>
        <Text14 fontSize="1.25rem">Start an Advert</Text14>
        <Text14 color="#6b6b6b">
          This campaign curerntly has no advert, you can create a new advert by
          clicking on the button below to start
        </Text14>
        <div className="btn-width">
          <Button
            text="Create Advert"
            onClick={() =>
              navigate(`/home/campaign/${campaignId}/create-advert`)
            }
          />
        </div>
      </TextCenter>
    </PendBox>
  );
};

export default AdEmpty;
