import React, { useState, useRef, useEffect } from "react";
import { SmallBox, Box3, SideBox, Box3Main, Section } from "./Dashboard.styled";
import Filter from "../../assets/icons/Wallet/filter.svg";
import OverviewInfo from "./OverviewInfo";
import useOutsideClick from "../../hooks/useOutsideClick";
import {
  useGetDashboardOverviewQuery,
  useLazyFirstLoginQuery,
} from "../../features/api/dashboardSlice";
import Welcome from "./Welcome";
import { Box5, Input2, Label2, SelectWrapper } from "../Wallet/Wallet.styled";
import Monthly from "./Monthly";
import { Margin } from "../Campaign/Campaign.styled";
import { FilterGroupCampaign } from "./FilterGroupCamp";
import { UserDetail } from "../../features/api/authTypes";
import EmptyBox from "../Reusable/EmptyBox";
import { viewPeriod } from "../Campaign/helper";

const Dashboard: React.FC = () => {
  const [showRole, setShowRole] = useState(false);
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);
  const [userDetail, setUser] = useState<UserDetail>();
  const [period, setPeriod] = useState<string>("1");

  const { data: overview, isLoading: loadingOverview } =
    useGetDashboardOverviewQuery(period);
  const [closeVideo] = useLazyFirstLoginQuery();

  const modalRef = useRef<HTMLElement>(null);

  const handleRole = () => {
    setShowRole(true);
  };

  const handleCloseRole = () => {
    closeVideo()
      .unwrap()
      .then((res) => {
        setShowWelcomeModal(false);
      })
      .catch((err) => {
        console.log(err, "i am the err message ");
      });
  };

  const closeFilter = () =>{
    setShowRole(false);
  }

  useOutsideClick(modalRef, handleCloseRole);
  useOutsideClick(modalRef, closeFilter);

  const OVERVIEW_DATA = [
    {
      title: "Total Campaign",
      value: overview?.data?.total_campaigns,
      info: "Total number of campaigns ongoing",
    },
    {
      title: "Active Campaigns",
      value: overview?.data?.total_campaigns,
      info: "Total number of active campaigns",
    },
    {
      title: "Total Adverts",
      value: overview?.data?.total_adverts,
      info: "Total number of adverts ongoing",
    },
    {
      title: "Total Views",
      value: overview?.data?.total_views,
      info: "Total number of views",
    },
  ];

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setShowWelcomeModal(userDetail?.first_login as boolean);
    setUser(user);
  }, []);

  return (
    <>
      {/* {showWelcomeModal && (
        <Welcome handleCloseRole={handleCloseRole} showRole />
      )} */}
      <Section>
        <SideBox>
          <SmallBox>
            <h3 className="title">Overview</h3>
            <Box3Main>
              {OVERVIEW_DATA.map((item, index) => {
                return (
                  <OverviewInfo
                    isLoading={loadingOverview}
                    key={index}
                    {...item}
                    handleRole={handleRole}
                  />
                );
              })}
            </Box3Main>
          </SmallBox>
          <Box3>
            <h3>Average Views</h3>
            <Margin margin="1.5rem 0">
              <Box5>
                <Label2 htmlFor="filter">Filter</Label2>
                <SelectWrapper onClick={handleRole}>
                  <Input2
                    type="text"
                    id="search"
                    placeholder={viewPeriod[Number(period)].period as string || "All"}
                    inputWidth="100%"
                    disabled
                  />
                  <button className="select-btn" >
                    <img src={Filter} alt="" className="select-img-filter" />
                  </button>
                </SelectWrapper>
              </Box5>
            </Margin>
          </Box3>
          {(overview?.data?.average_views.length || 0) > 0 ? <Monthly data={overview?.data?.average_views || []} /> : 
          <EmptyBox message={"No views within this duration"} />}
        </SideBox>
        {showRole && (
          <FilterGroupCampaign
            handleCloseRole={closeFilter}
            showRole={showRole}
            applyFilter={setPeriod}
          />
        )}
      </Section>
    </>
  );
};

export default Dashboard;
