import { createColumnHelper } from "@tanstack/react-table";
import Warning from "../../assets/icons/info-circle.svg";
import { CellRenderer } from "../Campaign/CampaignModals";
import moment from "moment";
import { AdNetworks, Network } from "../../features/api/campaignSliceTypes";
import { numberWithCommasWithoutDecimal, StatusCodes } from "../Campaign/helper";

type Person = {
  Date?: string;
  Advert?: string;
  Reference?: string;
  Amount?: string;
  FundingMethod?: string;
  More?: string;
  Countries?: number;
  Effective?: string;
  Minimal?: string;
  MyBid?: string;
  Action?: string;
  Network?: string;
  Bid?: string;
  Budget?: string;
  Expense?: string;
  status?: string;
  is_enabled?: boolean;
  created_at?: Date;
  internal_ref?: any;
  budget_daily_cap?: number;
  amount?: number;
  advert_name?: number;
  advert?: string;
  name?: string;
  advert_networks?: any;
  total_spent?: number;
  id?: any;
};


const columnHelper = createColumnHelper<Person>();

export const columnsEarnings = [
  columnHelper.accessor("created_at", {
    cell: (val) => {
      const createdAt = val.getValue();
      return (
        <p>
          {" "}
          {moment((createdAt as Date) || new Date()).format(
            "DD MMM YYYY, hh:mm"
          )}
        </p>
      );
    },
  }),
  columnHelper.accessor((row) => row.Advert, {
    id: "advert",
    cell: (info) => <span>{info.getValue() || "N/A"}</span>,
    header: () => <span>Advert</span>,
  }),
  columnHelper.accessor("internal_ref", {
    header: "Reference",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    cell: (val) => {
      const amount = val.getValue() as number;
      return (
        <p>
          {"₦"}
          {numberWithCommasWithoutDecimal(amount || 0)}
        </p>
      );
    },
  }),
];


export const columnsWithdraws = [
  columnHelper.accessor("created_at", {
    cell: (val) => {
      const createdAt = val.getValue();
      return (
        <p>
          {" "}
          {moment((createdAt as Date) || new Date()).format(
            "DD MMM YYYY, hh:mm"
          )}
        </p>
      );
    },
  }),
  columnHelper.accessor("advert_name", {
    header: "Advert",
    cell: (val) => {
      const advertName = val.getValue() as number;
      return (
        <span> {advertName || 'N/A' }</span>
      );
    },
  }),
  columnHelper.accessor("internal_ref", {
    header: "Reference",
  }),
  columnHelper.accessor("amount", {
    header: "Amount",
    cell: (val) => {
      const amount = val.getValue() as number;
      return (
        <p>
          {"₦"}
          {numberWithCommasWithoutDecimal(amount || 0)}
        </p>
      );
    },
  }),
];

// Bids

export const defaultDataBids: Person[] = [
  {
    Effective: "$20",
    Minimal: "$5",
    MyBid: "$20",
  },
];

export const columnsBids = [
  columnHelper.accessor((row) => row.Effective, {
    id: "Effective",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => (
      <div>
        <span>Effective Bid</span>
        <img src={Warning} alt="" />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.Minimal, {
    id: "Minimal",
    cell: (info) => <span>{info.getValue()}</span>,
    header: () => (
      <div>
        <span>Minimal Bid</span>
        <img src={Warning} alt="" />
      </div>
    ),
  }),
  columnHelper.accessor((row) => row.MyBid, {
    id: "MyBid",
    cell: (info) => (
      <input onChange={(e) => console.log(e.target.value, "i am the value")} />
    ),
    header: () => (
      <div>
        <span>My Bid</span>
        <img src={Warning} alt="" />
      </div>
    ),
  }),
];

export const columnsCampaignAd = [
  columnHelper.accessor("created_at", {
    header: "Date & time created",
    cell: (val) => {
      const createdAt = val.getValue();
      return (
        <p>
          {" "}
          {moment((createdAt as Date) || new Date()).format(
            "DD MMM YYYY, hh:mm"
          )}
        </p>
      );
    },
  }),
  columnHelper.accessor("name", {
    header: "Advert Name",
  }),
  columnHelper.accessor("advert_networks", {
    header: "Network",
    cell: (val) => {
      const networks = val.getValue() as AdNetworks[];
      return <div> {networks.length}</div>;
    },
  }),
  // columnHelper.accessor("bid_cap_per1k", {
  //   header: "Bid Cost per 1K",
  // }),
  columnHelper.accessor("budget_daily_cap", {
    header: "Daily Budget",
  }),
  columnHelper.accessor("total_spent", {
    header: "Expense",
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: (val) => {
      const status = val.getValue() as string;
      return <div className={StatusCodes[status].toLowerCase()}> {StatusCodes[status]}</div>;
    },
  }),
  columnHelper.accessor("id", {
    header: "More",
    cell: (val) => {
      const adId = val.getValue() as string;
      let status = (val.row?.original.status || "1") as string;
      return <CellRenderer 
                id={adId} 
                isEnabled={val.row?.original?.is_enabled} 
                canPause={!["2", "3", "6", "7", "10" ].includes(status)}
              />;
    },
  }),
];
