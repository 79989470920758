import React, { useRef, useState } from "react";
// import { MainBox } from "../Account/Account.styled";
import {
  Box5,
  Form2,
  Input2,
  Label2,
  // SelectWrapper,
  // Walletbtn,
} from "./Wallet.styled";
import TableMain from "../Tables/TableMain";
import { columnsEarnings } from "../Tables/TableData";
// import Filter from "../../assets/icons/Wallet/filter.svg";
// import useOutsideClick from "../../hooks/useOutsideClick";
// import { TabComponent } from "../../types";
import { useGetTransactionsQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import EmptyBox from "../Reusable/EmptyBox";

const Expenses = () => {

  const [walletReference, searchByReference] = useState<string>("");

  const { data: earningTransactions, isLoading, isFetching } = useGetTransactionsQuery({
    status: "",
    transactionType: "2",
    search: walletReference
  });


  const greenColor = "#00C087";

  return (
    <div>
      <Form2>
        {/* <Box5>
          <Label2 htmlFor="filter">Filter</Label2>
          <SelectWrapper>
            <Input2
              type="text"
              id="search"
              placeholder="All"
              inputWidth="100%"
            />
            <ButtonReset className="select-btn" onClick={handleRole}>
              <img src={Filter} alt="" className="select-img-filter" />
            </ButtonReset>
          </SelectWrapper>
        </Box5> */}
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            placeholder="Reference"
            inputWidth="100%"
            onChange={(e)=> searchByReference(e.target.value)}
          />
        </Box5>
      </Form2>
      {isLoading || isFetching ? <CardLoader /> :
      <div style={{ textAlign: "center" }}>
        <TableMain
          defaultData={earningTransactions?.data || []}
          columns={columnsEarnings}
          greenColor={greenColor}
        />
      </div>}
      {earningTransactions?.data.length === 0 && 
      <EmptyBox message={walletReference ? "Sorry, this reference does not exist" : "Make an expense to see your history here"} />}
      {/* <Walletbtn>
        <button>Load more</button>
      </Walletbtn> */}
    </div>
  );
};

export default Expenses;
