import React from "react";
import { ReactComponent as Logo } from "../../assets/icons/wicrypt.svg";
import { ReactComponent as Success } from "../../assets/icons/success.svg";
import {
  Description,
  ContainerVerify,
  SuccessBox,
  InnerContainer,
} from "./styles/Container.styled";
import { useNavigate } from "react-router-dom";
import { Button } from "../Reusable/Buttons/button";

interface ISuccsess {
  email: string;
  title: string;
}
const AuthSuccess: React.FC<ISuccsess> = ({ email, title }) => {
  const navigate = useNavigate();
  return (
    <ContainerVerify className="justify">
      <InnerContainer alignItems gap="1.875rem">
        <div className="logo-box">
          <Logo className="logo" />
        </div>
        <div className="logo-box">
          <Success />
        </div>
        <SuccessBox>
          <Description className="success__para">
          {title && title.toLowerCase() ? "Use an authentication app" : title}
          </Description>
         {title.toLowerCase() === "account created!"?  
         <Description className="success__para2" marginBottom="1rem">
            Starting now, each time you log into your account, 
            you must input your password along with an authentication 
            code generated by Google Authenticator.
          </Description> : 
           <Description className="success__para2" marginBottom="1rem">
           Please Log in to continue
         </Description>}

        </SuccessBox>
        {/* <Link to={`/`} className="cursor-pointer">
          Log In
        </Link> */}

        <Button onClick={() => navigate("/")} text="Done" height="40px" />
      </InnerContainer>
    </ContainerVerify>
  );
};

export default AuthSuccess;
