import styled from "styled-components";
import {
  COLOR_BORDER_2,
  COLOR_BORDER_GRAY,
  COLOR_H4,
  COLOR_LABEL,
  COLOR_LIGHT_GRAY,
  COLOR_PARA1,
  COLOR_WHITE,
} from "../../styles/themes/colors";
import { BOX_SHADOW_WALLET } from "../../styles/themes/box-shadow";
import { SelectProps } from "../../types";

interface CountryBoxProps {
  border?: string;
  borderRaduis?: string;
  width?: string;
}

export const WalletOuterBox = styled.div`
  display: flex;
  column-gap: 1.25rem;
  flex-wrap: wrap;
`;

export const WalletBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  width: 33.75rem;
  background: ${COLOR_WHITE};
  border: 1px solid ${COLOR_BORDER_GRAY};
  box-shadow: ${BOX_SHADOW_WALLET};
  border-radius: 1.25rem;
  padding: 2.18rem 1.85rem;
  margin-bottom: 2.5rem;

  @media (max-width: 1200px) {
    width: 28.75rem;
  }
`;

export const InnerWalletBox = styled.div`
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.1px;
  h4 {
    font-size: 1rem;
    font-weight: inherit;
    line-height: 1.5;
    color: ${COLOR_H4};
    margin-bottom: 1.5rem;
  }
  p {
    font-size: 2rem;
    color: ${COLOR_PARA1};
  }
`;

export const WalletSelect = styled.div`
  display: flex;
  align-items: center;
  gap: 2.5rem;
  border: 1px solid ${COLOR_BORDER_2};
  border-radius: 1.6rem;
  padding: 0.625rem 1.06rem;

  img {
    width: 1.25rem;
    height: 1.25rem;
    transform: rotate(90deg);
  }

  button {
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.5;
    color: ${COLOR_LIGHT_GRAY};
  }

  @media (min-width: 764px) {
    gap: 5rem;
  }
`;

export const Label2 = styled.label`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.5;
  color: ${COLOR_LABEL};
`;

export const Input2 = styled.input<SelectProps>`
  border: 1px solid ${COLOR_BORDER_2};
  border-radius: 26px;
  width: ${(props) => (!props.inputWidth ? "25%" : props.inputWidth)};
  padding: 0.625rem 1.06rem;
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: 0.875rem;

  &::placeholder {
    font-size: 0.75rem;
  }
`;

export const Walletbtn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background: rgba(229, 185, 16, 0.1);
    border-radius: 26px;
    padding: 0.25rem 1rem;
    font-weight: 500;
    font-size: 0.688rem;
    line-height: 1.4;
    color: #e5b910;
    border: none;
  }
`;

export const Box5 = styled.div<CountryBoxProps>`
  display: flex;
  flex-direction: column;
  width: 70%;

  @media (min-width: 390px) {
    width: ${(props) => props.width || "25%"};
  }

  .select-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    right: 5%;
    top: 38%;
    @media (min-width: 390px) {
      width: auto;
      right: 10px;
    }
  }

  .select-img {
    transform: rotate(90deg);
  }
`;

export const SelectWrapper = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  cursor: pointer;
  @media (min-width: 390px) {
    width: auto;
  }
`;
export const Box6 = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  .select-img {
    transform: rotate(90deg);
  }
`;

export const Box8 = styled.div<SelectProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  border: 1px solid ${COLOR_BORDER_2};
  border-radius: 26px;
  width: 100%;
  padding: 0.625rem 1.06rem;
  margin-top: 0.625rem;
  font-weight: 500;
  font-size: 0.75rem;
  color: #333;
  cursor: pointer;
`;

export const Form2 = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;

  .img-size {
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
  }

  .input-padding {
    padding: 0 2.5rem 0.875rem;
  }
  @media (min-width: 390px) {
    flex-direction: row;
  }
`;

export const WalletForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const WalletInnerBox = styled.div`
  button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1.5rem 1.25rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    background-color: transparent;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }

  p {
    color: var(--black-87, rgba(0, 0, 0, 0.87));
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 20.16px */
  }
`;

export const WalletDescription = styled.div`
  width: 399px;
  height: 83px;
  padding: 20px;
  gap: 16px;
  border-bottom: 0.5px solid rgba(239, 239, 239, 0.50);
  background-color: var(--secondary-dark-grey, #6B6B6B);
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  background-color: #FBFBFB;

  .title{
    color: var(--secondary-dark-grey, #6B6B6B);
    font-family: Poppins;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 144%; /* 15.84px */
    text-transform: uppercase;
  }
  .descr{
    color: var(--secondary-black, #1A1A1A);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.37px;
  }
`

export const MarginBottom = styled.div`
  margin-top: auto;
  width: 40%;
`;

export const FundDescription = styled.p`
  color: var(--secondary-dark-grey, #6B6B6B);
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 17.28px */
`

export const Text3 = styled.h3`
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.2;
  color: #6b6b6b;
  margin-bottom: 1.875rem;

  .bank-transfer {
    color: #25346a;
  }
`;

export const Form27 = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  width: 100%;
  @media (min-width: 390px) {
    width: 27.6rem;
  }
  .accountName {
    font-weight: 500;
    font-size: 11px;
    line-height: 1.4;
    color: #318d1f;
    margin-bottom: 0.9rem;
  }
  .flex {
    display: flex;
    align-items: center;
    gap: 0.66rem;

    label {
      margin-bottom: 0;
    }
  }

  .fee {
    color: #696969;
    span {
      color: #318d1f;
    }
  }
`;

export const FormBox = styled.div`
  position: relative;
  .balance-btn {
    position: absolute;
    right: 1%;
    bottom: 18%;
    background: rgba(37, 52, 106, 0.1);
    border-radius: 26px;
    padding: 0.25rem 1rem;
    border: none;
  }
  .balance-user {
    position: absolute;
    right: 1%;
    bottom: 18%;
  }
`;

export const CountryBox = styled.div<CountryBoxProps>`
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: rgb(255, 255, 255);
  border: ${(props) => props.border || "1px solid rgba(206, 206, 206, 0.25)"};
  border-radius: ${(props) => props.borderRaduis || "0.5rem"};
  width: 100%;
  cursor: pointer;
  :not(:last-child) {
    margin-bottom: 1.25rem;
  }

  .flex {
    display: flex;
    align-items: center;
    gap: 1rem;

    .title {
      font-size: 0.875rem;
      line-height: 1.4;
      color: rgba(0, 0, 0, 0.87);
      font-weight: 400;
    }
  }

  img {
    width: 1.5rem;
    height: 1.5rem;
  }
`;

export const Instructions = styled.div`
  display: flex;
  width: 399px;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
  border-radius: 12px;
  border: 0.5px solid var(--supporting-blue, #2F80ED);
  background: var(--colors-bg-2-bg-250, #EAF1FF);
  margin-top: 20px;
  color: var(--primary-blue, #25346A);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 144%; /* 17.28px */
  p{
    color: var(--primary-blue, #25346A);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 144%; /* 20.16px */
    margin-top: 0px;
    svg{
      cursor: pointer;
    }
  }
`