import React from "react";
import {
  GridBox,
  ProgressBarContainer,
  ProgressBarFill,
  ProgressBarWrapper,
} from "./Campaign.styled";
import { Text10 } from "../../styles/all.styled";

interface ProgressBarProps {
  title: string;
  percentage: number;
  remaining: string;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  title,
  percentage,
  remaining,
}) => {
  return (
    <ProgressBarContainer>
      <Text10 color="#C4C4C4">{title}</Text10>
      <GridBox>
        <ProgressBarWrapper>
          <ProgressBarFill percentage={percentage} />
        </ProgressBarWrapper>

        <Text10 color="#C4C4C4">{remaining}</Text10>
      </GridBox>
    </ProgressBarContainer>
  );
};
