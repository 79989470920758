
import { apiSlice } from "./apiSlice";
import { AllCountriesResponse, BusinessTransactionresponse, BusinessTransactions, ExpenseDetail, FundingDetailResponse, InitiateCardPaymentRequest, InitiateCardPaymentResponse, WalletDetail } from "./walletSliceTypes";

const walletSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        //Get countries
        getCountries: build.query<AllCountriesResponse, void>({
            query: () => `/countries`,
        }),

         //Get bank accounts
        getBankAccounts: build.query<AllCountriesResponse, void>({
            query: () => `/bank-accounts`,
        }),

        //Get transactions
        getTransactions: build.query<BusinessTransactionresponse, BusinessTransactions>({
            query: ({status, transactionType, search}) => `/transactions/business?status=${status}&transactionType=${transactionType}&search=${search}`,
        }),

        //Get Wallet balance
        getWalletBalance:  build.query<WalletDetail, void>({
            query: () => `/businesses/user`,
        }),

        //Get expenses
        getExpense:  build.query<ExpenseDetail, void>({
            query: () => `/transactions/business/expense`,
        }),
        

        //Initiate Card Payment
        initiateCardPayment: build.mutation<InitiateCardPaymentResponse, InitiateCardPaymentRequest>({
            query: (payload) => ({
                url: `/transactions/cardPayment`,
                method: 'POST',
                body: payload
            }),
        }),

         //Funding Details
        getFundingDetails:  build.query<FundingDetailResponse, void>({
            query: () => `/transactions/funding-details`,
        }),

    }),
    overrideExisting: true,
})


export const { useGetCountriesQuery, useGetBankAccountsQuery, 
    useGetTransactionsQuery, useGetExpenseQuery,
    useGetWalletBalanceQuery, useInitiateCardPaymentMutation, useGetFundingDetailsQuery } = walletSlice