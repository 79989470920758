import React, { ReactElement, ReactNode, useRef } from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import { ReactComponent as CloseIcon } from "../../assets/icons/close-remove.svg";
import { ModalBox, ModalInnerBox } from "../Verification/Verification.styled";
import { CloseBtn, CloseBtn2 } from "./Reuseable.styled";
import { FlexBox, MarginBottom } from "../Campaign/Campaign.styled";
import { Text10, Text12 } from "../../styles/all.styled";
import { ButtonReset } from "./Buttons/button";
import { ReactComponent as ArrowLeft } from "../../assets/icons/Arrow-Left.svg";
import {
  MainContainer2,
  ModalContainer,
  ModalTitle,
  RoleBoxMain2,
  RoleInnerBox,
} from "../Dashboard/Dashboard.styled";
import { BackArrow } from "../Authentication/styles/Container.styled";

interface ModalProps {
  handleCloseModal: () => void;
  handleGoBack?: () => void;
  goBack?: boolean;
  children: ReactElement;
  title?: string;
  close?: boolean;
  description?: string;
}

interface VerifyModalProps {
  handleGoBack: () => void;
  goBack?: boolean;
  children: ReactElement;
  title?: string;
  description?: string;
}

export const Modal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ModalBox ref={modalRef}>
      <ModalInnerBox>
        <FlexBox alignItems="start">
          {title && (
            <MarginBottom>
              <Text12
                as={"h1"}
                fontSize="1rem"
                fontWeight="600"
                marginBottom="0.5rem"
              >
                {title}
              </Text12>
              <Text10>{description}</Text10>
            </MarginBottom>
          )}

          {close && (
            <CloseBtn onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} />
            </CloseBtn>
          )}
        </FlexBox>
        {children}
      </ModalInnerBox>
    </ModalBox>
  );
};

export const CaptureModal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ModalBox ref={modalRef} backgroundColor="#0000001F">
      <ModalInnerBox>
        <FlexBox alignItems="start">
          {title && (
            <MarginBottom>
              <Text12
                as={"h1"}
                color="#fff"
                fontSize="1.25rem"
                fontWeight="600"
                marginBottom="0.5rem"
              >
                {title}
              </Text12>
              <Text10 color="#c4c4c4" fontSize="1.125rem">
                {description}
              </Text10>
            </MarginBottom>
          )}

          {close && (
            <CloseBtn onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} className="close-btn" />
            </CloseBtn>
          )}
        </FlexBox>
        {children}
      </ModalInnerBox>
    </ModalBox>
  );
};

export const VerifyModal = ({
  children,
  title,
  description,
  goBack,
  handleGoBack,
}: VerifyModalProps) => {
  const modalRef = useRef<HTMLElement>(null);
  useOutsideClick(modalRef, handleGoBack);

  return (
    <MainContainer2 ref={modalRef}>
      <ModalContainer gapSpace="1.25rem">
        <BackArrow>
          <div className="back-wallet">
            {goBack && (
              <ButtonReset onClick={handleGoBack}>
                <ArrowLeft className="arrow-left" />
              </ButtonReset>
            )}
            {title && <ModalTitle>{title}</ModalTitle>}
          </div>
        </BackArrow>
        {description && (
          <Text12 fontSize="0.875rem" color="#000000DE" marginBottom="-0.5rem">
            {description}
          </Text12>
        )}

        {children}
      </ModalContainer>
    </MainContainer2>
  );
};

export const AccessModal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <RoleBoxMain2 ref={modalRef}>
      <RoleInnerBox widthBox="32.75rem">
        {close && (
          <FlexBox justifyContent="flex-end">
            <CloseBtn onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} className="close-btn" />
            </CloseBtn>
          </FlexBox>
        )}
        {title && (
          <MarginBottom>
            <Text12
              as={"h2"}
              color="#1a1a1a"
              fontSize="1.375rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {title}
            </Text12>
            <Text10 color="#6b6b6b" fontSize="0.875rem" lineHeight="1.5">
              {description}
            </Text10>
          </MarginBottom>
        )}

        {children}
      </RoleInnerBox>
    </RoleBoxMain2>
  );
};

export const BusinessModal = ({
  handleCloseModal,
  children,
  title,
  close,
  description,
}: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <RoleBoxMain2 ref={modalRef}>
      <RoleInnerBox widthBox="30.75rem" padding="1.5rem 2.5rem">
        {close && (
          <FlexBox justifyContent="flex-end">
            <CloseBtn2 onClick={handleCloseModal}>
              <CloseIcon width={13} height={13} className="close-btn" />
            </CloseBtn2>
          </FlexBox>
        )}
        {title && (
          <MarginBottom>
            <Text12
              as={"h2"}
              color="#1a1a1a"
              fontSize="1.375rem"
              fontWeight="600"
              marginBottom="0.5rem"
            >
              {title}
            </Text12>
            <Text10 color="#6b6b6b" fontSize="0.875rem" lineHeight="1.5">
              {description}
            </Text10>
          </MarginBottom>
        )}

        {children}
      </RoleInnerBox>
    </RoleBoxMain2>
  );
};

export const ConfirmModal = ({ handleCloseModal, children }: ModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  useOutsideClick(modalRef, handleCloseModal);

  return (
    <ModalBox ref={modalRef}>
      <ModalInnerBox>{children}</ModalInnerBox>
    </ModalBox>
  );
};
