import React from "react";
import { RoleInnerBox, RoleBoxMain } from "../Dashboard/Dashboard.styled";
import { FlexBox } from "../Authentication/styles/Container.styled";
import { ReactComponent as Arrow } from "../../assets/icons/arrow.svg";
import Ad1 from "../../assets/images/ad1.png";
import PicAd from "../../assets/images/Rectangle 83.png";
import { Text400, ADBox2, ImageAD } from "../../styles/all.styled";
import { ImageGalleryProps } from "../../types";

const ImageGallery: React.FC<ImageGalleryProps> = ({
  showRole,
  handleCloseRole,
  modalRef,
  imagesAdvert,
  selectedImage,
  handlePreviousImage,
  handleNextImage,
}) => {
  return (
    <RoleBoxMain showRoleModal={showRole} ref={modalRef}>
      <RoleInnerBox widthBox="80%" background="#1a1a1a">
        <ADBox2>
          <Text400 color="white">Preview</Text400>
          <button className="cancelbtn" onClick={handleCloseRole}>
            x
          </button>
        </ADBox2>
        <FlexBox
          flexDirection
          gap="1rem"
          style={{ position: "relative", marginBottom: "42px" }}
        >
          <button onClick={handlePreviousImage} className="carolbtn rotate">
            <Arrow />
          </button>

          <ImageAD
            src={selectedImage}
            alt="Selected Image"
            className="selected-image"
            width="346.84px"
            height="407px"
          />
          <button onClick={handleNextImage} className="carolbtn">
            <Arrow />
          </button>
        </FlexBox>
        <div style={{ marginTop: "1.5rem" }}>
          <FlexBox flexDirection gap="1.5rem">
            <ImageAD src={Ad1} alt="" />

            <ImageAD src={PicAd} alt="" />
          </FlexBox>
        </div>
      </RoleInnerBox>
    </RoleBoxMain>
  );
};

export default ImageGallery;
