import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CampaignEmpty from "./CampaignEmpty";
import { Form } from "../Authentication/styles/Container.styled";
import { Input2, Label2 } from "../Wallet/Wallet.styled";
import {
  AddCampBox,
  CampNameBox,
  FlexBox,
  GridContainer,
  ImgBox,
} from "./Campaign.styled";
import AddCampaign from "../../assets/icons/add-campaign.svg";
import Speaker from "../../assets/icons/speaker-megaphone.svg";
import InfoIcon from "../../assets/icons/info-icon.svg";
import { ProgressBar } from "./ProgressBar";
import useOutsideClick from "../../hooks/useOutsideClick";
import { CreateCampaignModal, DeleteCampaignModal, Duration } from "./CampaignModals";
import { Section } from "../Dashboard/Dashboard.styled";
import { Text10, Text14 } from "../../styles/all.styled";
import { useGetCampaignsQuery, useDeleteCampaignMutation } from "../../features/api/campaignSlice";
import { getTimeLeft, progressDuration } from "./helper";
import { CardLoader } from "../Reusable/CardLoader";
import { ButtonReset } from "../Reusable/Buttons/button";
import { Relative } from "../Account/Account.styled";
import toast from "react-hot-toast";

const Campaign: React.FC = () => {
  const navigate = useNavigate();

  const [isCreateCampaign, setIsCreateCampaign] = useState<boolean>(false);
  const [isDeleteCampaign, setIsDeleteCampaign] = useState<boolean>(false);

  const [search, setSearch] = useState<string>("");
  // show duration
  const [campaignId, setCampaignId] = useState<number>(0);

  const { data: allCampaigns, isLoading: gettingCampaigns, isFetching } =useGetCampaignsQuery(search);

  //delete campaign
  const [deleteCampaign, {isLoading: deletingCampaign}] = useDeleteCampaignMutation()

  const modalRef = useRef<HTMLElement>(null);

  const handleCloseModal = () => {
    setIsCreateCampaign(false);
  };

  const handleClick = () => {
    handleCloseModal();
  };
  useOutsideClick(modalRef, handleClick);

  const handleDelete = (campaignId: number) =>{
    deleteCampaign(campaignId)
    .unwrap()
    .then((res)=>{
      setCampaignId(0);
      setIsDeleteCampaign(false)
    })
    .catch((err)=>{
      toast.error(err?.data?.Message|| "Something went wrong", {
        position: "top-right",
      });
    })
  }

  return (
    <Section>
      {allCampaigns && allCampaigns?.data?.length > 0 ? (
        <section>
          <Form>
            <Label2 htmlFor="search">Search</Label2>
            <Input2 type="text" id="search" value={search} placeholder="Campaign Search" onChange={(e)=> setSearch(e.target.value)} />
          </Form>
          <GridContainer>
            <AddCampBox onClick={() => setIsCreateCampaign(true)}>
              <img src={AddCampaign} alt="Add campaign" />
              <Text14>Add a Campaign</Text14>
            </AddCampBox>
            {allCampaigns?.data?.map((campaign) => {
              return (
                <CampNameBox
                >
                  <FlexBox>
                    <ImgBox>
                      <img src={Speaker} alt="Speaker" width={18} height={18} />
                    </ImgBox>
                    <Relative>
                      <ButtonReset
                        onClick={() => setCampaignId(campaignId === campaign?.id ? 0 : campaign?.id)}
                      >
                        <ImgBox borderRadius="50%" background="#6B6B6B14">
                          <img
                            src={InfoIcon}
                            alt="Information icon"
                            width={17}
                            height={17}
                          />
                        </ImgBox>
                      </ButtonReset>
                      {campaignId === campaign?.id && (
                        <Duration
                          handleOpenCampaign={() =>
                            navigate(
                              `/home/campaign/main-campaign/${campaign?.id}`
                            )
                          }
                          handleDeleteCampaign={()=> setIsDeleteCampaign(true)}
                        />
                      )}
                    </Relative>
                  </FlexBox>
                  <div style={{ marginTop: "12px" }}>
                    <Text14 marginBottom="0.5rem">{campaign?.name}</Text14>
                    <Text10>{campaign?.total_views_amt || 0} views</Text10>
                  </div>
                  <div>
                    <ProgressBar
                      title="Duration"
                      percentage={progressDuration(
                        campaign?.start_date,
                        campaign?.end_date
                      )}
                      remaining={
                        getTimeLeft(campaign?.end_date).value +
                        getTimeLeft(campaign?.end_date).unit
                      }
                    />
                    <ProgressBar
                      title="Budget"
                      percentage={
                        (campaign?.total_spent_amt / campaign?.total_budget) *
                        100
                      }
                      remaining={`₦${campaign?.total_budget}`}
                    />
                  </div>
                </CampNameBox>
              );
            })}
          </GridContainer>
          <DeleteCampaignModal 
            showModal={isDeleteCampaign}
            handleDelete={()=> handleDelete(campaignId)} 
            handleCloseModal={()=> setIsDeleteCampaign(false)}
            deletingCampaign={deletingCampaign} 
          />

          {
            <CreateCampaignModal
              show={isCreateCampaign}
              modalRef={modalRef}
              handleCloseModal={handleCloseModal}
            />
          }
        </section>
      ) : gettingCampaigns || isFetching ? (
        <GridContainer>
          <CardLoader />
        </GridContainer>
      ) : (
        <CampaignEmpty />
      )}
    </Section>
  );
};

export default Campaign;
