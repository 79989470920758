import { useParams } from "react-router-dom";
import { Box3Main, SmallBox } from "../Dashboard/Dashboard.styled";
import OverviewInfo from "../Dashboard/OverviewInfo";
import {
  AddCampBox,
  CampInnerBox,
  FlexBox,
  OverviewBox,
  ProgressBarFill,
  ProgressBarWrapper,
} from "./Campaign.styled";
import {
  Text14,
  Text14Span,
  Text400,
  TextTitle4,
} from "../../styles/all.styled";
import AddCampaign from "../../assets/icons/add-campaign.svg";
import BgAdvert from "../../assets/images/Frame.png";
import {
  useGetCampaignQuery,
  useGetCampaignAdvertOverviewQuery,
} from "../../features/api/campaignSlice";
import { getTimeLeft, progressDuration } from "./helper";
import { ScaleLoader } from "react-spinners";

const Overview = () => {
  const { campaignId } = useParams();
  const { data: campaignDetail, isLoading: gettingCampaignDetail } =
    useGetCampaignQuery(Number(campaignId) as number);
  const { data: campaignAdvertOverview, isLoading: gettingCampaignOverview } =
    useGetCampaignAdvertOverviewQuery(Number(campaignId) as number);
  let budgetPercentage =
    ((campaignDetail?.data?.total_spent_amt as number) /
      (campaignDetail?.data?.total_budget as number)) *
    100;

  const OVERVIEW_CAMPAIGN_DATA = [
    {
      title: "Total Adverts",
      value: campaignAdvertOverview?.data?.total_ads || 0,
      info: "Total number of adverts",
    },
    {
      title: "Active Adverts",
      value: campaignAdvertOverview?.data?.active_ads || 0,
      info: "Total number of active adverts",
    },
    {
      title: "Paused Adverts",
      value: campaignAdvertOverview?.data?.paused_ads || 0,
      info: "Total number of paused adverts",
    },
    {
      title: "Total views",
      value: campaignAdvertOverview?.data?.total_views || 0,
      info: "Total number of views",
    },
  ];

  return (
    <div>
      <SmallBox>
        <h3 className="title">Overview</h3>
        <OverviewBox>
          <CampInnerBox>
            <TextTitle4>Your Audience are Reachable</TextTitle4>
            <CampInnerBox gap="17px">
              <FlexBox justifyContent="start">
                <Text400>Campaign name:</Text400>{" "}
                <Text14Span>
                  {gettingCampaignOverview ? (
                    <ScaleLoader height="12px" width="2px" />
                  ) : (
                    campaignDetail?.data?.name
                  )}
                </Text14Span>
              </FlexBox>
              <FlexBox justifyContent="start">
                <Text400>Duration:</Text400>{" "}
                <ProgressBarWrapper>
                  <ProgressBarFill
                    percentage={progressDuration(
                      campaignDetail?.data?.start_date || new Date(),
                      campaignDetail?.data?.end_date || new Date()
                    )}
                  />
                </ProgressBarWrapper>
                <Text14Span>
                  {gettingCampaignOverview ? (
                    <ScaleLoader height="12px" width="2px" />
                  ) : (
                    getTimeLeft(campaignDetail?.data?.end_date || new Date())
                      .value +
                    getTimeLeft(campaignDetail?.data?.end_date || new Date())
                      .unit
                  )}
                </Text14Span>
              </FlexBox>
              <FlexBox justifyContent="start">
                <Text400>Budget:</Text400>
                <ProgressBarWrapper>
                  <ProgressBarFill percentage={budgetPercentage} />
                </ProgressBarWrapper>
                <Text14Span>
                  {gettingCampaignOverview ? (
                    <ScaleLoader height="12px" width="2px" />
                  ) : (
                    `NGN${campaignDetail?.data?.total_budget}`
                  )}
                </Text14Span>
              </FlexBox>
            </CampInnerBox>
          </CampInnerBox>
          <img
            src={BgAdvert}
            alt=""
            className="bg-ad-img"
            width={252.558}
            height={154}
          />
        </OverviewBox>
        <Box3Main>
          {OVERVIEW_CAMPAIGN_DATA.map((item, index) => {
            return (
              <OverviewInfo
                isLoading={gettingCampaignDetail}
                key={index}
                {...item}
                handleRole={() => {}}
              />
            );
          })}
        </Box3Main>
        <br />
        <Box3Main>
          <AddCampBox
            as="a"
            href={`/home/campaign/${campaignId}/create-advert`}
          >
            <img src={AddCampaign} alt="Add campaign" />
            <Text14>Add an advert</Text14>
          </AddCampBox>
        </Box3Main>
      </SmallBox>
    </div>
  );
};

export default Overview;
