import React, { useState, MouseEvent, useRef } from "react";
// import Filter from "../../assets/icons/Wallet/filter.svg";
import {
  Input2,
  Label2,
  Walletbtn,
  Box5,
  Form2,
  // SelectWrapper,
} from "./Wallet.styled";
// import FilterGroup from "./FilterGroup";
import TableMain from "../Tables/TableMain";
import { columnsWithdraws } from "../Tables/TableData";
import { useGetTransactionsQuery } from "../../features/api/walletSlice";
import { CardLoader } from "../Reusable/CardLoader";
import EmptyBox from "../Reusable/EmptyBox";

const Funding = () => {

  const [walletReference, searchByReference] = useState<string>("");

  const { data: earningTransactions, isLoading, isFetching } = useGetTransactionsQuery({
    status: "",
    transactionType: "1",
    search: walletReference
  });


  const redColor = "#FF1E1E";


  return (
    <div>
      <Form2>
        {/* <Box5>
          <Label2 htmlFor="filter">Filter</Label2>
          <SelectWrapper>
            <Input2
              type="text"
              id="search"
              placeholder="All"
              inputWidth="100%"
            />
            <button className="select-btn" onClick={handleRole}>
              <img src={Filter} alt="" className="select-img-filter" />
            </button>
          </SelectWrapper>
        </Box5> */}
        <Box5>
          <Label2 htmlFor="search">Search</Label2>
          <Input2
            type="text"
            id="search"
            placeholder="Reference"
            inputWidth="100%"
            onChange={(e)=> searchByReference(e.target.value)}
          />
        </Box5>
      </Form2>
      {isLoading || isFetching ? <CardLoader /> :
      <div style={{ textAlign: "center" }}>
        <TableMain
          defaultData={earningTransactions?.data || []}
          columns={columnsWithdraws}
          greenColor={redColor}
        />
      </div>}
      {earningTransactions?.data.length === 0 && 
        <EmptyBox message={ walletReference ? "Sorry, this reference does not exist" : "Fund your account to see your history here"} />
      }
      {/* <WalletDataTableWith /> */}
      {/* <Walletbtn>
        <button>Load more</button>
      </Walletbtn>
      <FilterGroup
        showRole={showRole}
        handleCloseRole={handleCloseRole}
        modalRef={modalRef}
      /> */}
    </div>
  );
};

export default Funding;
