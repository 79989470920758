import { useEffect, useState } from "react";
import {
  ValidPhotoID,
  VerifyOnGoing,
  VerifyPage,
  VerifyPhotoID,
} from "./Verify";
import {
  CaptureDocument,
  CaptureSelfie,
  ChooseID,
  ConfirmApproval,
  SelectRegion,
} from "./VerificationModals";
import { useNavigate } from "react-router-dom";
import { useVerifyUserMutation } from "../../features/api/accountSlice";
import { UserDetail } from "../../features/api/authTypes";

export const Verification = () => {

    // Get user
    const [userDetail, setUser] = useState<UserDetail>();

  //handle Verification
  const [verifyuser, {isLoading: verrifyingUser}] = useVerifyUserMutation()

  // Modals
  const [step, setVerificationStep] = useState<number>(0);

  //Pages
  const [page, setPage] = useState<number>(0);

  //Selected ID
  const [idType, setIdType] = useState<number>(0);

  //Selected Region
  const [region, setRegion] = useState<string>("");

  //ID
  const [idImage, setIdImage] = useState<File>();

  //Selfie image
  const [selfieImage, setSelfieImage] = useState<File>();

  //Selfie image
  const [errMsg, setErr] = useState<string>("");

  //Choose ID type
  const handleChooseId = (idType: number) => {
    setVerificationStep(2);
    setIdType(idType);
  };

  //Choose Region
  const handleChooseRegion = (region: string) => {
    setVerificationStep(3);
    setRegion(region);
  };

  const convertToFile = (image: string) =>{
    // Convert data URL to blob
    const byteCharacters = atob(image.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });

    // Create a file from the blob
    const imageFile = new File([blob], 'captured_image.jpeg', { type: 'image/jpeg' });
    return imageFile
  }

    //Choose ID
    const handleId = (idImage: string | File, type: string) => {
      setPage(2);
      setVerificationStep(0);
      if(type === 'UPLOAD'){
        setIdImage(idImage as File)
        console.log(idImage, 'i am the image file')
      }else{
        let imageFile = convertToFile(idImage as string) 
        setIdImage(imageFile);
      }
     
    };

  const handleSelfie = (selfieImage: string) => {
    let imageFile = convertToFile(selfieImage)
    setVerificationStep(5);
    setSelfieImage(imageFile);
  };

  const navigate = useNavigate();

  const handleAccountVerification = () =>{
    let bodyFormData = new FormData();
    bodyFormData.append("IdentityType", idType.toString());
    bodyFormData.append("IdentityPhotoFile", idImage as File);
    bodyFormData.append("SelfieFile", selfieImage as File);
    bodyFormData.append("IdentificationNumber", "");
    bodyFormData.append("BusinessId", (userDetail?.businesses[0].id.toString() || ''))
    verifyuser(bodyFormData)
    .unwrap()
    .then((res)=>{
      localStorage.setItem("user", JSON.stringify(res.data.user))
      setVerificationStep(0);
      navigate('/home/account')
      
    })
    .catch((err)=>{
      setErr(err?.data?.Message || "Something went wrong!")
    })
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    setUser(user);
  }, []);

  return (
    <div>
      <div>
        {page === 0 && <VerifyPage handlePage={() => setPage(1)} />}
        {page === 1 && (
          <ValidPhotoID
            handlePage={() => {
              setVerificationStep(1);
            }}
          />
        )}
        {page === 2 && (
          <VerifyPhotoID
            handlePage={() => {
              setVerificationStep(4);
            }}
          />
        )}
        {page === 3 && (
          <VerifyOnGoing
            handlePage={() => {
              navigate("/home/profile");
            }}
          />
        )}
      </div>

      {/* <VerifyOnGoing handlePage={() => navigate("/home")} /> */}
      {step === 1 && (
        <ChooseID
          handleSubmit={handleChooseId}
          handleGoBack={() => setVerificationStep(0)}
        />
      )}
      {step === 2 && (
        <SelectRegion
          handleSubmit={handleChooseRegion}
          handleGoBack={() => setVerificationStep(0)}
        />
      )}
      {step === 3 && (
        <CaptureDocument
          handleSubmit={handleId}
          handleCloseModal={() => setVerificationStep(0)}
          idType={idType}
        />
      )}
      {step === 4 && (
        <CaptureSelfie
          handleSubmit={handleSelfie}
          handleCloseModal={() => setVerificationStep(0)}
        />
      )}
      {step === 5 && (
        <ConfirmApproval
          handleSubmit={handleAccountVerification}
          handleCloseModal={() => setVerificationStep(0)}
          isLoading={verrifyingUser}
          errMsg={errMsg}
        />
      )}
    </div>
  );
};
