import React from "react";
import { GraphTab, Section } from "../../Dashboard/Dashboard.styled";
import { TextTitle1, TextTitle1Span } from "../../../styles/all.styled";
import { Link, Outlet, useLocation } from "react-router-dom";

const MainCampaign: React.FC = () => {
  const location = useLocation();

  return (
    <Section>
      <div>
        <TextTitle1>
          Campaigns - Bootleg - <TextTitle1Span>Flashsales</TextTitle1Span>
        </TextTitle1>
      </div>

      <GraphTab>
        <Link
          to="/home/campaign/flash-sales/overview/:advertId"
          className={
            location.pathname === "/home/campaign/flash-sales/:advertId/overview"
              ? "active"
              : ""
          }
        >
          Overview
        </Link>
        <Link
          to="/home/campaign/flash-sales/details"
          className={
            location.pathname === "/home/campaign/flash-sales/:advertId/details"
              ? "active"
              : ""
          }
        >
          Details
        </Link>
      </GraphTab>
      <Outlet />
    </Section>
  );
};

export default MainCampaign;
