import React from "react";
import { Graph } from "./DashboardGraph";

type Views = {
  date: Date,
  views: number,
  amount: number
}
export interface AverageViewsProps {
  data: Views[]
}

const AverageViews: React.FC<AverageViewsProps> = ({ data }) => {
  return <Graph data={data} />;
};

export default AverageViews;
